<template>
  <div class="animate_animated animate__slideInRight">
    <div class="fixed w-full top-0 left-0 z-20">
      <div class=" bg-white px-4 py-6 pb-5 shadow-ct">
        <div class="flex items-center">
          <div class="w-1/2">
              <div class="flex items-center w-full">
                <a @click="$router.go(-1)"><img src="@/assets/back-arrow.svg" alt=""></a>
                <span class="text-base text-content font-medium ml-4">Enquiry History</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    <div class="pt-16">
      <!-- Card-List -->
      <div class="shadow-ct bg-white my-4" v-for="(eh, key) in enquiryHistory" :key="key">
        <div class="px-4 py-3">
          <div class="grid gap-1 grid-cols-2">
            <div>
              <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Status From</span>
              <span class="text-content text-xs font-medium block mb-3">{{ eh.status_from }}</span>
            </div>
            <div>
              <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Status To</span>
              <span class="text-content text-xs font-medium block mb-3">{{ eh.status_to }}</span>
            </div>
            <div>
              <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Assign From</span>
              <span class="text-content text-xs font-medium block mb-3">{{ eh.assigned_from }}</span>
            </div>
            <div>
              <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Assign to</span>
              <span class="text-content text-xs font-medium block mb-3">{{ eh.assigned_to }}</span>
            </div>
            <div>
              <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">By</span>
              <span class="text-content text-xs font-medium block mb-3">{{ eh.by }}</span>
            </div>
            <div class="col-span-2">
              <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Comment From</span>
              <read-more more-str="read more" class="text-content text-xs font-medium block mb-3"
                :text="eh.comment_from" v-if="eh.comment_from" link="#" less-str="read less" :max-chars="50">
              </read-more>
            </div>
            <div class="col-span-2">
              <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Comment To</span>
              <read-more more-str="read more" class="text-content text-xs font-medium block mb-3" :text="eh.comment_to"
                v-if="eh.comment_to" link="#" less-str="read less" :max-chars="50"></read-more>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      enquiryHistory: [],
    }
  },
  watch: {
    $route(to, from) {
      this.loadHistory();
    }
  },
  created() {
    this.loadHistory()
  },
  methods: {
    loadHistory() {
      let data = this.$axios.get(`/mobile_crm/leads/${this.$route.params.uuid}/histories`)
        .then(res => {
          this.enquiryHistory = res.data.audits;
        })
        .catch(err => console.log(err));
    },
  }
}
</script>
