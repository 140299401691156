<template>
	<div class="animate_animated animate__slideInRight">
		<div class="fixed w-full top-0 left-0 z-20">
		<div class="flex bg-white px-4 py-6 items-center shadow-ct">
			<div class="w-full">
				<div class="flex items-center w-full">
					<a  @click="$router.go(-1)"><img src="@/assets/back-arrow.svg" alt=""></a>
					<span class="text-base text-content font-medium ml-4">Edit Property</span>
				</div>
			</div>
		</div>
    </div>
    <div class="pt-20 bg-white mt-2">
    <div class="bg-white min-h-screen p-4">
      <Property v-if="Object.keys(EditProperty).length > 0" :property="EditProperty" :editSubmitFn="goToStep2" :getLocality="getLocality" :localityList="localityList"/>
    </div>
    </div>
	</div>
</template>
<script>
import Property from '@/components/Property/Next.vue';
export default {
  components: {
    Property
  },
  data () {
    return {
      localityList: [],
      EditProperty: {},
    }
  },
  created() {
    this.$axios.get(`mobile_crm/properties/${this.$route.params.uuid}`)
    .then((res) => {
      this.EditProperty = res.data.property
      if (this.EditProperty.city_id) {
        this.$axios.get(`mobile_crm/properties/fetch_localities?city_id=${this.EditProperty.city_id}`)
        .then((res) => {
          this.localityList = res.data
          })
        .catch((err) => console.log(err));
      }
        let step1_data = this.$store.getters.newPropertyData
        if (Object.keys(step1_data).length > 0) {
          this.EditProperty = { ...this.EditProperty, ...step1_data}
        }
      })
    .catch((err) => console.log(err));
  },
  methods: {
    getLocality() {
      this.$axios.get(`mobile_crm/properties/fetch_localities?city_id=${this.EditProperty.city_id}`)
      .then((res) => {
        this.localityList = res.data
        })
      .catch((err) => console.log(err));
    },
    goToStep2(){
      let step0_data = this.$store.getters.newPropertyData
      let new_data = {...step0_data, ...this.EditProperty}
      this.$store.dispatch('setnewPropertyData', new_data);
      this.$router.push({name: 'EditNext2Property'});
    },
  }
}
</script>
<style scoped>

</style>