<template>
    <form @submit.prevent>
      <div class="mb-6 relative">
        <label for=""
          class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Furnishings</label>
        <select v-model="enquiry.require_furnishing"
          class="border border-inputBorder round-md p-4 w-full z-auto custom-select focus:outline-none">
          <option selected disabled>Select Furnishings</option>
          <option value='Yes'>Yes</option>
          <option value='No'>No</option>
        </select>
      </div>
    <div class="mb-6 relative">
      <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Bathrooms</label>
      <input type="number" v-model="enquiry.no_of_bathroom" placeholder="Bathrooms" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
    </div>
    <div class="mb-6 relative">
      <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">No of Parkings</label>
      <input type="number" v-model="enquiry.no_of_parkings" placeholder="No of Parkings" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
    </div>
    <div class="mb-6 relative">
      <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Floor Range</label>
      <input type="number" v-model="enquiry.floor_range" placeholder="Floor Range" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
    </div>
      <div class="mb-6 relative">
        <label for=""
          class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Facing
          Direction</label>
        <select v-model="enquiry.facing_direction"
          class="border border-inputBorder round-md p-4 w-full z-auto custom-select focus:outline-none">
          <option selected disabled>Select Facing Direction</option>
        <option
          v-for="(fd, key) in leadKeysList.facing_directions"
          :key="key"
          :value="fd"
        >{{fd}}</option>
        </select>
      </div>
      <div class="mb-6 relative">
        <label for=""
          class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Description</label>
        <textarea v-model="enquiry.comment" rows="3" placeholder="Comment"
          class="border border-inputBorder round-md p-4 w-full z-auto focus:outline-none"></textarea>
      </div>
    </form>
</template>

<script>

export default {
  props: {
    enquiry: {
      type: Object,
      required: true
    }
  },
  computed: {
    leadKeysList(){
      return this.$store.getters.leadKeys;
    },
  }
}
</script>