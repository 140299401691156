<template>
      <form  @submit.prevent="!property.uuid ? submitFn() : editSubmitFn()">
         <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">City *</label>
          <select v-model="property.city_id" required @change="getLocality()" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select City</option>
            <option
              v-for="(r, key) in propertyKeysList.cities"
              :key="key"
              :value="r.id"
            >{{r.name}}</option>
          </select>
        </div>
         <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Locality *</label>
          <select v-model="property.locality_id" required class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select Locality</option>
            <option
              v-for="(r, key) in localityList"
              :key="key"
              :value="r.id"
            >{{r.name}}</option>
          </select>
        </div>
         <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Zip</label>
          <input type="text" v-model="property.zip" placeholder="Enter" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
         <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Unit No</label>
          <input type="text"  v-model="property.unit_no" placeholder="Enter" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
				<div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Floor</label>
          <!-- <input type="text"  v-model="property.floor" placeholder="Enter" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none"> -->
          <select v-model="property.floor_id" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
          <option selected disabled>Select</option>
          <option
            v-for="(flr, key) in propertyKeysList.floors"
            :key="key"
            :value="flr.id"
          >{{flr.name}}</option>
        </select>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Wing</label>
          <input type="text" v-model="property.wing" placeholder="Enter" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Vastu Compliant</label>
          <select v-model="property.vastu_compliant" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select</option>
            <option value="Yes" >Yes</option>
            <option value="No" >No</option>
          </select>
        </div>
				<div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Building/Plot</label>
          <input type="text" v-model="property.building" placeholder="Enter" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mt-10">
					<button type="submit" :disabled="!showProceed" class="bg-primary text-base font-semibold text-white rounded-full p-5 w-full disabled:opacity-50" >Next</button>
				</div>
      </form>
</template>

<script>
export default {
  props: {
    property: {
      type: Object,
      required: true
    },
    submitFn: {
      type: Function,
    },
    getLocality: {
      type: Function,
    },
    localityList: {
      type: Array
    },
    editSubmitFn: {
      type: Function,
    }
  },
  computed: {
    showProceed() {
      if (this.property.city_id && this.property.locality_id) {
        return true
      }
    },
    leadKeysList(){
      return this.$store.getters.leadKeys;
    },
    propertyKeysList(){
      return this.$store.getters.propertyKeys;
    },
  }
}
</script>

<style>

</style>