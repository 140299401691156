<template>
	<div class="animate_animated animate__slideInRight">
		<div class="fixed w-full top-0 left-0 z-20">
		<div class="flex bg-white px-4 py-6 items-center shadow-ct">
			<div class="w-full">
				<div class="flex items-center w-full">
					<a  @click="$router.go(-1)"><img src="@/assets/back-arrow.svg" alt=""></a>
					<span class="text-base text-content font-medium ml-4">New Property</span>
				</div>
			</div>
		</div>
    </div>
    <div class="pt-20 bg-white mt-2">
    <div class="bg-white min-h-screen p-4">
      <Property :property="AddNewProperty" :submitFn="goToStep2" :getLocality="getLocality" :localityList="localityList"/>
    </div>
    </div>
	</div>
</template>
<script>
import Property from '@/components/Property/Next.vue';
export default {
  components: {
    Property
  },
  data () {
    return {
      localityList: [],
      AddNewProperty: {
        city_id: null,
        locality_id: null,
        zip: null,
        unit_no: null,
        floor_id: null,
        wing: null,
        vastu_compliant: null,
        building: null
      },
    }
  },
  created() {
    let step1_data = this.$store.getters.newPropertyData
    if (step1_data) {
      if (step1_data.city_id) {
        this.$axios.get(`mobile_crm/properties/fetch_localities?city_id=${step1_data.city_id}`)
        .then((res) => {
          this.localityList = res.data
        })
        .catch((err) => console.log(err));
      }
      this.AddNewProperty = step1_data
    }
  },
  methods: {
    getLocality() {
      this.$axios.get(`mobile_crm/properties/fetch_localities?city_id=${this.AddNewProperty.city_id}`)
      .then((res) => {
        this.localityList = res.data
      })
      .catch((err) => console.log(err));
    },
    goToStep2(){
      let step0_data = this.$store.getters.newPropertyData
      let new_data = {...step0_data, ...this.AddNewProperty}
      this.$store.dispatch('setnewPropertyData', new_data);
      this.$router.push({name: 'Next2Property'});
    },
  }
}
</script>
<style scoped>

</style>