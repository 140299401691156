<template>
	<div class="animate_animated animate__slideInRight">
		<div class="fixed w-full top-0 left-0 z-20">
			<div class="flex bg-white px-4 py-6 items-center shadow-ct">
				<div class="w-full">
					<div class="flex items-center w-full">
						<a  @click="$router.go(-1)"><img src="@/assets/back-arrow.svg" alt=""></a>
						<span class="text-base text-content font-medium ml-4">Edit Enquiry</span>
					</div>
				</div>
			</div>
		</div>
		<div class="pt-20 bg-white mt-2">
			<div class="bg-white min-h-screen p-4">
        <Enquiry :enquiry="EditEnquiry"/>
			  <div class="mb-6 relative">
          <div v-if="DynamicForm == true">
          <div class="mb-6 relative">
            <FormSchema class="schema-form"
              :schema="schema" v-model="magicFields">
                <button variant="primary" type="submit" hidden >Save</button>
            </FormSchema>
          </div>
        </div>
			  </div>
        <div class="mt-10">
        <button type="button" @click="updateEnquiry"
          class="bg-primary text-base font-semibold text-white rounded-full p-5 w-full disabled:opacity-50"
          >Submit</button>
      </div>
      </div>
		</div>
		</div>
	</div>
</template>
<script>
import FormSchema from 'vue-json-schema'
import {
    Form,
    elForm,
    FormItem,
    Input,
    Radio,
    Checkbox,
    Switch,
    Select,
    Option,
    Button
  } from 'element-ui'
  FormSchema.setComponent('form', Form)
  FormSchema.setComponent('email', Input)
  FormSchema.setComponent('text', Input)
  FormSchema.setComponent('textarea', Input)
  FormSchema.setComponent('checkbox', Checkbox)
  FormSchema.setComponent('radio', Radio)
import {prepareNextEnquiryParams} from '@/assets/scripts/utility.js';
import Enquiry from '@/components/Enquiry/Next.vue';
export default {
  components: {
    Enquiry,
    FormSchema
  },
  data () {
    return {
    magicFields: {},
      DynamicForm: false,
      uiSchema: [],
         schema: {
        type: 'object',
        options: {
          castToSchemaType: true,
        },
        required: []
      },
      EditEnquiry: {},
    }
  },
  created() {
    this.$axios.get(`mobile_crm/leads/${this.$route.params.uuid}`)
      .then((res) => {
      this.EditEnquiry = res.data.lead
      this.fetchDynamicFields(res.data.lead.magic_field_values)
    })
    .catch((err) => console.log(err));
    },
  methods: {
    fetchDynamicFields(LeadsData) {
      var HashMap = require('hashmap');
      var map = new HashMap();
      LeadsData.forEach(Lead => {
      let fieldOptions = {}
      let attributes = {}
      if (Lead.datatype === 'check_box_boolean') {
        attributes['type'] = 'checkbox'
        fieldOptions['type'] = 'boolean'
        fieldOptions["default"] = Lead.value,
        fieldOptions['title'] = Lead.label_name
      } else if (Lead.datatype === 'integer') {
        attributes['type'] = 'number'
        fieldOptions['type'] = 'number'
        fieldOptions["default"] = Lead.value
        fieldOptions['title'] = Lead.label_name
      } else if (Lead.datatype === 'date') {
        attributes['type'] =   Lead.datatype
        fieldOptions['type'] = 'string'
        fieldOptions["default"] = Lead.value
        fieldOptions['title'] = Lead.label_name
      } else if (Lead.datatype === 'datetime') {
        attributes['type'] = 'datetime-local'
        fieldOptions['type'] = 'string'
        fieldOptions["default"] = Lead.value
        fieldOptions['title'] = Lead.label_name
      } else {
        attributes['type'] = Lead.datatype
        fieldOptions['type'] = Lead.datatype
        fieldOptions["default"] = Lead.value
        fieldOptions['title'] = Lead.label_name
      }
      if (Lead.is_select_list === true) {
        fieldOptions["enum"] = Lead.items
        fieldOptions['title'] = Lead.label_name,
        delete attributes.type
      }
      fieldOptions['attrs'] = attributes
      if (Lead.is_required === true) {
        this.schema.required.push(Lead.key)
      }
      map.set(Lead.key, fieldOptions);
      });
      let data = {}
      map.forEach(function(value, key) {
        data[key] = value
      });
      this.schema['properties'] = data;
      this.DynamicForm = true
    },
    updateEnquiry() {
      let finalSubmitData = this.$store.getters.newEnquiryData
      let  nextParams = prepareNextEnquiryParams(this.EditEnquiry)
      let leadData = {...finalSubmitData, ...nextParams,  ...this.magicFields}
      this.$axios
        .put(`mobile_crm/leads/${this.$route.params.uuid}`, { lead: leadData })
        .then(res => {
          swal({
            title: "Enquiry Updated!",
            icon: "success"
          }).then(this.$router.replace({ name: "Enquiry" }));
          this.$store.dispatch('setnewEnquiryData', null);
        })
        .catch(err => {
          if (err.response.status === 500) {
            swal({
              title: "Server Error!",
              icon: "error"
            });
          } else {
            swal({
              title: "Error",
              text: err.response.data.message,
              icon: "error"
            });
          }
        });
    },
  },
  computed: {
    leadKeysList(){
      return this.$store.getters.leadKeys;
    },
  }
}
</script>
<style scoped>

</style>