<template>
	<div class="animate_animated animate__slideInRight">
		<div class="fixed w-full top-0 left-0 z-20">
		<div class="flex bg-white px-4 py-6 items-center shadow-ct">
			<div class="w-full">
				<div class="flex items-center w-full">
					<a  @click="$router.go(-1)"><img src="@/assets/back-arrow.svg" alt=""></a>
					<span class="text-base text-content font-medium ml-4">Search Agreements</span>
				</div>
			</div>
		</div>
    </div>
    <div class="pt-20 mt-2 bg-white">
    <div class="bg-white min-h-screen p-4">
      <form>
		    <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white z-10">Property</label>
            <multiselect v-model="search.property_id" :searchable="true"  :placeholder="propertyNoInput" label="project_name" track-by="id" :options="propertiesList" :taggable="true" @search-change="suggestProperty" class="multiselect-outer-wrapper"></multiselect>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Owner Name</label>
          <input type="text" v-model="search.owner" placeholder="Owner Name" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Buyer Name</label>
          <input type="text" v-model="search.buyer_name" placeholder="Buyer Name" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
       <div class="mb-10">
          <label for="" class="text-main text-opacity-50 text-xs block mb-5 ml-4">Transaction Type</label>
          <div class="flex">
            <span class="radio-selection">
              <input type="radio" v-model="search.transaction_type" name="propertyFor" value="Sale" id="Sale" class="hidden">
              <label for="Sale" class="bg-divider py-3 px-6 text-content rounded-full font-medium pills mr-3"> Sale </label>
            </span>
             <span class="radio-selection">
              <input type="radio" v-model="search.transaction_type" name="propertyFor" value="Rental" id="Rental" class="hidden">
              <label for="Rental" class="bg-divider py-3 px-6 text-content rounded-full font-medium pills"> Rental </label>
            </span>
          </div>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Agreement Date From</label>
          <input type="date" v-model="search.agreement_date_from" class="border border-inputBorder round-md p-4 w-full z-auto focus:outline-none">
        </div>

        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Agreement Date Upto</label>
          <input type="date" v-model="search.agreement_date_upto" class="border border-inputBorder round-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mt-10">
          <a @click="clearFilters" class="clearFilter"> Clear Filters</a>
          <button type="button" @click="performSearch" class="bg-primary text-base font-semibold text-white rounded-full p-5 w-full disabled:opacity-50" >Filter</button>
        </div>
      </form>
    </div>
  </div>
	</div>
</template>
<script>
import Multiselect from 'vue-multiselect';
export default {
  components: {
    Multiselect
  },
  data () {
    return {
      propertiesList: [],
      propertyNoInput: 'Start Typing....',
      search: {
        property_id: null,
        owner: null,
        buyer_name: null,
        transaction_type: null,
        agreement_date_from: null,
        agreement_date_upto: null
      },
    }
  },
  methods: {
    suggestProperty(event){
      if(event.length === 3){
      event = encodeURIComponent(event)
      this.$axios.get(`/mobile_crm/agreements/search_properties?search_string=${event.trim()}`)
        .then(res => {
          this.propertyNoInput = "No Results!"
          if(res.data.properties.length > 0){
            this.propertiesList = res.data.properties
          }
        })
        .catch(err => {
          this.propertyNoInput = "Something Went Wrong!"
        })
      }
    },
    performSearch(){
      this.$store.dispatch('setAdvSParams', this.search);
      this.$router.push({name: 'Agreement'})
    },
    seedDefaults(){
      this.search = this.advSParams
    },
    clearFilters(){
      this.search = {
        property_id: null,
        owner: null,
        buyer_name: null,
        transaction_type: null,
        agreement_date_from: null,
        agreement_date_upto: null
      }
      this.$store.dispatch('setAdvSParams', {});
    }
  },
  created(){
    if(Object.keys(this.advSParams).length > 0){
      this.seedDefaults();
    }
  },
  computed: {
    advSParams(){
      return this.$store.getters.advSParams;
    },
    agreementKeysList(){
      return this.$store.getters.agreementKeys;
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.clearFilter{
  display: table;
  margin:20px auto;
  opacity: .5;
  font-size: 14px;
}
</style>