<template>
	<div class="animate_animated animate__slideInRight ">
    <div class="fixed w-full top-0 left-0 z-20">
			<div class=" bg-white px-4 py-6 pb-5 shadow-ct">
				<div class="flex items-center">
					<div class="w-1/2">
						<div class="flex items-center w-full">
							<a  @click="$router.push('/')"><img src="@/assets/back-arrow.svg" alt=""></a>
							<span class="text-base text-content font-medium ml-4">Expiring Lease</span>
						</div>
					</div>
					<div class="w-1/2">
						<div class="flex items-center justify-end">
							<a @click="$router.push('searchexpiringlease')" class="mx-2"><img src="@/assets/filter-icon.svg" alt=""></a>
						</div>
					</div>
				</div>
				<div class="flex mt-5">
					<div class="flex items-strech w-full">
						<div class="relative w-full">
							<input type="text" @input="e => bsTerm = e.target.value" :value="bsTerm" name="" id="" placeholder="Search by Name"
								class="border border-inputBorder round-md p-3 w-full z-auto focus:outline-none rounded-tl rounded-bl">
							<button type="button" class="absolute right-0 top-0 m-3" v-if="bsTerm" @click="resetBs">
              <img src="@/assets/close-icon.svg" alt="" class="transform rotate-45"></button>
						</div>

						<div class="bg-primary rounded-tr rounded-br px-3 flex items-center">
              <button type="button" :disabled="!bsTerm" @click="performBs">
							<img src="@/assets/search-icon-white.svg"   alt="" class=""></button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pt-20 mt-2 bg-white mt-20">
			<vue-good-table :columns="columns" :rows="expAgreementLists" styleClass="vgt-table striped">
          <div slot="emptystate">
            No Records Found
          </div>
				<template slot="table-row" slot-scope="props">
					<span v-if="props.column.field == 'agreement_till'">
						<span>{{props.row.agreement_till}} <span class="text-red font-semibold text-xxs">{{props.row.days_left}} day</span></span>
					</span>
						<span v-else-if="props.column.field == 'action'">
						<span class="text-primary cursor-pointer" @click="$router.push({ name: 'AgreementDetails', params: {  uuid: props.row.uuid }})">view</span>
					</span>
					<span v-else>
						{{props.formattedRow[props.column.field]}}
					</span>
				</template>
			</vue-good-table>
		</div>
    <div class="pagination-holder">
      <paginate
        v-if="totalPages > 1"
        v-model="pageNo"
        :page-count="totalPages"
        :click-handler="goToPage"
        :page-class="'page-item'"
        :page-link-class="'page-link'"
        :prev-link-class="'page-link'"
        :next-link-class="'page-link'"
        :disabled-class="'page-item disabled'"
        :container-class="'pagination'">
      </paginate>
    </div>
	</div>
</template>
<script>
import {paramsFromQuery, prepareExpiringLeaseSearchParams} from '@/assets/scripts/utility.js';
export default {
  data() {
  return {
    bsTerm: this.$route.query.bs,
    total: 0,
    perPage: 0,
    agreements: [],
    advSearchParams: [],
    columns: [{
        label: 'Expiry Date',
        field: 'agreement_till',
        type: 'date',
        dateInputFormat: 'dd-MM-yyyy',
        dateOutputFormat: 'dd-MM-yyyy',
      },
      {
        label: 'Buyer',
        field: 'buyer_name',
        sortable: false,
      },
      {
        label: 'Type',
        field: 'transaction_type',
        sortable: false,
      },
      {
        label: 'Action',
        field: 'action',
        sortable: false,
      },
    ],
    expAgreementLists: [],
  };
},
watch: {
  $route(to, from){
    this.loadExpiringAgreements();
  }
},
created(){
  this.loadExpiringAgreements()
},
methods: {
  loadExpiringAgreements(){
    let queryP = {}
    if(Object.keys(this.advSParams).length > 0){
      queryP["as"] = true
      let searchParams = prepareExpiringLeaseSearchParams(this.advSParams);
      for(let key in searchParams){
        queryP[key] = searchParams[key];
      }
      this.advSearchParams = queryP
    } else {
      queryP["as"] = true
    }
    if (this.advSearchParams.length > 0){
      paramsFromQuery(queryP, this.advSearchParams);
    }
    else{
      paramsFromQuery(queryP, this.$route.query);
    }
    let data = this.$axios.get('/mobile_crm/agreements?expiring=true', {
      params: queryP
    })
    .then(res=>{
      this.expAgreementLists = res.data.agreements;
      this.perPage = res.data.per_page;
      this.total = res.data.count;
      // this.$store.dispatch('setAdvSParams', {});
  })
  .catch(err => console.log(err));
  },
  goToPage(pageNo){
    let newQ = {...this.$route.query, pageNo};
    this.$router.replace({query: newQ});
  },
  resetBs(){
    let rQ = this.$route.query
    this.bsTerm = ''
    if(rQ.bs){
      let {bs, ...newQ} = {}
      this.$router.replace({query: newQ})
    }
  },
  performBs(){
    if(this.bsTerm){
      let newQ = {...this.$route.query, bs: this.bsTerm}
      delete newQ.pageNo
      this.$router.replace({query: newQ})
    }
  }
},
computed: {
  advSParams(){
    return this.$store.getters.advSParams;
  },
  totalPages(){
    if(this.total === 0 || this.perPage === 0){
      return 0;
    }else{
      return Math.ceil(this.total/this.perPage);
    }
  },
  pageNo: {
    get(){
      let pageNo = this.$route.query.pageNo || 1
      return parseInt(pageNo);
    },
    set(){
      true;
    }
  },
  pageStart(){
    if(this.total === 0){
      return 0;
    }
    return (Math.max(0, (this.pageNo - 1)*this.perPage+1))
  },
  pageEnd(){
    if(this.total === 0){
      return 0;
    }
    return (Math.min(this.total, this.pageNo*this.perPage))
  }
}
}
</script>
<style scoped>

</style>