<template>
  <div class="animate_animated animate__slideInRight">
    <div class="fixed w-full top-0 left-0 z-20">
      <div class="flex bg-white px-4 py-6 items-center shadow-ct">
        <div class="w-full">
          <div class="flex items-center w-full">
            <a @click="$router.go(-1)"><img src="@/assets/back-arrow.svg" alt=""></a>
            <span class="text-base text-content font-medium ml-4">Edit Agreement</span>
          </div>
        </div>
      </div>
    </div>
    <Agreement :Agreement="EditAgreement" :submitFn="updateAgreement" />
  </div>
</template>
<script>
import Agreement from '@/components/Agreement/Next.vue';
export default {
  components: {
    Agreement
  },
  data() {
    return {
      EditAgreement: {},
    }
  },
  created() {
    this.$axios.get(`mobile_crm/agreements/${this.$route.params.uuid}`)
      .then((res) => {
        this.EditAgreement = {
          price: res.data.agreement.price, buyer_brokerage: res.data.agreement.buyer_brokerage,
          seller_brokerage: res.data.agreement.seller_brokerage, gst: res.data.agreement.gst, comment: res.data.agreement.comment
        }
      })
      .catch((err) => console.log(err));
  },
  methods: {
    updateAgreement() {
      let finalSubmitData = this.$store.getters.newAgreementData
      if (finalSubmitData.property_id) {
        finalSubmitData.property_id = finalSubmitData.property_id.id
      }
      let Data = { ...finalSubmitData, ...this.EditAgreement }
      this.$axios
        .put(`mobile_crm/agreements/${this.$route.params.uuid}`, { agreement: Data })
        .then(res => {
          swal({
            title: "Agreement Updated!",
            icon: "success"
          }).then(this.$router.replace({ name: "Agreement" }));
          this.$store.dispatch('setnewAgreementData', null);
        })
        .catch(err => {
          if (err.response.status === 500) {
            swal({
              title: "Server Error!",
              icon: "error"
            });
          } else {
            swal({
              title: "Error",
              text: err.response.data.message,
              icon: "error"
            });
          }
        });
    },
  }
}
</script>
<style scoped>
</style>
