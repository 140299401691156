<template>
  <div class="animate_animated animate__slideInRight">
    <!-- Header -->
    <div class="fixed w-full z-10 left-0 top-0">
      <div class="absolute w-full top-0 left-0 z-10 bg-fix">
        <div class="flex px-4 py-6 items-center ">
          <div class="w-1/2">
            <a @click="$router.go(-1)"><img src="@/assets/back-arrow-white.svg" alt=""></a>
          </div>
          <div class="w-1/2 flex justify-end">
            <span class="flex bg-white items-center rounded px-2 py-1">
              <img src="@/assets/available-icon.svg" alt="" class="mr-1">
              <span class="text-content text-xxs font-medium ">{{ projectDetails.possession_status }}</span>
            </span>
          </div>
        </div>
      </div>
      <img :src="photoUrl.length > 0 ? photoUrl : 'https://engage-prod.s3.ap-south-1.amazonaws.com/images/app/engage-app-prod.jpeg'" alt="" class="w-full h-48 object-cover object-center">
    </div>
    <div class="mt-48 relative z-50">
      <div class="bg-white min-h-screen pb-12">
        <div class="flex items-center p-2">
          <div class="w-full">
            <div class="p-3">
              <div class="flex items-center">
              </div>
              <div class="flex mb-5">
                <div class="w-3/4">
                  <span v-if="created_user" class="text-xs text-black font-medium ml-2 text-opacity-50">{{projectDetails.user_name}}</span>
                  <h2 class="text-content text-base font-semibold mb-1">{{ projectDetails.name }} </h2>
                  <span class="block text-base font-normal">{{ projectDetails.location }}</span>
                  <span class="block text-base font-normal">{{ projectDetails.locality }}, {{ projectDetails.city }}</span>
                  <span class="block text-base font-normal" style="font-size: 12px;">RERA No:
                    {{ projectDetails.rera_no }}</span>
                    <span class="block text-base font-normal" style="font-size: 12px;">Comment:
                    {{ projectDetails.description }}</span>
                </div>
              </div>
              <div class="flex mb-5">
                <div class="">
                  <span class="text-sm text-content text-opacity-50 block mb-2">Photos</span>
                  <vue-easy-lightbox :visible="visible" :imgs="photoUrl" @hide="handleHide"></vue-easy-lightbox>
                  <div class="flex justify-between">
                    <div v-for="(img_url, index) in photoUrl" :key="index" :class="index > 3 ? 'hidden' : ''"
                      class="mr-2 img-gallery w-full" @click="() => showImg(img_url)">
                      <span
                        class="text-sm absolute  items-center justify-center w-full h-full text-white z-10 font-medium hidden">{{ index
                            + 1
                        }}+</span>
                      <img :src="img_url" alt="" class="w-full max-w-full rounded-sm h-20 object-cover h-fix">
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex mb-5">
                <div class="mr-8">
                  <span class="text-sm text-content text-opacity-50 block">Developer Name</span>
                  <span class="text-sm text-content font-semibold">{{ projectDetails.developer_name }}</span>
                </div>
                <div class="">
                  <span class="text-sm text-content text-opacity-50 block">Developer Contact</span>
                  <span class="text-sm text-content font-semibold">{{ projectDetails.developer_contact }}</span>
                </div>
              </div>
              <div class="flex mb-5">
                <div class="mr-8">
                  <span class="text-sm text-content text-opacity-50 block">Possession Date</span>
                  <span class="text-sm text-content font-semibold">{{ projectDetails.formatted_possession_date }}</span>
                </div>
                <div class="">
                  <span class="text-sm text-content text-opacity-50 block">Project Approved by</span>
                  <span class="text-sm text-content font-semibold">{{ projectDetails.project_approve_by }}</span>
                </div>
              </div>
              <div class="flex mb-8 flex-wrap">
                <span class="text-sm text-content font-medium amenities-label"
                  v-if="projectDetails.property_age">Property Age: {{ projectDetails.property_age }}</span>
                <span class="text-sm text-content font-medium amenities-label"
                  v-for="(am, key) in projectDetails.amenity_ids" :key="key">{{ am.name }}</span>
              </div>
              <div class="flex mt-10 mb-5">
                <div class="w-full">
                  <a class="bg-primary bg-opacity-10 rounded-full px-5 py-4 inline-flex btn-pill-fix cursor-pointer"
                    @click="shareWhatsApp(projectDetails)" target="_blank">
                    <img src="@/assets/whatsapp-blue.svg" alt="" class="mr-1 inline-flex">
                    <span class="text-primary text-base font-medium">Share on Whatsapp</span>
                  </a>
                </div>
              </div>
              <div class="mb-20">
                <div class="flex items-center">
                  <span class="btn text-main text-sm font-medium text-opacity-50"
                    @click="deleteProject(projectDetails.uuid)">Delete Project</span>
                  <span class="ml-1 text-main text-opacity-50 "><svg class="stroke-current" width="14" height="14"
                      viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.8125 3.0625L2.1875 3.06254" stroke="" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M5.6875 5.6875V9.1875" stroke="" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M8.3125 5.6875V9.1875" stroke="" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                        d="M10.9375 3.0625V11.375C10.9375 11.491 10.8914 11.6023 10.8094 11.6844C10.7273 11.7664 10.616 11.8125 10.5 11.8125H3.5C3.38397 11.8125 3.27269 11.7664 3.19064 11.6844C3.10859 11.6023 3.0625 11.491 3.0625 11.375V3.0625"
                        stroke="" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                        d="M9.1875 3.0625V2.1875C9.1875 1.95544 9.09531 1.73288 8.93122 1.56878C8.76712 1.40469 8.54456 1.3125 8.3125 1.3125H5.6875C5.45544 1.3125 5.23288 1.40469 5.06878 1.56878C4.90469 1.73288 4.8125 1.95544 4.8125 2.1875V3.0625"
                        stroke="" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                  <router-link :to="{ name: 'EditProject', params: { uuid: projectDetails.uuid } }"
                    class="ml-5 cursor-pointer  flex">
                    <span class="text-primary text-sm font-medium">Edit</span><img src="@/assets/edit-icon.svg" alt=""
                      class="ml-2 inline-flex">
                  </router-link>
                  <a v-if="projectDetails.brochure_url" :href="projectDetails.brochure_url"
                    class="btn text-main text-sm font-medium text-opacity-50 ml-4" target="_blank">Brochure View</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { numDifferentiation } from '@/assets/scripts/utility.js';
export default {
  data() {
    return {
      photoUrl: [],
      imgList: [],
      projectDetails: {},
      visible: false,
      index: 0,
    }
  },
  created() {
    this.loadProjectDetails()
  },

  methods: {
    shareWhatsApp(proj_detail) {
      window.open(`https://api.whatsapp.com/send?text=%F0%9F%8F%A8+Project+Details%0D%0A%F0%9F%91%89+Project+Name+%3A+${proj_detail.name}${proj_detail.possession_status ? '%0D%0A%F0%9F%91%89+Possession+On+%3A+' + proj_detail.possession_status : ''}${proj_detail.formatted_possession_date ? '%0D%0A%F0%9F%91%89+Possession+Date+%3A+' + proj_detail.formatted_possession_date : ''}${proj_detail.property_age ? '%0D%0A%F0%9F%91%89+Property+Age+%3A+' + proj_detail.property_age + '+Years' : ''}${proj_detail.rera_no ? '+%0D%0A%F0%9F%91%89+Rera+No%3A+' + proj_detail.rera_no : ''}${proj_detail.project_approve_by ? '%0D%0A%F0%9F%91%89+Project+Approved+by%3A+' + proj_detail.project_approve_by : ''}${proj_detail.location ? '%0D%0A%F0%9F%91%89+Project+Location%3A+' + proj_detail.location : ''}${proj_detail.city ? '%0D%0A%F0%9F%91%89+City+%3A+' + proj_detail.city : ''}${proj_detail.locality ? '%28East%29%2C+' + proj_detail.locality : ''}%0D%0A%0D%0A%F0%9F%8C%8D+++https%3A%2F%2Fwww.google.com%2Fmaps%2Fsearch%2F%3Fapi%3D1%26query%3D${proj_detail.lat}%2C${proj_detail.long}%0D%0A%0D%0A`)
    },
    deleteProject(uuid) {
      let vm = this;
      swal({
        title: "Delete this Project?",
        buttons: true,
        buttons: ["No", "Yes"]
      }).then(confirm => {
        if (confirm) {
          vm.$axios
            .delete(`mobile_crm/projects/${uuid}/delete`)
            .then(res => {
              this.$router.replace({ name: "Projects" });
              swal({
                title: "Deleted!",
                icon: "success"
              });
            })
            .catch(err => {
              swal({
                title: "Error",
                text: err.response.data.message,
                icon: "error"
              });
            });
        }
      });
    },
    priceinLacs(value) {
      return numDifferentiation(value)
    },
    showImg(img_url) {
      this.imgList = img_url
      this.index = 1
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    loadProjectDetails() {
      this.$axios.get(`mobile_crm/projects/${this.$route.params.uuid}`)
        .then((res) => {
          this.projectDetails = res.data.project
          this.photoUrl = this.projectDetails.image_url
        })
        .catch((err) => console.log(err));
    },
  },
  computed: {
    userName() {
      return localStorage.getItem('property-user-name')
    },
    created_user(){
      return this.$store.getters.created_user;
    },
    userEmail() {
      return localStorage.getItem('property-user-email')
    },
    userMobile() {
      return localStorage.getItem('property-user-mobile')
    }
  }
}

</script>
<style scoped>
.amenities-label {
  @apply border-r border-divider pr-3 mr-3 inline-flex mb-2;
}

.amenities-label:first-child {
  @apply pl-0;
}

.amenities-label:last-child {
  @apply border-none;
}

.img-gallery:nth-child(4) {
  @apply relative cursor-pointer pointer-events-auto;
}

.img-gallery:nth-child(4)::after {
  @apply absolute bg-black bg-opacity-50 inset-0 rounded-sm;
  content: '';
}

.img-gallery:nth-child(4) span {
  @apply flex;
}

@media (max-width:350px) {
  .h-fix {
    height: 50px;
  }
}
</style>