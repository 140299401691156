<template>
  <div class="animate_animated animate__slideInRight">
    <div class="fixed w-full top-0 left-0 z-20">
      <div class=" bg-white px-4 py-6 pb-5 shadow-ct">
        <div class="flex items-center">
          <div class="w-2/3">
            <div class="flex items-center w-full">
              <a @click="$router.push('/')"><img src="@/assets/back-arrow.svg" alt=""></a>
              <span class="text-base text-content font-medium ml-4">{{ 'Projects' }}</span>
            </div>
          </div>
          <div class="w-1/3">
            <div class="flex items-center justify-end">
              <a @click="share = true" class="mx-2 relative"><img src="@/assets/share.svg" alt="">
                <div v-if="shareSelectedProperty.length > 0"
                  class="absolute w-3 h-3 -right-1 -top-1 bg-primary text-white rounded-full items-center flex justify-center">
                  <span class=" text-xxs block ">{{ shareSelectedProperty.length }}</span></div>
              </a>
              <a @click="$router.push('searchproject')" class="mx-2"><img src="@/assets/filter-icon.svg" alt=""></a>
            </div>
          </div>
        </div>
        <div class="flex mt-5">
          <div class="flex items-strech w-full">
            <div class="relative w-full">
              <input type="text" @input="e => bsTerm = e.target.value" :value="bsTerm" name="" id=""
                placeholder="Search by Project Name, possession status and City"
                class="border border-inputBorder round-md p-3 w-full z-auto focus:outline-none rounded-tl rounded-bl">
              <button type="button" class="absolute right-0 top-0 m-3" v-if="bsTerm" @click="resetBs">
                <img src="@/assets/close-icon.svg" alt="" class="transform rotate-45"></button>
            </div>

            <div class="bg-primary rounded-tr rounded-br px-3 flex items-center">
              <button type="button" :disabled="!bsTerm" @click="performBs">
                <img src="@/assets/search-icon-white.svg" alt="" class=""></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Card-List -->
    <div class="pt-16 mt-18">
      <span class="result-Text px-3 pt-2 text-sm text-main text-opacity-50 block">Showing {{ pageStart }} - {{ pageEnd
      }} of {{ total }}</span>
      <vue-easy-lightbox :visible="visible" :imgs="imgList" @hide="handleHide"></vue-easy-lightbox>
      <div class="shadow-ct bg-white my-4" v-for="(pl, key) in projectList" :key="key">
        <div class="flex items-center p-2 border-b border-divider">
          <div class="w-1/3">
            <div class="flex">
              <input type="checkbox" v-model="shareSelectedProperty" :value="pl" class="mr-2">
              <div class="w-full">
                <img :src="pl.image_url.length > 0 ? pl.image_url : 'https://engage-prod.s3.ap-south-1.amazonaws.com/images/app/engage-app-prod.jpeg'" @click="opeLightBox(pl.image_url)" alt=""
                  class="rounded  h-28 w-full object-cover object-center ">
              </div>
            </div>
          </div>
          <div class="w-2/3">
            <router-link :to="{ name: 'ProjectDetails', params: { uuid: pl.uuid } }">
              <div class="p-3">
                <div class="flex items-center mb-1">
                  <span class="text-xs  font-medium"
                    :class="pl.possession_status === 'Ready to move in' ? 'text-success' : ''">{{ pl.possession_status }}</span>
                    <span v-if="created_user" class="text-xs text-black font-medium ml-2 text-opacity-50">{{pl.user_name}}</span>
                </div>
                <h2 class="text-content text-sm font-medium mb-1">{{ pl.name }}</h2>
                <div class="flex items-center mb-1">
                  <img src="@/assets/location.svg" alt="" class="mr-1"> <span
                    class="text-content text-xs text-opacity-50">{{ pl.locality }}, {{ pl.city }}</span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <!-- Add Button -->
      <router-link :to="{ name: 'NewProject' }" v-if="!$route.query.match"
        class="fixed bottom-0 right-0 m-4 flex items-center justify-center w-14 h-14 bg-primary rounded-full">
        <img src="@/assets/plus-icon.svg" alt="">
      </router-link>
      <div class="pagination-holder">
        <paginate v-if="totalPages > 1" v-model="pageNo" :page-count="totalPages" :click-handler="goToPage"
          :page-class="'page-item'" :page-link-class="'page-link'" :prev-link-class="'page-link'"
          :next-link-class="'page-link'" :disabled-class="'page-item disabled'" :container-class="'pagination'">
        </paginate>
      </div>
      <v-easy-dialog v-model="share" focus-on="#my-input">
        <div class="flex flex-col bg-white p-5 mx-4">
          <h5 class="text-base  font-medium text-main mb-3">Share with</h5>
          <div class="flex py-3 cursor-pointer">
            <img src="@/assets/whatsapp-icon.svg" alt="" class="mr-2">
            <span class="text-base text-main" @click="shareWhatsApp()">Whatsapp</span>
          </div>
          <div class="flex justify-end space-x-2">
            <button @click="share = false" class="text-sm">Close</button>
          </div>
        </div>
      </v-easy-dialog>
    </div>
  </div>
</template>
<script>
import qs from 'qs';
import VueEasyLightbox from 'vue-easy-lightbox'
import VEasyDialog from 'v-easy-dialog'
import { paramsFromQuery, numDifferentiation, prepareProjectSearchParams } from '@/assets/scripts/utility.js';
export default {
  components: {
    VEasyDialog,
    VueEasyLightbox
  },
  name: 'Projects',
  data() {
    return {
      bsTerm: this.$route.query.bs,
      shareSelectedProperty: [],
      total: 0,
      perPage: 0,
      projectList: [],
      advSearchParams: [],
      imgList: [],
      index: 0,
      share: false,
      shareMailLink: '',
      visible: false,
    }
  },
  watch: {
    $route(to, from) {
      this.loadProjects();
    }
  },
  created() {
    this.loadProjects()
  },
  methods: {
    priceinLacs(value) {
      return numDifferentiation(value)
    },
    opeLightBox(img_url) {
      this.imgList = img_url
      this.index = 1
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    shareWhatsApp() {
      let link = 'https://api.whatsapp.com/send?text='
      let url = ''
      this.shareSelectedProperty.forEach(proj_detail => {
        url = url + `%F0%9F%8F%A8+Project+Details%0D%0A%F0%9F%91%89+Project+Name+%3A+${proj_detail.name}${proj_detail.possession_status ? '%0D%0A%F0%9F%91%89+Possession+On+%3A+' + proj_detail.possession_status : ''}${proj_detail.formatted_possession_date ? '%0D%0A%F0%9F%91%89+Possession+Date+%3A+' + proj_detail.formatted_possession_date : ''}${proj_detail.property_age ? '%0D%0A%F0%9F%91%89+Property+Age+%3A+' + proj_detail.property_age + '+Years' : ''}${proj_detail.rera_no ? '+%0D%0A%F0%9F%91%89+Rera+No%3A+' + proj_detail.rera_no : ''}${proj_detail.project_approve_by ? '%0D%0A%F0%9F%91%89+Project+Approved+by%3A+' + proj_detail.project_approve_by : ''}${proj_detail.location ? '%0D%0A%F0%9F%91%89+Project+Location%3A+' + proj_detail.location : ''}${proj_detail.city ? '%0D%0A%F0%9F%91%89+City+%3A+' + proj_detail.city : ''}${proj_detail.locality ? '%28East%29%2C+' + proj_detail.locality : ''}%0D%0A%0D%0A%F0%9F%8C%8D+++https%3A%2F%2Fwww.google.com%2Fmaps%2Fsearch%2F%3Fapi%3D1%26query%3D${proj_detail.lat}%2C${proj_detail.long}%0D%0A%0D%0A`
      })
      link = link + url
      window.open(link)
    },
    loadProjects() {
      let queryP = {}
      if (Object.keys(this.advSParams).length > 0) {
        queryP["as"] = true
        let searchParams = prepareProjectSearchParams(this.advSParams);
        for (let key in searchParams) {
          queryP[key] = searchParams[key];
        }
        this.advSearchParams = queryP
      }
      if (this.advSearchParams.length > 0) {
        paramsFromQuery(queryP, this.advSearchParams, status);
      }
      else {
        paramsFromQuery(queryP, this.$route.query, status);
      }
      let data = this.$axios.get('/mobile_crm/projects', {
        params: queryP,
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' })
      })
        .then(res => {
          this.projectList = res.data.projects;
          this.perPage = res.data.per_page;
          this.total = res.data.count;
        })
        .catch(err => console.log(err));
    },
    goToPage(pageNo) {
      let newQ = { ...this.$route.query, pageNo };
      this.$router.replace({ query: newQ });
    },
    resetBs() {
      let rQ = this.$route.query
      this.bsTerm = ''
      if (rQ.bs) {
        delete rQ.bs;
        let reset = {}
        for (let key in rQ) {
          reset[key] = rQ[key];
        }
        reset['reset'] = reset.reset ? (reset.reset == 0 ? 1 : 0) : 1
        this.$router.replace({ query: reset })
      }
    },
    performBs() {
      if (this.bsTerm) {
        let newQ = { ...this.$route.query, bs: this.bsTerm }
        delete newQ.pageNo
        this.$router.replace({ query: newQ })
      }
    }
  },
  computed: {
    userName() {
      return localStorage.getItem('property-user-name')
    },
    userEmail() {
      return localStorage.getItem('property-user-email')
    },
    userMobile() {
      return localStorage.getItem('property-user-mobile')
    },
    created_user(){
      return this.$store.getters.created_user;
    },
    advSParams() {
      return this.$store.getters.advSParams;
    },
    totalPages() {
      if (this.total === 0 || this.perPage === 0) {
        return 0;
      } else {
        return Math.ceil(this.total / this.perPage);
      }
    },
    pageNo: {
      get() {
        let pageNo = this.$route.query.pageNo || 1
        return parseInt(pageNo);
      },
      set() {
        true;
      }
    },
    pageStart() {
      if (this.total === 0) {
        return 0;
      }
      return (Math.max(0, (this.pageNo - 1) * this.perPage + 1))
    },
    pageEnd() {
      if (this.total === 0) {
        return 0;
      }
      return (Math.min(this.total, this.pageNo * this.perPage))
    },
  }
}
</script>
<style scoped>
</style>