<template>
  <div class="pt-20 mt-2 bg-white">
    <div class="bg-white  p-4">
      <form @submit.prevent="submitFn">
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Price
            *</label>
          <input type="number" v-model="Agreement.price" required placeholder="Enter"
            class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>

        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Buyer
            Brokerage *</label>
          <input type="number" v-model="Agreement.buyer_brokerage" required placeholder="Enter"
            class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>

        <div class="mb-6 relative">
          <label for=""
            class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Seller
            Brokerage *</label>
          <input type="number" v-model="Agreement.seller_brokerage" required placeholder="Enter"
            class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>

        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">CGST
            *</label>
          <input type="text" v-model="Agreement.gst" required placeholder="Enter"
            class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mb-6 relative">
          <label for=""
            class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Description</label>
          <textarea v-model="Agreement.comment" placeholder="Comments" rows="3"
            class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none"></textarea>
        </div>

        <div class="mt-10">
          <button type="submit" :disabled="!showProceed"
            class="bg-primary text-base font-semibold text-white rounded-full p-5 w-full disabled:opacity-50">Submit</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    Agreement: {
      type: Object,
      required: true
    },
    submitFn: {
      type: Function,
    }
  },
  computed: {
    showProceed() {
      if (this.Agreement.price && this.Agreement.gst && this.Agreement.buyer_brokerage
        && this.Agreement.seller_brokerage) {
        return true
      }
    },
  },
}
</script>

<style>
</style>