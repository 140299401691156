<template>
	<div class="animate_animated animate__slideInRight">
		<div class="fixed w-full top-0 left-0 z-20">
		<div class="flex bg-white px-4 py-6 items-center shadow-ct">
			<div class="w-full">
				<div class="flex items-center w-full">
					<a  @click="$router.go(-1)"><img src="@/assets/back-arrow.svg" alt=""></a>
					<span class="text-base text-content font-medium ml-4">Edit Enquiry</span>
				</div>
			</div>
		</div>
    </div>
    <div class="pt-20 mt-2 bg-white">
    <div class="bg-white min-h-screen p-4">
       <Enquiry :enquiry="EditEnquiry" :editSubmitFn="goToStep1" :getProperty="getProperty" :propertyList="propertyList"/>
    </div>
  </div>
	</div>
</template>
<script>
import Enquiry from '@/components/Enquiry/New.vue';
import {prepareEnquiryParams} from '@/assets/scripts/utility.js';
export default {
  components: {
    Enquiry
  },
  data () {
    return {
      EditEnquiry: {},
      propertyList: [],
    }
  },
  created() {
    this.$axios.get(`mobile_crm/leads/${this.$route.params.uuid}`)
    .then((res) => {
      this.EditEnquiry = res.data.lead
      if (this.EditEnquiry.city_id) {
        this.EditEnquiry.city_id = {'id': this.EditEnquiry.city_id, 'name': this.EditEnquiry.city }
      }
      if (this.EditEnquiry.preferred_project_id) {
        this.$axios.get(`mobile_crm/leads/properties?project_id=${this.EditEnquiry.preferred_project_id}`)
        .then((res) => {
          this.EditEnquiry.preferred_project_id = {'id': this.EditEnquiry.preferred_project_id, 'text': this.EditEnquiry.preferred_project }
          this.propertyList = res.data
          })
        .catch((err) => console.log(err));
      }
      let step0_data = this.$store.getters.newEnquiryData
      if (Object.keys(step0_data).length > 0) {
        this.EditEnquiry = step0_data
      }
    })
    .catch((err) => console.log(err));
  },
  methods: {
    getProperty() {
      this.$axios.get(`mobile_crm/leads/properties?project_id=${this.EditEnquiry.preferred_project_id.id}`)
      .then((res) => {
        this.propertyList = res.data
      })
      .catch((err) => console.log(err));
    },
    goToStep1(){
      let apiParams = {}
      apiParams = prepareEnquiryParams(this.EditEnquiry);
      let editEnquiryData = apiParams
      this.$store.dispatch('setnewEnquiryData', editEnquiryData);
      this.$router.push({name: 'EditNextEnquiry'});
    },
  }
}
</script>
<style scoped>

</style>