<template>
	<div>
		<div class="home-bg w-full p-4">
        <vue-drawer-layout
          ref="drawerLayout" :class="{ toggle: clicked }"     @mask-click="handleMaskClick();  clicked = !clicked">
          <div class="drawer bg-white min-h-screen" slot="drawer">
            <div class="p-4 flex justify-between items-center">
              <span>Welcome {{userName}}</span>
              <a href="javascript:void(0)" class="btn  cursor-pointer transform rotate-45"
                @click="handleToggleDrawer(); clicked = !clicked "><img src="@/assets/close-icon.svg" alt="" class="w-4 h-4 opacity-50"></a>
            </div>
            <div class="">
       
            </div>
            <div class="p-4 text-center fixed bottom-0 left-0 w-full mb-5">
              <span class="block text-sm text-main text-opacity-80 mb-4"
                >{{userEmail}}</span
              >
              <div class="">
                <a href="javascript:void(0);" class="navbar-brand" @click="logout">Logout</a>
              </div>
            </div>
          
          </div>
          <div class="content p-4 absolute top-0 left-0 z-50" slot="content">
            <a to='/'  class="cursor-pointer"><svg width="24" height="24" @click="handleToggleDrawer(); clicked = !clicked" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 5V7H22V5H2ZM2 11V13H22V11H2ZM2 17V19H22V17H2Z" fill="white" />
              </svg>
            </a>
          </div>
        </vue-drawer-layout>
			<span class="text-white text-2xl text-center block pt-20">{{setDays}}, <span class="font-semibold block">{{userName}}</span></span>
			<div class="home-icon"></div>
		</div>
		   <router-link to="/expiringlease">
		<div class="divide-y divide-divider divide-solid mx-4 bg-white rounded-lg mb-4 relative z-40  -m-10">
			<div class="flex items-center justify-between px-5 py-6">
				<div class="flex">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path opacity="0.35"
							d="M21.121 9.87802L14.121 2.87802C12.949 1.70602 11.05 1.70602 9.87799 2.87802L2.87799 9.87802C1.70599 11.05 1.70599 12.949 2.87799 14.121L9.87799 21.121C11.05 22.293 12.949 22.293 14.121 21.121L21.121 14.121C22.293 12.95 22.293 11.05 21.121 9.87802Z"
							fill="#4183E0" />
						<path
							d="M13.42 16.489C13.42 16.914 13.173 18 11.993 18C10.813 18 10.581 16.914 10.581 16.489C10.581 16.074 10.844 14.96 11.993 14.96C13.142 14.96 13.42 16.074 13.42 16.489ZM10.698 12.499V7.25903C10.698 6.54003 11.281 5.95703 12 5.95703C12.719 5.95703 13.302 6.54003 13.302 7.25903V12.5C13.302 13.219 12.719 13.802 12 13.802C11.281 13.801 10.698 13.218 10.698 12.499Z"
							fill="#4183E0" />
					</svg>

					<span class="ml-4 text-content text-base font-medium">Expiring Lease</span>
				</div>
				<div class="flex items-center">
					<span class="text-red text-base font-medium mr-2">{{expiringLeaseCount}}</span>
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g opacity="0.3">
							<path
								d="M8.33331 4.94141L7.08331 6.19141L10.8919 10L7.08331 13.8086L8.33331 15.0586L13.3919 10L8.33331 4.94141Z"
								fill="#172F45" />
						</g>
					</svg>
				</div>
			</div>
		</div>
    </router-link>
		<div class="divide-y divide-divider divide-solid mx-4 bg-white rounded-lg mb-4 relative z-40">
      <router-link class="flex items-center justify-between px-5 py-6" to="/projects">
				<div class="flex">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.35" d="M18 6H16V3H8V6H6C4.895 6 4 6.895 4 8V20C4 21.105 4.895 22 6 22H18C19.105 22 20 21.105 20 20V8C20 6.895 19.105 6 18 6H18Z" fill="#4183E0"/>
          <path d="M13 11H11V9H13V11Z" fill="#4183E0"/>
          <path d="M13 15H11V13H13V15Z" fill="#4183E0"/>
          <path d="M13 22H11V17H13V22Z" fill="#4183E0"/>
          <path d="M17 11H15V9H17V11Z" fill="#4183E0"/>
          <path d="M17 15H15V13H17V15Z" fill="#4183E0"/>
          <path d="M17 19H15V17H17V19Z" fill="#4183E0"/>
          <path d="M9 11H7V9H9V11Z" fill="#4183E0"/>
          <path d="M9 15H7V13H9V15Z" fill="#4183E0"/>
          <path d="M9 19H7V17H9V19Z" fill="#4183E0"/>
          <path d="M16 2H8C6.895 2 6 2.895 6 4V6H9V4H11V6H13V4H15V6H18V4C18 2.895 17.105 2 16 2V2Z" fill="#4183E0"/>
          </svg>
					<span class="ml-4 text-content text-base font-medium">Projects</span>
				</div>
				<div>
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g opacity="0.3">
							<path
								d="M8.33331 4.94141L7.08331 6.19141L10.8919 10L7.08331 13.8086L8.33331 15.0586L13.3919 10L8.33331 4.94141Z"
								fill="#172F45" />
						</g>
					</svg>
				</div>
      </router-link>
    
			<router-link class="flex items-center justify-between px-5 py-6" to="/properties">
				<div class="flex">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path opacity="0.35" d="M10 8H4V20H10V8Z" fill="#4183E0" />
						<path opacity="0.35"
							d="M20 21H11V5C11 3.895 11.895 3 13 3H19C20.105 3 21 3.895 21 5V20C21 20.552 20.552 21 20 21Z"
							fill="#4183E0" />
						<path d="M19 7H17V5H19V7Z" fill="#4183E0" />
						<path d="M15 7H13V5H15V7Z" fill="#4183E0" />
						<path d="M19 11H17V9H19V11Z" fill="#4183E0" />
						<path d="M15 11H13V9H15V11Z" fill="#4183E0" />
						<path d="M19 15H17V13H19V15Z" fill="#4183E0" />
						<path d="M15 15H13V13H15V15Z" fill="#4183E0" />
						<path d="M19 19H17V17H19V19Z" fill="#4183E0" />
						<path d="M15 19H13V17H15V19Z" fill="#4183E0" />
						<path
							d="M9 6H5C3.895 6 3 6.895 3 8V20C3 20.552 3.448 21 4 21H11V8C11 6.895 10.105 6 9 6ZM9 19H5V17H9V19ZM9 15H5V13H9V15ZM9 11H5V9H9V11Z"
							fill="#4183E0" />
					</svg>
					<span class="ml-4 text-content text-base font-medium">Properties</span>
				</div>
				<div>
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g opacity="0.3">
							<path
								d="M8.33331 4.94141L7.08331 6.19141L10.8919 10L7.08331 13.8086L8.33331 15.0586L13.3919 10L8.33331 4.94141Z"
								fill="#172F45" />
						</g>
					</svg>
				</div>
      </router-link>
		 <router-link to="/enquiry" class="flex items-center justify-between px-5 py-6">
				<div class="flex">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path opacity="0.35" d="M13 15H8V21H13V15Z" fill="#4183E0" />
						<path opacity="0.35"
							d="M11 12C13.7614 12 16 9.76142 16 7C16 4.23858 13.7614 2 11 2C8.23858 2 6 4.23858 6 7C6 9.76142 8.23858 12 11 12Z"
							fill="#4183E0" />
						<path
							d="M10.344 16.406L9.5 15C7.161 15 5.146 15 5 15C3.343 15 2 16.343 2 18C2 19.657 3.343 21 5 21C5.137 21 6.931 21 9.091 21L10.344 16.406Z"
							fill="#4183E0" />
						<path
							d="M11.656 16.406L12.5 15C14.839 15 16.854 15 17 15C18.657 15 20 16.343 20 18C20 19.657 18.657 21 17 21C16.863 21 15.069 21 12.909 21L11.656 16.406Z"
							fill="#4183E0" />
					</svg>

					<span class="ml-4 text-content text-base font-medium">Enquiries</span>
				</div>
				<div>
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g opacity="0.3">
							<path
								d="M8.33331 4.94141L7.08331 6.19141L10.8919 10L7.08331 13.8086L8.33331 15.0586L13.3919 10L8.33331 4.94141Z"
								fill="#172F45" />
						</g>
					</svg>
				</div>
      </router-link>
			 <router-link to="/agreement" class="flex items-center justify-between px-5 py-6">
				<div class="flex">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path opacity="0.35"
							d="M2 18.9301V13.1291C2 12.4471 2.469 11.8541 3.133 11.6961L5.438 11.1511C6.616 10.8721 7.853 10.9811 8.965 11.4601L13.344 13.3481C14.733 13.9471 15.359 15.5711 14.73 16.9471L15.103 17.4361L19.756 15.7751C20.639 15.4471 21.618 15.9161 21.916 16.8091C22.178 17.5951 21.822 18.4541 21.081 18.8251L16.458 21.1531C15.537 21.6131 14.471 21.6891 13.494 21.3641L7.354 19.3171C6.479 19.0251 5.534 19.0231 4.657 19.3091L3.185 19.7901C2.601 19.9811 2 19.5451 2 18.9301Z"
							fill="#4183E0" />
						<path
							d="M17 1C14.791 1 13 2.791 13 5C13 6.479 13.805 7.769 15 8.461V11.991C15 12.345 15.141 12.684 15.391 12.934L16.058 13.601C16.579 14.122 17.423 14.122 17.943 13.601L18.61 12.934C18.859 12.683 19 12.344 19 11.99V8.46C20.195 7.769 21 6.479 21 5C21 2.791 19.209 1 17 1ZM17 5C16.448 5 16 4.552 16 4C16 3.448 16.448 3 17 3C17.552 3 18 3.448 18 4C18 4.552 17.552 5 17 5Z"
							fill="#4183E0" />
					</svg>
					<span class="ml-4 text-content text-base font-medium">Agreements</span>
				</div>
				<div>
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g opacity="0.3">
							<path
								d="M8.33331 4.94141L7.08331 6.19141L10.8919 10L7.08331 13.8086L8.33331 15.0586L13.3919 10L8.33331 4.94141Z"
								fill="#172F45" />
						</g>
					</svg>
				</div>
      </router-link>
		</div>
<div class="flex mx-4 bg-white rounded-t-lg mt-4 relative z-40 pt-6  px-2">
	<div class="w-1/3 border-r border-b border-inputBorder">
		<router-link :to="{name: 'Enquiry', query: {status_id: hot_status_ids}}" class="flex items-center justify-center flex-col pb-4">
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M12 3.42847C16.1905 7.23799 18.2857 10.7754 18.2857 14.0407C18.2857 17.5585 16.8341 19.9815 14.7298 21.0677C14.993 20.5058 15.1429 19.8196 15.1429 19.0203C15.1429 17.3877 14.0952 15.6189 12 13.7142C9.90477 15.6189 8.85715 17.3877 8.85715 19.0203C8.85715 19.8196 9.00703 20.5058 9.2693 21.0677C7.16596 19.9815 5.71429 17.5585 5.71429 14.0407C5.71429 10.7754 7.80953 7.23799 12 3.42847V3.42847Z"
					stroke="#4183E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
			</svg>
			<span class="text-xs text-content font-normal mt-2">Hot Leads</span>
		</router-link>
	</div>
	<div class="w-1/3 border-r border-b border-inputBorder">
		<router-link :to="{name: 'Enquiry', query: {status_id: 1}}" class="flex items-center justify-center flex-col pb-4">
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path opacity="0.9"
					d="M13.0058 16.45L11.2043 21.34C11.1512 21.484 11.0553 21.6082 10.9295 21.6959C10.8036 21.7837 10.6539 21.8307 10.5005 21.8307C10.3471 21.8307 10.1974 21.7837 10.0715 21.6959C9.94569 21.6082 9.84978 21.484 9.79675 21.34L7.99517 16.45C7.95765 16.3482 7.89848 16.2557 7.82174 16.179C7.74501 16.1022 7.65253 16.0431 7.55069 16.0055L2.6607 14.204C2.51675 14.1509 2.39252 14.055 2.30478 13.9292C2.21703 13.8033 2.16998 13.6536 2.16998 13.5002C2.16998 13.3468 2.21703 13.1971 2.30478 13.0712C2.39252 12.9454 2.51675 12.8495 2.6607 12.7964L7.55069 10.9949C7.65253 10.9573 7.74501 10.8982 7.82174 10.8214C7.89848 10.7447 7.95765 10.6522 7.99517 10.5504L9.79675 5.6604C9.84978 5.51644 9.94569 5.39222 10.0715 5.30447C10.1974 5.21672 10.3471 5.16968 10.5005 5.16968C10.6539 5.16968 10.8036 5.21672 10.9295 5.30447C11.0553 5.39222 11.1512 5.51644 11.2043 5.6604L13.0058 10.5504C13.0434 10.6522 13.1025 10.7447 13.1793 10.8214C13.256 10.8982 13.3485 10.9573 13.4503 10.9949L18.3403 12.7964C18.4843 12.8495 18.6085 12.9454 18.6962 13.0712C18.784 13.1971 18.831 13.3468 18.831 13.5002C18.831 13.6536 18.784 13.8033 18.6962 13.9292C18.6085 14.055 18.4843 14.1509 18.3403 14.204L13.4503 16.0055C13.3485 16.0431 13.256 16.1022 13.1793 16.179C13.1025 16.2557 13.0434 16.3482 13.0058 16.45V16.45Z"
					stroke="#4183E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M16.5 1.5V6" stroke="#B3CDF3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M18.75 3.75H14.25" stroke="#B3CDF3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M21 6.75V9.75" stroke="#B3CDF3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M22.5 8.25H19.5" stroke="#B3CDF3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
			</svg>
			<span class="text-xs text-content font-normal mt-2">New Leads</span>
		</router-link>
	</div>
	<div class="w-1/3  border-b border-inputBorder">
		<router-link :to="{name: 'Enquiry', query: {status_id: following_ids}}" class="flex items-center justify-center flex-col pb-4">
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M7.99998 4C6.97394 4 6.15623 4.64583 5.71873 5.5C4.67706 5.71875 3.74477 6.34896 3.3229 7.36458C2.5156 9.29167 1.33331 12.5156 1.33331 15.3333C1.33331 17.901 3.43227 20 5.99998 20C7.84894 20 9.44269 18.901 10.1979 17.3333H13.8021C14.5573 18.901 16.151 20 18 20C20.5677 20 22.6666 17.901 22.6666 15.3333C22.6666 14.2865 22.3437 12.9219 21.9375 11.5C21.526 10.0729 21.0208 8.61458 20.625 7.52083C20.2396 6.47396 19.3333 5.80208 18.2969 5.53646C17.8646 4.66146 17.0416 4 16 4C15.0469 4 14.2969 4.57292 13.8281 5.33333H10.1719C9.7031 4.57292 8.9531 4 7.99998 4ZM7.99998 5.33333C8.58331 5.33333 9.06769 5.70833 9.24998 6.22396L9.40623 6.66667H14.5937L14.75 6.22396C14.9323 5.70833 15.4166 5.33333 16 5.33333C16.5989 5.33333 17.0885 5.72917 17.2604 6.26563L17.3854 6.65625L17.7916 6.71875C18.5208 6.83333 19.1198 7.29167 19.375 7.97917C19.7239 8.9375 20.1458 10.1823 20.5156 11.4167C19.7864 10.9479 18.9271 10.6667 18 10.6667C16.151 10.6667 14.5573 11.7656 13.8021 13.3333H10.1979C9.44269 11.7656 7.84894 10.6667 5.99998 10.6667C4.98435 10.6667 4.04685 11.0052 3.28123 11.5625C3.66144 10.1719 4.15102 8.84896 4.55206 7.88021C4.8281 7.21354 5.4479 6.78125 6.17706 6.70313L6.60415 6.65625L6.73956 6.25C6.91665 5.71875 7.40623 5.33333 7.99998 5.33333ZM5.99998 12C7.84894 12 9.33331 13.4844 9.33331 15.3333C9.33331 17.1823 7.84894 18.6667 5.99998 18.6667C4.15102 18.6667 2.66665 17.1823 2.66665 15.3333C2.66665 13.4844 4.15102 12 5.99998 12ZM18 12C19.8489 12 21.3333 13.4844 21.3333 15.3333C21.3333 17.1823 19.8489 18.6667 18 18.6667C16.151 18.6667 14.6666 17.1823 14.6666 15.3333C14.6666 13.4844 16.151 12 18 12ZM10.5989 14.6667H13.401C13.3698 14.8854 13.3333 15.1042 13.3333 15.3333C13.3333 15.5625 13.3698 15.7813 13.401 16H10.5989C10.6302 15.7813 10.6666 15.5625 10.6666 15.3333C10.6666 15.1042 10.6302 14.8854 10.5989 14.6667Z"
					fill="#4183E0" stroke="#4183E0" stroke-width="0.3" />
			</svg>

			<span class="text-xs text-content font-normal mt-2">Following</span>
		</router-link>
	</div>

</div>
<div class="flex mx-4 bg-white rounded-b-lg relative z-40 pb-6 px-2 ">
	<div class="w-1/3 border-r  border-inputBorder">
		<router-link :to="{name: 'Enquiry', query: {status_id: bookingDoneIds}}" class="flex items-center justify-center flex-col pt-4 pb-2">
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path opacity="0.4"
					d="M5.10571 18.8943C4.24283 18.0314 4.81514 16.2198 4.37595 15.1584C3.92066 14.058 2.25 13.1723 2.25 12C2.25 10.8276 3.92067 9.942 4.37595 8.84164C4.81515 7.78015 4.24283 5.96858 5.10571 5.10571C5.96858 4.24283 7.78016 4.81514 8.84165 4.37595C9.94203 3.92066 10.8277 2.25 12 2.25C13.1724 2.25 14.058 3.92067 15.1584 4.37595C16.2199 4.81515 18.0314 4.24283 18.8943 5.10571C19.7572 5.96858 19.1849 7.78016 19.6241 8.84165C20.0793 9.94203 21.75 10.8277 21.75 12C21.75 13.1724 20.0793 14.058 19.624 15.1584C19.1848 16.2199 19.7572 18.0314 18.8943 18.8943C18.0314 19.7572 16.2198 19.1849 15.1584 19.6241C14.058 20.0793 13.1723 21.75 12 21.75C10.8276 21.75 9.942 20.0793 8.84164 19.624C7.78015 19.1848 5.96858 19.7572 5.10571 18.8943Z"
					stroke="#4183E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M16.125 9.75L10.625 15L7.875 12.375" stroke="#4183E0" stroke-width="2" stroke-linecap="round"
					stroke-linejoin="round" />
			</svg>

			<span class="text-xs text-content font-normal mt-2">Booking Done</span>
		</router-link>
	</div>

	<div class="w-1/3  border-r  border-inputBorder" >
		<router-link :to="{name: 'Enquiry', query: {status_id: attempt_to_contact.length > 0 ? attempt_to_contact : 2}}" class="flex items-center justify-center flex-col pt-4 pb-2">
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M8.66965 11.7014C9.44762 13.2919 10.7369 14.5753 12.3309 15.346C12.4475 15.4013 12.5765 15.4252 12.7052 15.4155C12.8339 15.4058 12.9579 15.3627 13.0648 15.2905L15.4119 13.7254C15.5157 13.6562 15.6352 13.6139 15.7594 13.6025C15.8837 13.5911 16.0088 13.6109 16.1235 13.66L20.5144 15.5419C20.6636 15.6052 20.7881 15.7154 20.8693 15.8556C20.9504 15.9959 20.9838 16.1588 20.9643 16.3197C20.8255 17.4057 20.2956 18.4039 19.4739 19.1273C18.6521 19.8508 17.5948 20.2499 16.5 20.25C13.1185 20.25 9.87548 18.9067 7.48439 16.5156C5.0933 14.1245 3.75 10.8815 3.75 7.49997C3.75006 6.40513 4.14918 5.34786 4.87264 4.5261C5.5961 3.70435 6.59428 3.17448 7.68028 3.03569C7.84117 3.01622 8.00403 3.04956 8.14432 3.1307C8.28461 3.21183 8.39473 3.33636 8.4581 3.48552L10.3416 7.88032C10.3903 7.994 10.4101 8.11796 10.3994 8.24116C10.3886 8.36436 10.3475 8.48299 10.2798 8.58647L8.72011 10.9696C8.64912 11.0768 8.60716 11.2006 8.59831 11.3288C8.58947 11.4571 8.61405 11.5855 8.66965 11.7014V11.7014Z"
					stroke="#4183E0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M15.75 4.5H19.5V8.25" stroke="#B3CDF3" stroke-width="1.5" stroke-linecap="round"
					stroke-linejoin="round" />
				<path d="M15 9L19.5 4.5" stroke="#B3CDF3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
			</svg>
			<span class="text-xs text-content font-normal mt-2">Attempt Contact</span>
		</router-link>
	</div>

	<div class="w-1/3 ">
		<router-link :to="{name: 'Enquiry'}" class="flex items-center justify-center flex-col pt-4 pb-2">
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25Z"
					stroke="#4183E0" stroke-width="1.5" stroke-miterlimit="10" />
				<path
					d="M4.5 14.25C5.74264 14.25 6.75 13.2426 6.75 12C6.75 10.7574 5.74264 9.75 4.5 9.75C3.25736 9.75 2.25 10.7574 2.25 12C2.25 13.2426 3.25736 14.25 4.5 14.25Z"
					stroke="#4183E0" stroke-width="1.5" stroke-miterlimit="10" />
				<path opacity="0.4"
					d="M19.5 14.25C20.7426 14.25 21.75 13.2426 21.75 12C21.75 10.7574 20.7426 9.75 19.5 9.75C18.2574 9.75 17.25 10.7574 17.25 12C17.25 13.2426 18.2574 14.25 19.5 14.25Z"
					stroke="#4183E0" stroke-width="1.5" stroke-miterlimit="10" />
			</svg>
			<span class="text-xs text-content font-normal mt-2">View All</span>
		</router-link>
	</div>
</div>
<div class="flex m-4">
	<div class="w-1/2">
<div class=" bg-white rounded-lg relative z-40 p-4 shadow-grid mr-1">
			<router-link :to="{name: 'TodaysCalls'}" class="">
			<span class="block font-semibold text-2xl text-primary">{{todayCount}}</span>
			<span class="text-xs text-content font-medium mt-2">Today's Calls</span>
		</router-link>
</div>
	</div>
		<div class="w-1/2">
<div class=" bg-white rounded-lg relative z-40 p-4 shadow-grid ml-1">
			<router-link :to="{name: 'Backlogs'}" class="">
			<span class="block font-semibold text-2xl text-primary">{{blCount}}</span>
			<span class="text-xs text-content font-medium mt-2">Backlogs</span>
		</router-link>
</div>
	</div>
</div>
</div>
</template>
<script>
export default {
  name: 'Home',
  data () {
    return {
      clicked: false,
      expiringLeaseCount: 0,
      todayCount: 0,
      blCount: 0
    }
  },
  methods: {
    handleToggleDrawer() {
      this.$refs.drawerLayout.toggle();
    },
    logout(){
      swal({
        title: "Are you sure?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          this.$axios.delete('/logout')
            .then(res => {
              if(res.data.status == true){
                localStorage.removeItem('property-user-token');
                localStorage.removeItem('property-user-email');
                localStorage.removeItem('property-user-mobile');
                localStorage.removeItem('property-user-name');
                this.$router.replace('/login');
                return;
              }
            })
            .catch(err => console.log(err));
        }
      });
    },
    handleMaskClick() {
      this.$refs.drawerLayout.toggle(false);
    }
  },
  created() {
    this.$axios
    .get("mobile_crm/dashboard")
    .then(res => {
      this.todayCount = res.data.todays_call
      this.blCount = res.data.backlogs
    })
    .catch(err => console.log(err));
    this.$axios.get(`mobile_crm/agreements/expiring_lease_count?expiring=true`)
      .then((res) => {
        this.expiringLeaseCount = res.data
        this.$store.dispatch('setAdvSParams', {});
    })
    .catch((err) => console.log(err));
    },
  computed: {
    userName(){
      return localStorage.getItem('property-user-name')
    },
    hot_status_ids() {
      return this.$store.getters.hot_status_ids;
    },
    statusesMaster() {
      return this.$store.getters.statusesMaster;
    },
    following_ids() {
      return this.statusesMaster.filter(s => ['Follow Up', 'Following'].includes(s.name)).map(s => s.id)
    },
    attempt_to_contact() {
      return this.statusesMaster.filter(s => ['Attempted to Contact'].includes(s.name)).map(s => s.id)
    },
    bookingDoneIds() {
      return this.$store.getters.bookingDoneIds;
    },
    setDays() {
      let d = new Date()
      let hr = d.getHours()
      if (hr >= 0 && hr < 12) {
        return 'Good Morning'
      } else if (hr == 12) {
        return 'Good Afternoon'
      } else if (hr >= 12 && hr <= 17) {
        return 'Good Afternoon'
      } else {
        return 'Good Evening'
      }
    },
    userEmail(){
      return localStorage.getItem('property-user-email')
    },
    title(){
      return this.$store.getters.title;
    }
  }
}
</script>
<style scoped>
	.home-bg {
		background: linear-gradient(201.03deg, #4EA6F7 -21.71%, #4183E0 80.03%);
		min-height: 295px;
	}
	.home-icon {
		@apply absolute top-0 left-0 z-10 w-full mix-blend-soft-light;
		background: url('../assets/home-bg.svg') no-repeat center -60%;
		min-height: 295px;
	}
</style>
