<template>
  <div class="animate_animated animate__slideInRight">
    <div class="fixed w-full top-0 left-0 z-20">
      <div class="flex bg-white px-4 py-6 items-center shadow-ct">
        <div class="w-full">
          <div class="flex items-center w-full">
            <a  @click="$router.go(-1)"><img src="@/assets/back-arrow.svg" alt=""></a>
            <span class="text-base text-content font-medium ml-4">Edit Property</span>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-20 bg-white mt-2">
      <div class="bg-white  p-4">
        <Property :property="EditProperty"/>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block mb-2">Add Photo</label>
          <label for="upload"
            class="text-center rounded-md border border-inputBorder bg-grey  h-24  flex flex-col items-center justify-center">
            <input type="file" multiple ref="file" id="upload" class="hidden" @change="addPhoto">
            <img src="@/assets/upload-icon.svg" alt="">
            <span class="text-main text-opacity-50 text-sm font-medium block mt-1">{{ photo_attributes.length>0 ? (photo_attributes.length === 1 ? photo_attributes[0].name : `${photo_attributes.length} files selected`) : "Upload Images" }}</span>
          </label>
          <p class="text-main text-opacity-50 text-sm font-medium block mt-1 text-center">Image files type accept .jpg .png</p>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block mb-2">brochure</label>
          <label for="brochure"
            class="text-center rounded-md border border-inputBorder bg-grey  h-24  flex flex-col items-center justify-center">
            <input type="file" ref="brochure" id="brochure" class="hidden" @change="addBrochure">
            <img src="@/assets/upload-icon.svg" alt="">
            <span class="text-main text-opacity-50 text-sm font-medium block mt-1">{{ brochure_name ? brochure_name : "Upload Documents" }}</span>
          </label>
        </div>
        <div class="mb-6 relative">
          <label for=""
            class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Description</label>
          <textarea v-model="EditProperty.comment" placeholder="Comments" rows="3"
            class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none"></textarea>
        </div>
        <div class="mb-6 relative">
          <label for=""
            class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Additional Info</label>
          <textarea v-model="EditProperty.external_comments" placeholder="Additional Info" rows="3"
            class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none"></textarea>
        </div>
        <div class="mt-10">
          <button type="button"  @click="updateProperty"
            class="bg-primary text-base font-semibold text-white rounded-full p-5 w-full disabled:opacity-50"
            >Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {prepareSubmitPropertyParams} from '@/assets/scripts/utility.js';
import Property from '@/components/Property/Next2.vue';
export default {
  components: {
    Property
  },
  data () {
    return {
      photo_attributes: [],
      EditProperty: {},
      brochure_name: null
    }
  },
  created() {
    this.$axios.get(`mobile_crm/properties/${this.$route.params.uuid}`)
    .then((res) => {
      this.EditProperty = res.data.property
      })
  .catch((err) => console.log(err));
  },
  methods: {
    addPhoto() {
      this.photo_attributes = this.$refs.file.files
    },
    addBrochure() {
       this.EditProperty.brochure = this.$refs.brochure.files[0];
       this.brochure_name = this.$refs.brochure.files[0].name
    },
    updateProperty() {
      let formData = new FormData();
      let finalSubmitData = this.$store.getters.newPropertyData
      let  nextParams = prepareSubmitPropertyParams(this.EditProperty)
      let propertyData = {...finalSubmitData, ...nextParams}
      if (this.$refs.file.files.length > 0) {
        for (let file of this.$refs.file.files) {
          formData.append('images_attributes[][image]', file)
          formData.append('images_attributes[][_destroy]', false)
        }
      }
      if (propertyData.uuid) {
        formData.append('uuid', propertyData.uuid);
        formData.append('id', propertyData.id);
      }
      if (propertyData.brochure) {
        formData.append('brochure', propertyData.brochure)
      }
      if (propertyData.broker_id) {
        formData.append('broker_id', propertyData.broker_id || '');
      }
      formData.append('property_address', propertyData.property_address || '');
      formData.append('property_for', propertyData.property_for || '');
      formData.append('property_from', propertyData.property_from || '');
      formData.append('building_type', propertyData.building_type || '');
      formData.append('property_type', propertyData.property_type || '');
      formData.append('configuration', propertyData.configuration || '')
      formData.append('owner', propertyData.owner || '')
      formData.append('deposit_amount', propertyData.deposit_amount || '')
      formData.append('owner_contact', propertyData.owner_contact || '')
      formData.append('other_contacts', propertyData.other_contacts || '')
      formData.append('status', propertyData.status || '')
      formData.append('project_name', propertyData.project_name || '')
      formData.append('lat', propertyData.lat || '')
      formData.append('long', propertyData.long || '')
      formData.append('posession_status', propertyData.posession_status || '')
      formData.append('property_source_id', propertyData.property_source_id || '')
      formData.append('area_unit', propertyData.area_unit || '')
      formData.append('builtup_area', propertyData.builtup_area || '')
      formData.append('carpet_area', propertyData.carpet_area || '')
      formData.append('property_age', propertyData.property_age || '')
      formData.append('property_approve_by', propertyData.property_approve_by || '')
      formData.append('expected_price', propertyData.expected_price || '')
      formData.append('car_parking_charge', propertyData.car_parking_charge || '')
      formData.append('base_cost_rate', propertyData.base_cost_rate || '')
      formData.append('maintenance_cost', propertyData.maintenance_cost || '')
      formData.append('rera_no', propertyData.rera_no || '')
      formData.append('city_id', propertyData.city_id || '')
      formData.append('locality_id', propertyData.locality_id || '')
      formData.append('zip', propertyData.zip || '')
      formData.append('unit_no', propertyData.unit_no || '')
      formData.append('floor_id', propertyData.floor_id || '')
      formData.append('wing', propertyData.wing || '')
      formData.append('vastu_compliant', propertyData.vastu_compliant || '')
      formData.append('building', propertyData.building || '')
      formData.append('landmark', propertyData.landmark || '')
      formData.append('furnishing_type_id', propertyData.furnishing_type_id || '')
      formData.append('facing', propertyData.facing || '')
      formData.append('watersource', propertyData.watersource || '')
      formData.append('no_of_floors', propertyData.no_of_floors || '')
      formData.append('no_of_balcony', propertyData.no_of_balcony || '')
      formData.append('no_of_terrace', propertyData.no_of_terrace || '')
      formData.append('no_of_utility_room', propertyData.no_of_utility_room || '')
      formData.append('bathroom', propertyData.bathroom || '')
      formData.append('possession_date', propertyData.possession_date || '')
      formData.append('project_id', propertyData.project_id || '')
      formData.append('referal_name', propertyData.referal_name || '')
      formData.append('referal_mobile', propertyData.referal_mobile || '')
      formData.append('priority', propertyData.priority || '')
      formData.append('frontage', propertyData.frontage || '')
      formData.append('height', propertyData.height || '')
      formData.append('electricity', propertyData.electricity || '')
      if (propertyData.building_type === 'Commercial') {
        formData.append('no_of_kitchen', propertyData.no_of_kitchen || '')
        formData.append('no_of_workstations', propertyData.no_of_workstations || '')
        formData.append('no_of_cabins', propertyData.no_of_cabins || '')
        formData.append('no_of_server_rooms', propertyData.no_of_server_rooms || '')
        formData.append('no_of_meeting_room', propertyData.no_of_meeting_room || '')
        formData.append('no_of_cubicles', propertyData.no_of_cubicles || '')
        formData.append('no_of_conference_room', propertyData.no_of_conference_room || '')
        formData.append('no_of_seaters', propertyData.no_of_seaters || '')
      }
      formData.append('parking', propertyData.parking || '')
      if (propertyData.amenity_ids.length > 0) {
        propertyData.amenity_ids.forEach(am => {
          formData.append('amenity_ids[]', am.id)
        })
      } else {
        formData.append('amenity_ids[]', '')
      }
      formData.append('comment', propertyData.comment || '')
      formData.append('external_comments', propertyData.external_comments || '')
      this.$axios
        .put(`/mobile_crm/properties/${this.$route.params.uuid}`, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          swal({
            title: "Property Updated!",
            icon: "success"
          }).then(this.$router.replace({ name: "Properties" }));
          this.$store.dispatch('setnewPropertyData', null);
        })
        .catch(err => {
          if (err.response.status === 500) {
            swal({
              title: "Server Error!",
              icon: "error"
            });
          } else {
            swal({
              title: "Error",
              text: err.response.data.message,
              icon: "error"
            });
          }
        });
      },
  },
  computed: {
    propertyKeysList(){
      return this.$store.getters.propertyKeys;
    },
  }
}
</script>
<style scoped>

</style>