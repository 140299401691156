<template>
    <form  @submit.prevent="property.uuid ? editSubmitFn() : submitFn()">
      <div class="mb-10">
        <label for="" class="text-main text-opacity-50 text-xs block mb-5 ml-4">Property For *</label>
        <div class="flex">
          <span class="radio-selection">
            <input type="radio" v-model="property.property_for"  value="Sale" name="propertyFor" id="sale" class="hidden">
            <label for="sale" class="bg-divider py-3 px-6 text-content rounded-full font-medium pills mr-3"> Sale </label>
          </span>
            <span class="radio-selection">
            <input type="radio" v-model="property.property_for" value="Rental" name="propertyFor" id="rent" class="hidden">
            <label for="rent" class="bg-divider py-3 px-6 text-content rounded-full font-medium pills"> Rental </label>
          </span>
        </div>
      </div>
        <div class="mb-10">
        <label for="" class="text-main text-opacity-50 text-xs block mb-5 ml-4">Property From *</label>
        <div class="flex">
          <span class="radio-selection">
            <input type="radio" v-model="property.property_from" name="propertyFrom" value="Owner" id="owner" class="hidden">
            <label for="owner" class="bg-divider py-3 px-6 text-content rounded-full font-medium pills mr-3"> Owner </label>
          </span>
            <span class="radio-selection">
            <input type="radio" v-model="property.property_from" name="propertyFrom" value="Developer" id="developer" class="hidden">
            <label for="developer" class="bg-divider py-3 px-6 text-content rounded-full font-medium pills mr-3"> Developer </label>
          </span>
          <span class="radio-selection">
            <input type="radio" v-model="property.property_from" name="propertyFrom" value="CP" id="CP" class="hidden">
            <label for="CP" class="bg-divider py-3 px-6 text-content rounded-full font-medium pills"> CP </label>
          </span>
        </div>
      </div>
      <div class="mb-6 relative" v-if="property.property_from ==='CP'">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Broker</label>
        <select v-model="property.broker_id" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
          <option selected disabled>Select</option>
          <option
            v-for="(br, key) in propertyKeysList.brokers"
            :key="key"
            :value="br.id"
          >{{br.name}}</option>
        </select>
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Project</label>
        <select v-model="property.project_id" @change="getProjectDetails(property.project_id)" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
          <option selected :value="null">Select the type</option>
          <option
            v-for="(pr, key) in propertyKeysList.projects"
            :key="key"
            :value="pr.id"
          >{{pr.text}}</option>
        </select>
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Building Type *</label>
        <select v-model="property.building_type" required class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
          <option selected disabled>Select the type</option>
          <option
            v-for="(bt, key) in propertyKeysList.building_types"
            :key="key"
            :value="bt"
          >{{bt}}</option>
        </select>
      </div>
        <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Type *</label>
        <select v-model="property.property_type" required class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
          <option selected disabled>Select the type</option>
          <option
            v-for="(pt, key) in propertyType"
            :key="key"
            :value="pt"
          >{{pt}}</option>
        </select>
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Configuration</label>
        <select v-model="property.configuration" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
          <option selected disabled>Select Configuration</option>
          <option
            v-for="(c, key) in propertyKeysList.configuration"
            :key="key"
            :value="c"
          >{{c}}</option>
        </select>
      </div>
      <div class="mb-6 relative" v-if="is_rental">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Deposit Amount</label>
        <input type="number" v-model="property.deposit_amount" placeholder="Deposit Amount" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
      </div>
      <div v-if="(!propertyKeysList.is_executive && propertyKeysList.is_hide_owner_details)">
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Owner</label>
        <input type="text" v-model="property.owner" placeholder="Owner Name" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Owner Contact</label>
        <input type="number" v-model="property.owner_contact" placeholder="Owner Contact" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Other Contact</label>
        <input type="number" v-model="property.other_contacts" placeholder="Other Contact" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
      </div>
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Project/Location *</label>
        <vue-google-autocomplete
          required
          :country="['in']"
          types=""
          id="autoPl"
          v-model="property.project_name"
          classname="form-control border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none"
          placeholder="Start typing"
          v-on:placechanged="getAddressData"
          >
        </vue-google-autocomplete>
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Landmark</label>
        <input type="text" v-model="property.landmark" id="landmark" name="landmark" placeholder="Enter" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
      </div>
      <div class="mb-6 relative">
        <label for=""
          class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Property Address</label>
        <textarea v-model="property.property_address" placeholder="Property Address" rows="2"
          class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none"></textarea>
      </div>
        <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Possession Status</label>
        <select v-model="property.posession_status" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
          <option selected disabled>Select</option>
          <option
            v-for="(c, key) in propertyKeysList.posesstion_status"
            :key="key"
            :value="c"
          >{{c}}</option>
        </select>
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Possession Date</label>
          <datetime type="date" v-model="property.possession_date" placeholder="Possession Date" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
          </datetime>
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Status</label>
        <select v-model="property.status" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
          <option selected disabled>Select</option>
          <option
            v-for="(s, key) in propertyKeysList.status"
            :key="key"
            :value="s"
          >{{s}}</option>
        </select>
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Source</label>
        <select v-model="property.property_source_id" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
          <option selected disabled>Select</option>
          <option
            v-for="(p, key) in propertyKeysList.property_source"
            :key="key"
            :value="p.id"
          >{{p.name}}</option>
        </select>
      </div>
      <div class="mb-6 relative" v-if="propertyKeysList.reference_source_ids.includes(property.property_source_id)">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Referal Name</label>
        <input type="text" v-model="property.referal_name" placeholder="Referal Name" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
      </div>
      <div class="mb-6 relative" v-if="propertyKeysList.reference_source_ids.includes(property.property_source_id)">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Referal Contact</label>
        <input type="number" v-model="property.referal_mobile" placeholder="Referal Contact" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Area Unit *</label>
        <select v-model="property.area_unit" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
          <option selected disabled>Select</option>
          <option
            v-for="(a, key) in propertyKeysList.area_units"
            :key="key"
            :value="a"
          >{{a}}</option>
        </select>
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Priority</label>
        <select v-model="property.priority" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
          <option selected disabled>Select</option>
          <option
            v-for="(p, key) in propertyKeysList.priorities"
            :key="key"
            :value="p"
          >{{p}}</option>
        </select>
      </div>
        <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Builtup Area</label>
        <input type="number" v-model="property.builtup_area" placeholder="Enter in sq.ft" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Carpet Area * (Note: Less than Builtup Area)</label>
        <input type="number" v-model="property.carpet_area"  required placeholder="Enter in sq.ft" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        <span class="absolute right-0 top-0 m-4 text-main text-base text-opacity-50">sq.ft</span>
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Property Age</label>
        <input type="number" v-model="property.property_age" placeholder="Property Age" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Property Approved by</label>
        <input type="text" v-model="property.property_approve_by" placeholder="Property Approved by" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Expected Price *</label>
        <input type="number" v-model="property.expected_price" required placeholder="Enter in lacs" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Car parking charges </label>
        <input type="number" v-model="property.car_parking_charge" placeholder="Enter car parking charge" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Per square ft rate </label>
        <input type="number" v-model="property.base_cost_rate" placeholder="Enter Per square ft rate" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Maintainance Cost</label>
        <input type="number" v-model="property.maintenance_cost" placeholder="Enter" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Rera No</label>
        <input type="text" v-model="property.rera_no" placeholder="Enter" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
      </div>
      <div class="mt-10">
        <button type="submit" :disabled="!showProceed" class="bg-primary text-base font-semibold text-white rounded-full p-5 w-full disabled:opacity-50" >Next</button>
      </div>
    </form>
</template>

<script>
import { Datetime } from 'vue-datetime';
import VueGoogleAutocomplete from 'vue-google-autocomplete'
export default {
  components: {
    VueGoogleAutocomplete,
    datetime: Datetime
  },
  props: {
    property: {
      type: Object,
      required: true
    },
    submitFn: {
      type: Function,
    },
    editSubmitFn: {
      type: Function,
    }
  },
  methods: {
    getProjectDetails(id) {
      if (id) {
        this.$axios.get(`mobile_crm/properties/get_project_details?project_id=${id}`)
        .then((res) => {
          this.property.posession_status = res.data.possession_status
          this.property.possession_date = res.data.possession_date
          this.property.property_age = res.data.property_age
          this.property.rera_no = res.data.rera_no
          this.property.property_approve_by = res.data.project_approve_by
          this.property.city_id = res.data.city_id
          this.property.locality_id = res.data.locality_id
          if (res.data.location) {
            this.property.project_name = res.data.location
            this.property.lat = res.data.lat
            this.property.long = res.data.long
          }
          if (res.data.amenity_ids) {
            this.property.amenity_ids = res.data.amenity_ids
          }
        })
        .catch((err) => console.log(err));
      }
    },
    getAddressData(data, anotherData){
      this.property.project_name = anotherData.name
      document.getElementById("landmark").value = anotherData.vicinity
      this.property.landmark = anotherData.vicinity
      this.property.lat = data.latitude
      this.property.long = data.longitude
    },
  },
  computed: {
    is_rental() {
      return this.property.property_for === 'Rental'
    },
    propertyType() {
      if (this.property.building_type === 'Residential') {
        return this.propertyKeysList.residential_property_type
      } else if (this.property.building_type === 'Commercial') {
        return this.propertyKeysList.commercial_property_type
      } else {
        return []
      }
    },
    showProceed() {
      if (this.property.property_for && this.property.building_type && this.property.property_from && this.property.property_type
       && this.property.area_unit && this.property.carpet_area && this.property.expected_price
       && this.property.project_name) {
        return true
      }
    },
    propertyKeysList(){
      return this.$store.getters.propertyKeys;
    },
    leadKeysList(){
      return this.$store.getters.leadKeys;
    },
    projectList(){
      return this.$store.getters.projectMaster;
    },
    projectList(){
      return this.$store.getters.projectMaster;
    }
  }
}
</script>

<style>

</style>