<template>
	<div class="animate_animated animate__slideInRight">
		<div class="fixed w-full top-0 left-0 z-20">
		<div class="flex bg-white px-4 py-6 items-center shadow-ct">
			<div class="w-full">
				<div class="flex items-center w-full">
					<a  @click="$router.go(-1)"><img src="@/assets/back-arrow.svg" alt=""></a>
					<span class="text-base text-content font-medium ml-4">Search Property</span>
				</div>
			</div>
		</div>
    </div>
    <div class="pt-20 mt-2 bg-white">
    <div class="bg-white min-h-screen p-4">
      <form>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Owner</label>
          <input type="text" v-model="search.owner" placeholder="Owner Name" class="border border-inputBorder round-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mb-10">
          <label for="" class="text-main text-opacity-50 text-xs block mb-5 ml-4">Property For</label>
          <div class="flex">
            <span class="radio-selection">
              <input type="radio" v-model="search.property_for"  value="Sale" name="propertyFor" id="sale" class="hidden">
              <label for="sale" class="bg-divider py-3 px-6 text-content rounded-full font-medium pills mr-3"> Sale </label>
            </span>
             <span class="radio-selection">
              <input type="radio" v-model="search.property_for" value="Rental" name="propertyFor" id="rent" class="hidden">
              <label for="rent" class="bg-divider py-3 px-6 text-content rounded-full font-medium pills"> Rental </label>
            </span>
          </div>
        </div>
         <div class="mb-10">
          <label for="" class="text-main text-opacity-50 text-xs block mb-5 ml-4">Property From</label>
          <div class="flex">
            <span class="radio-selection">
              <input type="radio" v-model="search.property_from" name="propertyFrom" value="Owner" id="owner" class="hidden">
              <label for="owner" class="bg-divider py-3 px-6 text-content rounded-full font-medium pills mr-3"> Owner </label>
            </span>
             <span class="radio-selection">
              <input type="radio" v-model="search.property_from" name="propertyFrom" value="Developer" id="developer" class="hidden">
              <label for="developer" class="bg-divider py-3 px-6 text-content rounded-full font-medium pills"> Developer </label>
            </span>
          </div>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Project/Location</label>
          <input type="text" v-model="search.project_name " placeholder="Project/Location" class="border border-inputBorder round-md p-4 w-full z-auto focus:outline-none">
        </div>
          <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Building/Plot</label>
          <input type="text" v-model="search.building" placeholder="Building/Plot Name" class="border border-inputBorder round-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white z-10">Select Projects</label>
          <multiselect v-model="search.project_ids" :searchable="true"  placeholder="Select Projects" label="name" track-by="id" :options="projectList" :multiple="true" :taggable="true" class="multiselect-outer-wrapper"></multiselect>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Configuration</label>
          <select v-model="search.configuration_id" class="border border-inputBorder round-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select Configuration</option>
           <option
              v-for="(c, key) in propertyKeysList.configuration"
              :key="key"
              :value="c"
            >{{c}}</option>
          </select>
        </div>
        <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Building Type *</label>
        <select v-model="search.building_type" required class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
          <option selected disabled>Select the type</option>
          <option
            v-for="(bt, key) in propertyKeysList.building_types"
            :key="key"
            :value="bt"
          >{{bt}}</option>
        </select>
      </div>
         <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Type</label>
          <select v-model="search.property_type_id" class="border border-inputBorder round-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select the type</option>
            <option
              v-for="(pt, key) in propertyType"
              :key="key"
              :value="pt"
            >{{pt}}</option>
          </select>
        </div>
        <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Status</label>
        <select v-model="search.status" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
          <option selected disabled>Select</option>
          <option
            v-for="(s, key) in propertyKeysList.property_statuses"
            :key="key"
            :value="s.id"
          >{{s.name}}</option>
        </select>
      </div>
        <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Possession Status</label>
        <select v-model="search.posession_status" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
          <option selected disabled>Select</option>
          <option
            v-for="(c, key) in propertyKeysList.posesstion_status"
            :key="key"
            :value="c"
          >{{c}}</option>
        </select>
      </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">City</label>
          <select v-model="search.city_ids" class="border border-inputBorder round-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select City</option>
            <option
              v-for="(r, key) in leadKeysList.cities"
              :key="key"
              :value="r.id"
            >{{r.name}}</option>
          </select>
        </div>
         <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white z-10">Locality</label>
          <multiselect v-model="search.locality_ids" :searchable="true"  :options-limit="leadKeysList.locality.length" placeholder="Select Locality" label="name" track-by="id" :options="leadKeysList.locality" :multiple="true" :taggable="true" class="multiselect-outer-wrapper"></multiselect>
        </div>
        <div class="mt-10">
          <a @click="clearFilters" class="clearFilter"> Clear Filters</a>
          <button type="button" @click="performSearch" class="bg-primary text-base font-semibold text-white rounded-full p-5 w-full disabled:opacity-50" >Filter</button>
        </div>
      </form>
    </div>
  </div>
	</div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
  components: {
    Multiselect
  },
  data () {
    return {
      search: {
        owner: null,
        property_for: null,
        property_from: null,
        project_name: null,
        building: null,
        configuration_id: null,
        property_type_id: null,
        city_ids: null,
        posession_status: null,
        status: null,
        project_ids: [],
        locality_ids: [],
      },
    }
  },
  methods: {
    performSearch(){
      this.$store.dispatch('setAdvSParams', this.search);
      this.$router.push({name: 'Properties'})
    },
    seedDefaults(){
      this.search = this.advSParams
    },
    clearFilters(){
      this.search = {
        owner: null,
        property_for: null,
        property_from: null,
        project_name: null,
        building: null,
        configuration_id: null,
        property_type_id: null,
        posession_status: null,
        status: null,
        city_ids: null,
        project_ids: [],
        locality_ids: [],
      }
      this.$store.dispatch('setAdvSParams', {});
    }
  },
  created(){
    if(Object.keys(this.advSParams).length > 0){
      this.seedDefaults();
    }
  },
  computed: {
    propertyType() {
      if (this.search.building_type === 'Residential') {
        return this.propertyKeysList.residential_property_type
      } else if (this.search.building_type === 'Commercial') {
        return this.propertyKeysList.commercial_property_type
      } else {
        return []
      }
    },
    propertyKeysList(){
      return this.$store.getters.propertyKeys;
    },
    projectList(){
      return this.$store.getters.projectMaster;
    },
    advSParams(){
      return this.$store.getters.advSParams;
    },
    leadKeysList(){
      return this.$store.getters.leadKeys;
    },
  }
}
</script>
<style scoped>
.clearFilter{
  display: table;
  margin:20px auto;
  opacity: .5;
  font-size: 14px;
}
</style>