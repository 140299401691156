<template>
  <div>
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <a
            class="nav-link"
            :class="{active : (activeStatus == 'all' || activeStatus.length > 1)}"
            data-toggle="tab"
            role="tab"
            aria-controls="new"
            aria-selected="true"
            @click="activeStatus != 'all' && selectStatus('all')"
          >All</a>
        </li>
        <li class="nav-item" role="presentation" v-for="(status, key) in statusesMaster">
          <a
            class="nav-link"
            :class="{active : activeStatus === `${status.id}`}"
            data-toggle="tab"
            role="tab"
            aria-controls="new"
            aria-selected="true"
            @click="activeStatus != `${status.id}` && selectStatus(status.id)"
            :key="`status-${status.id}-${key}`"
          >{{status.name}}</a>
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link"
            :class="{active : activeStatus === 'others'}"
            data-toggle="tab"
            role="tab"
            aria-controls="new"
            aria-selected="true"
            @click="activeStatus != 'others' && selectStatus('others')"
          >Others</a>
        </li>
      </ul>
    </div>
</template>
<script>
export default {
  computed: {
    statusesMaster() {
      return this.$store.getters.statusesMaster;
    }
  },
  mounted(){
    let activeTab = $('a.nav-link.active').parent();
    let winWidth = $(window).width();
    let atRight = activeTab.position().left + activeTab.width()/2;
    if(atRight > winWidth){
      let diff = atRight - winWidth/2
      $("#myTab").animate( { scrollLeft: diff }, 1200)
    }
  },
  props: {
    activeStatus: {
      type: String,
      required: true
    },
    selectStatus: {
      type: Function,
      required: true
    }
  }
};
</script>
<style scoped>
.nav-tabs {
  @apply w-full whitespace-nowrap overflow-y-hidden pb-1;
}
.nav-tabs .nav-item{
  @apply inline-block py-5;
}
.nav-tabs .nav-item .nav-link{
  @apply px-6 py-5 text-main text-opacity-50 font-semibold border-b border-inputBorder;
}
.nav-tabs .nav-link.active{
  @apply text-black border-b-2 border-primary; 
}
</style>