<template>
  <div class="animate_animated animate__slideInRight">
    <div class="fixed w-full top-0 left-0 z-20">
      <div class="flex bg-white px-4 py-6 items-center shadow-ct">
        <div class="w-full">
          <div class="flex items-center w-full">
            <a @click="$router.go(-1)"><img src="@/assets/back-arrow.svg" alt=""></a>
            <span class="text-base text-content font-medium ml-4">Edit Agreement</span>
          </div>
        </div>
      </div>
    </div>
    <Agreement :Agreement="EditAgreement" :editSubmitFn="goToStep1" :suggestProperty="suggestProperty"
      :propertiesList="propertiesList" :selectProperty="selectProperty" />
  </div>
</template>
<script>
import Agreement from '@/components/Agreement/New.vue';
import { prepareAgreementParams } from '@/assets/scripts/utility.js';
export default {
  components: {
    Agreement
  },
  data() {
    return {
      propertiesList: [],
      EditAgreement: {},
    }
  },
  created() {
    this.$axios.get(`mobile_crm/agreements/${this.$route.params.uuid}`)
      .then((res) => {
        this.EditAgreement = res.data.agreement
        if (this.EditAgreement.property_detail) {
          this.EditAgreement.property_id = this.EditAgreement.property_detail
        }
        let step0_data = this.$store.getters.newAgreementData
        if (step0_data) {
          if (Object.keys(step0_data).length > 0) {
            this.EditAgreement = step0_data
          }
        }
      })
      .catch((err) => console.log(err));
  },
  methods: {
    suggestProperty(event) {
      if (event.length === 3) {
        event = encodeURIComponent(event)
        this.$axios.get(`/mobile_crm/agreements/search_properties?search_string=${event.trim()}`)
          .then(res => {
            this.propertyNoInput = "No Results!"
            if (res.data.properties.length > 0) {
              this.propertiesList = res.data.properties
            }
          })
          .catch(err => {
            this.propertyNoInput = "Something Went Wrong!"
          })
      }
    },
    selectProperty(prop) {
      if (prop) {
        this.EditAgreement.owner = prop.owner
        this.EditAgreement.configuration = prop.configuration
        this.EditAgreement.transaction_type = prop.property_for;
        this.EditAgreement.agreement_type_id = prop.property_type;
      } else {
        this.EditAgreement.owner = null
        this.EditAgreement.configuration = null
        this.EditAgreement.transaction_type = null
        this.EditAgreement.agreement_type_id = null
      }
    },
    goToStep1() {
      let apiParams = {}
      apiParams = prepareAgreementParams(this.EditAgreement);
      let newAgreementData = apiParams
      this.$store.dispatch('setnewAgreementData', newAgreementData);
      this.$router.push({ name: 'EditNextAgreement' });
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
<style scoped>
</style>
