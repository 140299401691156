<template>
	<div class="animate_animated animate__slideInRight">
		<div class="fixed w-full top-0 left-0 z-20">
		<div class="flex bg-white px-4 py-6 items-center shadow-ct">
			<div class="w-full">
				<div class="flex items-center w-full">
					<a  @click="$router.go(-1)"><img src="@/assets/back-arrow.svg" alt=""></a>
					<span class="text-base text-content font-medium ml-4">Add Collection</span>
				</div>
			</div>
		</div>
    </div>
    <div class="pt-20 mt-2 bg-white min-h-screen">
    <div class="bg-white  p-4">
      <form @submit.prevent="addAgreementCollection()">
         <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Payment Type *</label>
          <select v-model="addCollection.payment_type" class="border border-inputBorder round-md p-4 w-full z-auto custom-select focus:outline-none">
          <option selected disabled>Select Payment Type</option>
           <option
              v-for="(p, key) in agreementKeysList.payment_type"
              :key="key"
              :value="p"
            >{{p}}</option>
          </select>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Payment Mode *</label>
          <select v-model="addCollection.payment_mode" class="border border-inputBorder round-md p-4 w-full z-auto custom-select focus:outline-none">
          <option selected disabled>Select Payment Mode</option>
           <option
              v-for="(p, key) in agreementKeysList.payment_mode"
              :key="key"
              :value="p"
            >{{p}}</option>
          </select>
        </div>
				<div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Amount *</label>
					<input type="number" v-model="addCollection.amount" placeholder="Enter" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Tax Amount *</label>
					<input type="number" v-model="addCollection.tax_amount" placeholder="Enter" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Status</label>
         <select v-model="addCollection.status" class="border border-inputBorder round-md p-4 w-full z-auto custom-select focus:outline-none">
          <option selected disabled>Select Status</option>
           <option
              v-for="(p, key) in agreementKeysList.collection_status"
              :key="key"
              :value="p"
            >{{p}}</option>
          </select>
        </div>
        <div class="fixed w-full bottom-0 left-0 p-4">
					<button type="submit" class="bg-primary text-base font-semibold text-white rounded-full p-5 w-full disabled:opacity-50" >Save</button>
				</div>
      </form>
    </div>
  </div>
	</div>
</template>
<script>
import {updateCollectionParams} from '@/assets/scripts/utility.js';
export default {
  data () {
    return {
      addCollection: {
        payment_type: null,
        payment_mode: null,
        amount: null,
        tax_amount: null,
        status: null
      },
    }
  },
  methods: {
    addAgreementCollection() {
      let apiParams = {}
      apiParams = updateCollectionParams(this.addCollection);
      this.$axios
        .put(`mobile_crm/agreements/${this.$route.params.uuid}`, { agreement: apiParams })
        .then(res => {
          swal({
            title: "Collection Updated!",
            icon: "success"
          }).then(this.$router.replace({ name: "AgreementDetails", params: {  uuid: this.$route.params.uuid } }));
        })
        .catch(err => {
          if (err.response.status === 500) {
            swal({
              title: "Server Error!",
              icon: "error"
            });
          } else {
            swal({
              title: "Error",
              text: err.response.data.message,
              icon: "error"
            });
          }
        });
    }
  },
  computed: {
    agreementKeysList(){
      return this.$store.getters.agreementKeys;
    },
  }
}
</script>
<style scoped>

</style>
