import Vue from 'vue'
import App from './App.vue'
import DrawerLayout from "vue-drawer-layout";
import './registerServiceWorker'
import {Tabs, Tab} from 'vue-tabs-component';
import VueGoodTablePlugin from 'vue-good-table';
import moment from 'moment'
import axios from 'axios'
import VueSwal from 'vue-swal';
import Paginate from 'vuejs-paginate'
import './assets/css/style.css'
import router from './router'
import store from './store'
import Element from 'element-ui'
import Lightbox from 'vue-easy-lightbox'
import ReadMore from 'vue-read-more';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'


Vue.use(DrawerLayout);
Vue.use(VueSwal);
Vue.component('paginate', Paginate)
Vue.component('tabs', Tabs);
Vue.component('tab', Tab);
Vue.use(VueGoodTablePlugin);
Vue.use(Element)
Vue.use(Lightbox)
Vue.use(ReadMore)
Vue.use(Datetime)


let baseUrl = "http://localhost:3000/api"
if(process.env.NODE_ENV === 'production'){
  baseUrl = "https://engage.enrichr.tech/api"
}
axios.defaults.baseURL = baseUrl;
//axios interceptors
axios.interceptors.request.use(function(config) {
  store.dispatch('setLoading', true);
  if(config.url.indexOf('login') < 0){
    let token = localStorage.getItem('property-user-token')
    if(token){
      config.headers.Authorization = `Token token=${token}`
    }
  }
  config.headers['Content-Type'] = 'application/json'
  config.headers['Accept'] = 'application/json'
  return config;
}, function(err) {
  store.dispatch('setLoading', false);
  return Promise.reject(err);
});

axios.interceptors.response.use(function(response) {
  store.dispatch('setLoading', false);
  return response;
}, function(error){
  if(error.response.status === 401){
    localStorage.removeItem('property-user-token');
    swal({
      title: "Session Expired",
      text: "You have been redirected to the login page",
      icon: "error"
    })
    .then(router.replace('/login'));
  }else{
    store.dispatch('setLoading', false);
    return Promise.reject(error);
  }
});

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('property-user-token')
  if(token && to.name === 'Login'){
    next({name: 'Home'});
  }else if(to.name !== 'Login' && !token){
    next({name: 'Login'});
  }else{
    if(to.name === 'Login'){
      store.dispatch('setLayout', 'login');
    }else{
      store.dispatch('setLayout', 'default');
    }
    next();
  }
})
Vue.filter('dateFormat', function (value) {
  if (!value) return '-'
  return moment(value).format('DD-MMM-YYYY')
})
Vue.filter('asINR', function(value){
  if(!value) return '0'
  return value.toLocaleString('en-IN')
})
Vue.prototype.$moment = moment;
Vue.prototype.$axios = axios;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')