<template>
    <form  @submit.prevent="submitFn()">
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">TItle of Project *</label>
        <input type="text" v-model="project.name" required placeholder="Title" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Developer Name</label>
        <input type="text" v-model="project.developer_name" placeholder="Developer Name" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Developer Contact</label>
        <input type="number" v-model="project.developer_contact" placeholder="Developer Contact" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Possession Status</label>
        <select v-model="project.possession_status" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
          <option selected disabled>Select</option>
          <option
            v-for="(c, key) in propertyKeysList.posesstion_status"
            :key="key"
            :value="c"
          >{{c}}</option>
        </select>
      </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Possession Date</label>
          <!-- <input type="date" v-model="project.possession_date" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none"> -->
          <datetime type="date" v-model="project.possession_date" placeholder="Possession Date" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
          </datetime>
        </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Property Age</label>
        <input type="number" v-model="project.property_age" placeholder="Property Age" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Rera No</label>
        <input type="text" v-model="project.rera_no" placeholder="Rera No" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Project Approved by</label>
        <input type="text" v-model="project.project_approve_by" placeholder="Project Approved by" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
      </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">City</label>
          <select v-model="project.city_id" @change="getLocality()" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select City</option>
            <option
              v-for="(r, key) in propertyKeysList.cities"
              :key="key"
              :value="r.id"
            >{{r.name}}</option>
          </select>
        </div>
         <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Locality</label>
          <select v-model="project.locality_id" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select Locality</option>
            <option
              v-for="(r, key) in localityList"
              :key="key"
              :value="r.id"
            >{{r.name}}</option>
          </select>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Project/Location *</label>
          <vue-google-autocomplete
            required
            :country="['in']"
            types=""
            id="autoPl"
            v-model="project.location"
            classname="form-control border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none"
            placeholder="Start typing"
            v-on:placechanged="getAddressData"
            >
          </vue-google-autocomplete>
        </div>
      <div class="mb-6 relative">
        <label for=""
          class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white z-10">Amenities</label>
            <multiselect v-model="project.amenity_ids" :searchable="false"  placeholder="Select Amenities" label="name" track-by="id" :options="propertyKeysList.amenities" :multiple="true" :taggable="true" class="multiselect-outer-wrapper"></multiselect>
      </div>
      <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block mb-2">Add Photo</label>
        <label for="upload"
          class="text-center rounded-md border border-inputBorder bg-grey  h-24  flex flex-col items-center justify-center">
          <input type="file" multiple ref="file" id="upload" class="hidden" @change="uploadImg">
          <img src="@/assets/upload-icon.svg" alt="">
          <span class="text-main text-opacity-50 text-sm font-medium block mt-1" v-if="project.upload_img">{{ project.upload_img.length>0 ? (project.upload_img.length === 1 ? project.upload_img[0].name : `${project.upload_img.length} files selected`) : "Upload Images" }}</span>
        </label>
        <p class="text-main text-opacity-50 text-sm font-medium block mt-1 text-center">Image files type accept .jpg .png</p>
      </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block mb-2">brochure</label>
          <label for="brochure"
            class="text-center rounded-md border border-inputBorder bg-grey  h-24  flex flex-col items-center justify-center">
            <input type="file" ref="brochure" id="brochure" class="hidden" @change="addBrochure">
            <img src="@/assets/upload-icon.svg" alt="">
            <span class="text-main text-opacity-50 text-sm font-medium block mt-1">{{ project.brochure ? project.brochure.name : "Upload Documents" }}</span>
          </label>
        </div>
        <div class="mb-6 relative">
          <label for=""
            class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Description</label>
          <textarea v-model="project.description" placeholder="Comments" rows="3"
            class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none"></textarea>
        </div>

      <div class="mt-10">
        <button type="submit" :disabled="!showProceed" class="bg-primary text-base font-semibold text-white rounded-full p-5 w-full disabled:opacity-50" >Submit</button>
      </div>
    </form>
</template>

<script>
import { Datetime } from 'vue-datetime';
import Multiselect from 'vue-multiselect'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
export default {
  components: {
    Multiselect,
    datetime: Datetime,
    VueGoogleAutocomplete,
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    getLocality: {
      type: Function,
    },
    submitFn: {
      type: Function,
    },
    localityList: {
      type: Array
    },
    uploadImg: {
      type: Function,
      required: true
    },
    addBrochure: {
      type: Function,
      required: true
    }
  },
  methods: {
    getAddressData(data, anotherData){
      this.project.location = anotherData.name
      this.project.lat = data.latitude
      this.project.long = data.longitude
    },
  },
  computed: {
    showProceed() {
      if (this.project.name) {
        return true
      }
    },
    propertyKeysList(){
      return this.$store.getters.propertyKeys;
    },
  }
}
</script>

<style>

</style>