<template>
  <div class="animate_animated animate__slideInRight">
    <div class="fixed w-full top-0 left-0 z-20">
      <div class="flex bg-white px-4 py-6 items-center shadow-ct">
        <div class="w-full">
          <div class="flex items-center w-full">
            <a  @click="$router.go(-1)"><img src="@/assets/back-arrow.svg" alt=""></a>
            <span class="text-base text-content font-medium ml-4">Edit Project</span>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-20 bg-white mt-2">
      <div class="bg-white  p-4">
        <Project :project="EditProject" :uploadImg="uploadImg" :addBrochure="addBrochure" :submitFn="updateProject" :getLocality="getLocality" :localityList="localityList" />
      </div>
    </div>
  </div>
</template>
<script>
import Project from '@/components/Project/New.vue';
export default {
  components: {
    Project
  },
  data () {
    return {
      EditProject: {},
      localityList: []
    }
  },
  created() {
    this.$axios.get(`mobile_crm/projects/${this.$route.params.uuid}`)
    .then((res) => {
      let project = res.data.project
      this.EditProject = {...project, upload_img: [], brochure: null}
      if (this.EditProject.city_id) {
        this.$axios.get(`mobile_crm/properties/fetch_localities?city_id=${this.EditProject.city_id}`)
        .then((res) => {
          this.localityList = res.data
          })
        .catch((err) => console.log(err));
       }
    })
  .catch((err) => console.log(err));
  },
  methods: {
    uploadImg() {
      this.EditProject.upload_img = event.target.files;
    },
    addBrochure() {
      this.EditProject.brochure = event.target.files[0];
    },
    getLocality() {
      this.$axios.get(`mobile_crm/properties/fetch_localities?city_id=${this.EditProject.city_id}`)
      .then((res) => {
        this.localityList = res.data
        })
      .catch((err) => console.log(err));
    },
    updateProject() {
      let formData = new FormData();
      if (this.EditProject.upload_img.length > 0) {
        for (let file of this.EditProject.upload_img) {
          formData.append('images_attributes[][image]', file)
          formData.append('images_attributes[][_destroy]', false)
        }
      }
      if (this.EditProject.uuid) {
        formData.append('uuid', this.EditProject.uuid);
        formData.append('id', this.EditProject.id);
      }
      if (this.EditProject.brochure) {
        formData.append('brochure', this.EditProject.brochure)
      }
      formData.append('name', this.EditProject.name || '');
      formData.append('developer_name', this.EditProject.developer_name || '');
      formData.append('developer_contact', this.EditProject.developer_contact || '');
      formData.append('possession_status', this.EditProject.possession_status || '');
      formData.append('possession_date', this.EditProject.possession_date || '');
      formData.append('property_age', this.EditProject.property_age || '');
      formData.append('rera_no', this.EditProject.rera_no || '');
      formData.append('project_approve_by', this.EditProject.project_approve_by || '');
      formData.append('city_id', this.EditProject.city_id || '');
      formData.append('location', this.EditProject.location || '');
      formData.append('lat', this.EditProject.lat || '')
      formData.append('long', this.EditProject.long || '')
      formData.append('locality_id', this.EditProject.locality_id || '');
      formData.append('description', this.EditProject.description || '');
      if (this.EditProject.amenity_ids.length > 0) {
        this.EditProject.amenity_ids.forEach(am => {
          formData.append('amenity_ids[]', am.id)
        })
      } else {
        formData.append('amenity_ids[]', '')
      }
      this.$axios
        .put(`/mobile_crm/projects/${this.$route.params.uuid}`, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          swal({
            title: "Project Updated!",
            icon: "success"
          }).then(this.$router.replace({ name: "Projects" }));
        })
        .catch(err => {
          if (err.response.status === 500) {
            swal({
              title: "Server Error!",
              icon: "error"
            });
          } else {
            swal({
              title: "Error",
              text: err.response.data.message,
              icon: "error"
            });
          }
        });
      },
  }
}
</script>
<style scoped>

</style>