<template>
  <form @submit.prevent>
    <div class="mb-6 relative">
      <label for=""
        class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Furnishings</label>
      <select v-model="property.furnishing_type_id"
        class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
        <option selected disabled>Select</option>
        <option
          v-for="(r, key) in propertyKeysList.furnishing_type_id"
          :key="key"
          :value="r"
        >{{r}}</option>
      </select>
    </div>
    <div class="mb-6 relative">
      <label for=""
        class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Facing</label>
      <select v-model="property.facing"
        class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
        <option selected disabled>Select</option>
        <option
          v-for="(f, key) in leadKeysList.facing_directions"
          :key="key"
          :value="f"
        >{{f}}</option>
      </select>
    </div>
    <div class="mb-6 relative">
      <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Water Source</label>
      <input type="text" v-model="property.watersource" placeholder="Water Source" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
    </div>
    <div class="mb-6 relative">
      <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">No of Floors</label>
      <input type="number" v-model="property.no_of_floors" placeholder="No of Floors" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
    </div>
    <div class="mb-6 relative">
      <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">No of Balcony</label>
      <input type="number" v-model="property.no_of_balcony" placeholder="No of Balcony" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
    </div>
    <div class="mb-6 relative">
      <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">No of Terrace</label>
      <input type="number" v-model="property.no_of_terrace" placeholder="No of Terrace" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
    </div>
    <div class="mb-6 relative">
      <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">No of Utility Rooms</label>
      <input type="number" v-model="property.no_of_utility_room" placeholder="No of Utility Rooms" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
    </div>
    <div class="mb-6 relative">
      <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Bathrooms</label>
      <input type="number" v-model="property.bathroom" placeholder="Bathrooms" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
    </div>
    <div class="mb-6 relative">
      <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Frontage</label>
      <input type="text" v-model="property.frontage" placeholder="Frontage" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
    </div>
    <div class="mb-6 relative">
      <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Height</label>
      <input type="text" v-model="property.height" placeholder="Height" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
    </div>
    <div class="mb-6 relative">
      <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Electricity</label>
      <input type="text" v-model="property.electricity" placeholder="Electricity" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
    </div>
    <div v-if="is_building_type">
    <div class="mb-6 relative">
      <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">No of Kitchen</label>
      <input type="number" v-model="property.no_of_kitchen" placeholder="No of Kitchen" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
    </div>
    <div class="mb-6 relative">
      <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">No of Workstation</label>
      <input type="number" v-model="property.no_of_workstations" placeholder="No of Workstation" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
    </div>
    <div class="mb-6 relative">
      <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">No of Cabins</label>
      <input type="number" v-model="property.no_of_cabins" placeholder="No of Cabins" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
    </div>
    <div class="mb-6 relative">
      <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Server Rooms</label>
      <input type="number" v-model="property.no_of_server_rooms" placeholder="Server Rooms" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
    </div>
    <div class="mb-6 relative">
      <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Meeting Rooms</label>
      <input type="number" v-model="property.no_of_meeting_room" placeholder="Meeting Rooms" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
    </div>
    <div class="mb-6 relative">
      <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">No of Cubicles</label>
      <input type="number" v-model="property.no_of_cubicles" placeholder="No of Cubicles" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
    </div>
    <div class="mb-6 relative">
      <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">No of Seaters</label>
      <input type="number" v-model="property.no_of_seaters" placeholder="No of Seaters" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
    </div>
    <div class="mb-6 relative">
      <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">No of Conference Room</label>
      <input type="number" v-model="property.no_of_conference_room" placeholder="No of Conference Room" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
    </div>
    </div>
    <div class="mb-6 relative">
        <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">No of Parkings</label>
        <input type="number" v-model="property.parking" placeholder="No of Parkings" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
      </div>
      <div class="mb-6 relative">
        <label for=""
          class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white z-10">Amenities</label>
            <multiselect v-model="property.amenity_ids" :searchable="false"  placeholder="Select Amenities" label="name" track-by="id" :options="propertyKeysList.amenities" :multiple="true" :taggable="true" class="multiselect-outer-wrapper"></multiselect>
      </div>
  </form>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  components: {
    Multiselect
  },
  props: {
    property: {
      type: Object,
      required: true
    }
  },
  computed: {
    is_building_type() {
      return this.$store.getters.newPropertyData.building_type === 'Commercial'
    },
    leadKeysList(){
      return this.$store.getters.leadKeys;
    },
    propertyKeysList(){
      return this.$store.getters.propertyKeys;
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>

</style>