<template>
  <div class="animate_animated animate__slideInRight">
    <div class="fixed w-full top-0 left-0 z-20">
      <div class="flex bg-white px-4 py-6 items-center shadow-ct">
        <div class="w-full">
          <div class="flex items-center w-full">
            <a @click="$router.go(-1)"><img src="@/assets/back-arrow.svg" alt=""></a>
            <span class="text-base text-content font-medium ml-4">New Agreement</span>
          </div>
        </div>
      </div>
    </div>
    <Agreement :Agreement="AddNewAgreement" :submitFn="goToStep1" :suggestProperty="suggestProperty"
      :propertiesList="propertiesList" :selectProperty="selectProperty" />
  </div>
</template>
<script>
import Agreement from '@/components/Agreement/New.vue';
import { prepareAgreementParams } from '@/assets/scripts/utility.js';
import Multiselect from 'vue-multiselect';
export default {
  components: {
    Multiselect, Agreement
  },
  data() {
    return {
      propertiesList: [],
      AddNewAgreement: {
        transaction_type: null,
        agreement_type_id: null,
        configuration: null,
        property_id: null,
        owner: null,
        buyer_name: null,
        broker_id: null,
        start_date: null,
        end_date: null
      },
    }
  },
  created() {
    let step0_data = this.$store.getters.newAgreementData
    if (step0_data) {
      this.AddNewAgreement = step0_data
    }
  },
  methods: {
    suggestProperty(event) {
      if (event.length === 3) {
        event = encodeURIComponent(event)
        this.$axios.get(`/mobile_crm/agreements/search_properties?search_string=${event.trim()}`)
          .then(res => {
            this.propertyNoInput = "No Results!"
            if (res.data.properties.length > 0) {
              this.propertiesList = res.data.properties
            }
          })
          .catch(err => {
            this.propertyNoInput = "Something Went Wrong!"
          })
      }
    },
    selectProperty(prop) {
      if (prop) {
        this.AddNewAgreement.owner = prop.owner
        this.AddNewAgreement.configuration = prop.configuration
        this.AddNewAgreement.transaction_type = prop.property_for;
        this.AddNewAgreement.agreement_type_id = prop.property_type;
      } else {
        this.AddNewAgreement.owner = null
        this.AddNewAgreement.configuration = null
        this.AddNewAgreement.transaction_type = null
        this.AddNewAgreement.agreement_type_id = null
      }
    },
    goToStep1() {
      let apiParams = {}
      apiParams = prepareAgreementParams(this.AddNewAgreement);
      let newAgreementData = apiParams
      this.$store.dispatch('setnewAgreementData', newAgreementData);
      this.$router.push({ name: 'NextAgreement' });
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
<style scoped>
</style>
