<template>
	<div class="animate_animated animate__slideInRight">
		<!-- Header -->
		<div class="fixed w-full z-10 left-0 top-0">
			<div class="absolute w-full top-0 left-0 z-10 bg-fix">
				<div class="flex px-4 py-6 items-center ">
					<div class="w-1/2">
						<a @click="$router.go(-1)"><img src="@/assets/back-arrow-white.svg" alt=""></a>
					</div>
					<div class="w-1/2 flex justify-end">
						<span class="flex bg-white items-center rounded px-2 py-1">
							<img src="@/assets/available-icon.svg" alt="" class="mr-1">
							<span class="text-content text-xxs font-medium ">{{propertyDetails.status}}</span>
						</span>
					</div>
				</div>
			</div>
				<img :src="photoUrl" alt="" class="w-full h-48 object-cover object-center">
		</div>
		<div class="mt-48 relative z-50">
			<div class="bg-white min-h-screen pb-12">
				<div class="flex items-center p-2">
					<div class="w-full">
						<div class="p-3">
							<div class="flex items-center mb-4">
								<span class="text-base text-content font-bold mr-2">Rs {{priceinLacs(propertyDetails.expected_price) | asINR}}</span>
								<span class="text-base text-content text-opacity-40 font-medium">{{propertyDetails.days_ago == 0 ? 'Today' : propertyDetails.days_ago + ' days ago'}} </span>
									<router-link :to="{ name: 'EditProperty',  params: {  uuid: propertyDetails.uuid }}" class="ml-2 cursor-pointer  flex">
										<img src="@/assets/edit-icon.svg" alt="" class="mr-1 inline-flex">
										<span class="text-primary text-sm font-medium">Edit</span>
								</router-link>
                <span v-if="created_user" class="text-xs text-black font-medium ml-2 mt-1 text-opacity-50">{{propertyDetails.user_name}}</span>
							</div>
							<div class="flex mb-5">
								<div class="w-3/4">
									<h2 class="text-content text-base font-semibold mb-1">{{propertyDetails.title}} </h2>
									<span class="block text-base font-normal">{{propertyDetails.address}}</span>
                  <span class="block text-base font-normal" style="font-size: 12px;">Property No: {{propertyDetails.property_no}}</span>
                  <span class="block text-base font-normal" style="font-size: 12px;">Car Parking Charge: {{priceinLacs(propertyDetails.car_parking_charge)}}</span>
                  <span class="block text-base font-normal" style="font-size: 12px;">Base Cost Rate: {{priceinLacs(propertyDetails.base_cost_rate)}}</span>
                  <span class="block text-base font-normal" v-if="propertyDetails.create_at" style="font-size: 12px;">Created At: {{propertyDetails.create_at}}</span>
                  <span class="block text-base font-normal"  v-if="propertyDetails.priority" style="font-size: 12px;">Priority: {{propertyDetails.priority}}</span>
                  <span class="block text-base font-normal" v-if="propertyDetails.posession_status" style="font-size: 12px;">Posession Status: {{propertyDetails.posession_status}}</span>
								</div>
								<div class="w-1/4 flex justify-end" @click="openGoogleMap(propertyDetails)">
									<div class="rounded-full w-14 h-14 flex items-center justify-center bg-grey map-static">
										<img src="@/assets/map-pin.svg" alt="">
									</div>
								</div>
							</div>
							<div class="flex mb-5">
								<div class="">
									<span class="text-sm text-content text-opacity-50 block mb-2">Photos</span>
								  <vue-easy-lightbox :visible="visible" :imgs="photoUrl" @hide="handleHide" ></vue-easy-lightbox>
										 <div class="flex justify-between">
												<div
													v-for="(img_url, index) in photoUrl"
													:key="index"
                          :class="index > 3 ? 'hidden' : ''"
													class="mr-2 img-gallery w-full"
													@click="() => showImg(img_url)"
												>
													<span class="text-sm absolute  items-center justify-center w-full h-full text-white z-10 font-medium hidden">{{index + 1}}+</span>
												<img :src="img_url" alt=""  class="w-full max-w-full rounded-sm h-20 object-cover h-fix">
												</div>
											</div>
								</div>
								</div>
							<div class="flex mb-5">
								<div class="mr-8">
									<span class="text-sm text-content text-opacity-50 block">Build Up Area</span>
									<span class="text-sm text-content font-semibold">{{propertyDetails.builtup_area}} Sq.ft</span>
								</div>
								<div class="">
									<span class="text-sm text-content text-opacity-50 block">Carpet Area</span>
									<span class="text-sm text-content font-semibold">{{propertyDetails.carpet_area}} Sq.ft</span>
								</div>
							</div>
							<div class="flex mb-5">
								<div class="mr-8">
									<span class="text-sm text-content text-opacity-50 block">Referal Name</span>
									<span class="text-sm text-content font-semibold">{{propertyDetails.referal_name}}</span>
								</div>
								<div class="">
									<span class="text-sm text-content text-opacity-50 block">Referal Mobile</span>
									<span class="text-sm text-content font-semibold">{{propertyDetails.referal_mobile}}</span>
								</div>
							</div>
							<div class="flex mb-8 flex-wrap">
								<span class="text-sm text-content font-medium amenities-label" v-if="propertyDetails.configuration">{{propertyDetails.configuration}}</span>
								<span class="text-sm text-content font-medium amenities-label" v-if="propertyDetails.bathroom">{{propertyDetails.bathroom}} Bathrooms</span>
								<span class="text-sm text-content font-medium amenities-label" v-if="propertyDetails.furnishing_type_id">{{propertyDetails.furnishing_type_id}}</span>
								<span class="text-sm text-content font-medium amenities-label" v-if="propertyDetails.parking">{{propertyDetails.parking}} Parking</span>
								<span class="text-sm text-content font-medium amenities-label" v-if="propertyDetails.floor">{{propertyDetails.floor}}</span>
								<span class="text-sm text-content font-medium amenities-label" v-for="(am, key) in propertyDetails.amenity_ids" :key="key">{{am.name}}</span>
							</div>

							<div class="flex mt-10 mb-5">
								<div class="w-full">
									<a @click="share = true" class="bg-primary bg-opacity-10 rounded-full px-5 py-4 mr-2 mb-5 inline-flex btn-pill-fix cursor-pointer">
										<img src="@/assets/share-icon.svg" alt="" class="mr-1 inline-flex">
										<span class="text-primary text-base font-medium">Share</span>
									</a>
									  <a class="bg-primary bg-opacity-10 rounded-full px-5 py-4 inline-flex btn-pill-fix cursor-pointer" @click="shareWhatsApp(propertyDetails)" target="_blank">
										<img src="@/assets/whatsapp-blue.svg" alt="" class="mr-1 inline-flex">
										<span class="text-primary text-base font-medium">Share on Whatsapp</span>
									</a>
								</div>
							</div>
							<div class="mb-20">
								<div class="flex flex-col  mt-10 mb-5" v-if="(!propertyKeysList.is_executive && propertyKeysList.is_hide_owner_details)">
								<span class="text-black text-sm " v-if="propertyDetails.owner">{{propertyDetails.owner}}<span
									class="bg-success  text-xxs font-medium text-white rounded-full px-3 py-1">Owner</span></span>
								<span class="text-black text-sm font-medium my-2" v-if="propertyDetails.owner_contact">{{propertyDetails.owner_contact}}</span>
							</div>
							<div class="flex items-center">
										<span class="btn text-main text-sm font-medium text-opacity-50" @click="deleteProperty(propertyDetails.uuid)">Delete Property</span>
											<span class="ml-1 text-main text-opacity-50 "><svg  class="stroke-current" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M11.8125 3.0625L2.1875 3.06254" stroke="" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M5.6875 5.6875V9.1875" stroke="" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M8.3125 5.6875V9.1875" stroke="" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M10.9375 3.0625V11.375C10.9375 11.491 10.8914 11.6023 10.8094 11.6844C10.7273 11.7664 10.616 11.8125 10.5 11.8125H3.5C3.38397 11.8125 3.27269 11.7664 3.19064 11.6844C3.10859 11.6023 3.0625 11.491 3.0625 11.375V3.0625" stroke="" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M9.1875 3.0625V2.1875C9.1875 1.95544 9.09531 1.73288 8.93122 1.56878C8.76712 1.40469 8.54456 1.3125 8.3125 1.3125H5.6875C5.45544 1.3125 5.23288 1.40469 5.06878 1.56878C4.90469 1.73288 4.8125 1.95544 4.8125 2.1875V3.0625" stroke="" stroke-linecap="round" stroke-linejoin="round"/>
											</svg>
                    </span>
                    <a v-if="propertyDetails.brochure_url" :href="propertyDetails.brochure_url" class="btn text-main text-sm font-medium text-opacity-50 ml-4" target="_blank">Brochure View</a>
							</div>
							</div>

						</div>
					</div>
				</div>
				<div class="fixed w-full bottom-0 bg-white">
					<div class="flex items-center p-4 shadow-top">
						<div class="w-1/2" v-if="propertyDetails.matching_leads_count > 0">
							<div class="flex items-center w-full justify-center" @click="filterMatchesEnquiries(propertyDetails)">
								<span
									class="w-6 h-6 bg-success rounded-full text-white font-semibold text-base mr-1 flex items-center justify-center">{{propertyDetails.matching_leads_count}}</span>
								<span class="text-base text-content font-medium">Matches</span>
							</div>
						</div>
						<div class="w-1/2" v-if="(!propertyKeysList.is_executive && propertyKeysList.is_hide_owner_details)">
							<div class="flex items-center w-full justify-center bg-primary text-white px-6 py-4 rounded-full">
								<img src="@/assets/phone-w-icon.svg" alt="" class="mr-2">
									<a :href="propertyDetails.owner_contact && `tel:${propertyDetails.owner_contact}`" ><span class="text-base text-white font-medium">Call Owner</span></a>
							</div>
						</div>
					</div>
				</div>
			</div>
				<v-easy-dialog v-model="share" focus-on="#my-input">
				<div class="flex flex-col bg-white p-5 mx-4">
				<h5 class="text-base  font-medium text-main mb-3">Share with</h5>
				<div class="flex py-3 cursor-pointer">
					<img src="@/assets/whatsapp-icon.svg" alt="" class="mr-2">
							<span class="text-base text-main" @click="shareWhatsApp(propertyDetails)">Whatsapp</span>
				</div>
						<div class="flex py-3 cursor-pointer">
					<img src="@/assets/gmail-icon.svg" alt="" class="mr-2">
						<a class="text-base text-main ml-1" :href="shareMailLink" @click="shareWithGmail(propertyDetails)">Gmail</a>
				</div>
				<div class="flex justify-end space-x-2">
					<button @click="share = false" class="text-sm">Close</button>
				</div>
				</div>
			</v-easy-dialog>
		</div>
	</div>
</template>
<script>
import {numDifferentiation} from '@/assets/scripts/utility.js';
import VEasyDialog from 'v-easy-dialog'
import VueEasyLightbox from 'vue-easy-lightbox'
export default {
  components: {
    VEasyDialog,
		VueEasyLightbox,
  },
  data () {
    return {
      photoUrl: [],
      imgList: [],
      share: false,
      propertyDetails: {},
			visible: false,
      index: 0,
      shareMailLink: ''
    }
  },
  created(){
    this.$store.dispatch('setnewPropertyData', {});
    this.loadPropertyDetails()
  },

  methods: {
    deleteProperty(uuid) {
      let vm = this;
      swal({
        title: "Delete this Property?",
        buttons: true,
        buttons: ["No", "Yes"]
      }).then(confirm => {
        if (confirm) {
          vm.$axios
            .delete(`mobile_crm/properties/${uuid}/delete`)
            .then(res => {
              this.$router.replace({ name: "Properties" });
              swal({
                title: "Deleted!",
                icon: "success"
              });
            })
          .catch(err => {
            swal({
              title: "Error",
              text: err.response.data.message,
              icon: "error"
            });
          });
        }
      });
    },
	  priceinLacs(value) {
      return numDifferentiation(value)
    },
    shareWhatsApp(prop_detail) {
		  let img_url = ''
	    if (prop_detail.image_url) {
          prop_detail.image_url.forEach(img => {
            img_url = img_url + `%F0%9F%96%BC%EF%B8%8F+${img}%0D%0A`
          })
        }
      if (this.propertyKeysList.whatsapp_template_id == 2) {
        let brochure_url = ''
        let title = prop_detail.title ? encodeURIComponent(prop_detail.title) : ''
        if (prop_detail.brochure_url) {
          brochure_url = brochure_url + `%F0%9F%96%BC%EF%B8%8F+Brochure:+${prop_detail.brochure_url}%0D%0A`
        }
        window.open(`https://api.whatsapp.com/send?text=
        %F0%9F%8C%8D+++https%3A%2F%2Fwww.google.com%2Fmaps%2Fsearch%2F%3Fapi%3D1%26query%3D${prop_detail.lat}%2C${prop_detail.long}+%0D%0A%F0%9F%91%89+Location+%3A+${prop_detail.locality ? prop_detail.locality : ''}%0D%0A%F0%9F%91%89+Address+%3A+${prop_detail.address ? prop_detail.address : ''}%0D%0A%F0%9F%91%89+Floor+%3A+${prop_detail.floor ? prop_detail.floor : ''}%0D%0A%F0%9F%91%89+Unit+%3A+${prop_detail.unit_no ? prop_detail.unit_no : ''}%0D%0A%F0%9F%91%89+Carpet+Area%3A+${prop_detail.carpet_area}+Sq.Ft%0D%0A%F0%9F%91%89+Buildup+Area%3A+${prop_detail.builtup_area ? prop_detail.builtup_area : ''}+Sq.Ft%0D%0A%F0%9F%91%89+Furnishing +Status%3A+${prop_detail.furnishing_type_id ? prop_detail.furnishing_type_id : ''}%0D%0A%F0%9F%91%89+Furnishing+Details%3A+${prop_detail.no_of_workstations ? prop_detail.no_of_workstations : ''}%0D%0A%F0%9F%91%89+Conference+room%3A+${prop_detail.no_of_conference_room ? prop_detail.no_of_conference_room : ''}+Nos%0D%0A%F0%9F%91%89+Private+Cabin%3A+${prop_detail.no_of_cabins ? prop_detail.no_of_cabins : ''}+Nos%0D%0A%F0%9F%91%89+Car+parks%3A+${prop_detail.parking ? prop_detail.parking : ''}%0D%0A%F0%9F%91%89+Height%3A+${prop_detail.height ? prop_detail.height : ''}%0D%0A%F0%9F%91%89+Frontage%3A+${prop_detail.frontage ? prop_detail.frontage : ''}%0D%0A%F0%9F%91%89+Washroom%3A+${prop_detail.bathroom ? prop_detail.bathroom : ''}%0D%0A${img_url}${brochure_url}+%0D%0A%0D%0ATo+arrange+site+visits%2C+or+to+know+more+details+contact+%3A+%0D%0A%F0%9F%91%A4+${this.userName}%0D%0A%F0%9F%93%B1+${this.userMobile}%0D%0A%F0%9F%93%A7+${this.userEmail}${prop_detail.rera_no ? '%0D%0A%F0%9F%91%89Consultants+Rera+No%3A' + prop_detail.rera_no : ''}%0D%0A%0D%0A`)
      } else {
        let title = prop_detail.title ? encodeURIComponent(prop_detail.title) : ''
        window.open(`https://api.whatsapp.com/send?text=
          %F0%9F%8F%A8+Flat+%2F+Apartment+for+${prop_detail.property_for}%0D%0A%F0%9F%91%89+Property+Name+%3A+${title}${prop_detail.expected_price ? '%0D%0A%F0%9F%91%89+Price+%3A+' + prop_detail.expected_price : ''}${prop_detail.configuration ? '%0D%0A%F0%9F%91%89+Bedrooms+%3A+' + prop_detail.configuration: ''}${prop_detail.bathroom ? '%0D%0A%F0%9F%91%89+Bathrooms+%3A+' + prop_detail.bathroom: ''}${prop_detail.furnishing_type_id ? '%0D%0A%F0%9F%91%89+Furniture%3A+' + prop_detail.furnishing_type_id: ''}${prop_detail.builtup_area ? '%0D%0A%F0%9F%91%89+Salable+Area%3A+' + prop_detail.builtup_area + '+Sq.Ft': ''}%0D%0A%F0%9F%91%89+Carpet+Area%3A+${prop_detail.carpet_area}+Sq.Ft%0D%0A%F0%9F%91%89+Location+%3A+${prop_detail.locality ? prop_detail.locality : ''}%2C+${prop_detail.city ? prop_detail.city : ''}${prop_detail.external_comments ? '%0D%0A%F0%9F%91%89+Additional+Information+%3A+' + prop_detail.external_comments: ''}
          %0D%0A%0D%0A%F0%9F%8C%8D+++https%3A%2F%2Fwww.google.com%2Fmaps%2Fsearch%2F%3Fapi%3D1%26query%3D${prop_detail.lat}%2C${prop_detail.long}+%0D%0A${img_url}%0D%0ATo+arrange+site+visits%2C+or+to+know+more+details+contact+%3A+%0D%0A%F0%9F%91%A4+${this.userName}%0D%0A%F0%9F%93%B1+${this.userMobile}%0D%0A%F0%9F%93%A7+${this.userEmail}${prop_detail.rera_no ? '%0D%0A%F0%9F%91%89Consultants+Rera+No%3A' + prop_detail.rera_no : ''}%0D%0A%0D%0A`)
        }

    },
    shareWithGmail(prop_detail) {
      let title = prop_detail.title ? encodeURIComponent(prop_detail.title) : ''
      let text = `%F0%9F%8F%A8 Flat %2F Apartment for ${prop_detail.property_for}%0D%0A%F0%9F%91%89 Property Name %3A ${title}%0D%0A%F0%9F%91%89 Price %3A ${prop_detail.expected_price} ${prop_detail.configuration ? '%0D%0A%F0%9F%91%89 Bedrooms %3A' + prop_detail.configuration : ''}${prop_detail.bathroom ? '%0D%0A%F0%9F%91%89 Bathrooms %3A ' + prop_detail.bathroom : ''}${prop_detail.furnishing_type_id ? '%0D%0A%F0%9F%91%89 Furniture%3A ' + prop_detail.furnishing_type_id: ''}${prop_detail.builtup_area ? '%0D%0A%F0%9F%91%89 Salable Area%3A ' + prop_detail.builtup_area + 'Sq.Ft ': ''} %0D%0A%F0%9F%91%89 Carpet Area%3A ${prop_detail.carpet_area}Sq.Ft %0D%0A%F0%9F%91%89 Location %3A ${prop_detail.locality ? prop_detail.locality: ''}%2C ${prop_detail.city ? prop_detail.city: ''} ${prop_detail.external_comments ? '%0D%0A%F0%9F%91%89Additional Information %3A' + prop_detail.external_comments: ''}%0D%0A%0D%0A%F0%9F%91%89 https%3A%2F%2Fwww.google.com%2Fmaps%2Fsearch%2F%3Fapi%3D1%26query%3D${prop_detail.lat}%2C${prop_detail.long} %0D%0A%0D%0ATo arrange site visits%2C or to know more details contact %3A %0D%0A%F0%9F%91%A4 ${this.userName}%0D%0A%F0%9F%93%B1 ${this.userMobile}%0D%0A%F0%9F%93%A7 ${this.userEmail}${prop_detail.rera_no ? '%0D%0A%F0%9F%91%89Consultants era No%3A' + prop_detail.rera_no: ''}%0D%0A%0D%0A`
      let url = `mailto:?Subject=Property Details&body=${text}&ui=2&tf=1&pli=1`;
      this.shareMailLink = url
    },
    openGoogleMap(prop_detail) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${prop_detail.lat},${prop_detail.long}`)
    },
    filterMatchesEnquiries(properties) {
      let apiParams = {}
      apiParams['locality_id'] = properties.locality_id
      apiParams['budget_from'] = properties.expected_price
      apiParams['transaction_type_id'] = properties.property_for_id
      apiParams['match'] = true
      this.$store.dispatch('setAdvSParams', apiParams);
      this.$router.push({name: 'Enquiry'})
    },
    loadPropertyDetails(){
      this.$axios.get(`mobile_crm/properties/${this.$route.params.uuid}`)
        .then((res) => {
        this.propertyDetails = res.data.property
        this.photoUrl = this.propertyDetails.image_url
      })
      .catch((err) => console.log(err));
    },
		showImg (img_url) {
        this.imgList = img_url
        this.index = 1
        this.visible = true
      },
      handleHide () {
        this.visible = false
      }

  },
  computed: {
    userName(){
      return localStorage.getItem('property-user-name')
    },
    created_user(){
      return this.$store.getters.created_user;
    },
    userEmail(){
      return localStorage.getItem('property-user-email')
    },
    userMobile(){
      return localStorage.getItem('property-user-mobile')
    },
    propertyKeysList(){
      return this.$store.getters.propertyKeys;
    },
  }
}

</script>
<style scoped>
	.amenities-label {
		@apply border-r border-divider pr-3 mr-3 inline-flex mb-2;
	}

	.amenities-label:first-child {
		@apply pl-0;
	}

	.amenities-label:last-child {
		@apply border-none;
	}
	.img-gallery:nth-child(4) {
		@apply relative cursor-pointer pointer-events-auto;
	}
	.img-gallery:nth-child(4)::after {
		@apply absolute bg-black bg-opacity-50 inset-0 rounded-sm ;
		content: '';
	}
	.img-gallery:nth-child(4) span{
		@apply flex;
	}
	@media (max-width:350px) {
		.h-fix {
			height: 50px;
		}
  }

</style>