<template>
	<div class="animate_animated animate__slideInRight">
		<div class="fixed w-full top-0 left-0 z-20">
		<div class="flex bg-white px-4 py-6 items-center shadow-ct">
			<div class="w-full">
				<div class="flex items-center w-full">
					<a  @click="$router.go(-1)"><img src="@/assets/back-arrow.svg" alt=""></a>
					<span class="text-base text-content font-medium ml-4">Search Project</span>
				</div>
			</div>
		</div>
    </div>
    <div class="pt-20 mt-2 bg-white">
    <div class="bg-white min-h-screen p-4">
      <form>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Project Name</label>
          <input type="text" v-model="search.name" placeholder="Owner Name" class="border border-inputBorder round-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Created From</label>
          <input type="date" v-model="search.created_from" placeholder="Created From" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Created Upto</label>
          <input type="date" v-model="search.created_upto" placeholder="Created Upto" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
        
        <div class="mt-10">
          <a @click="clearFilters" class="clearFilter"> Clear Filters</a>
          <button type="button" @click="performSearch" class="bg-primary text-base font-semibold text-white rounded-full p-5 w-full disabled:opacity-50" >Filter</button>
        </div>
      </form>
    </div>
  </div>
	</div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
  components: {
    Multiselect
  },
  data () {
    return {
      search: {
        name: null,
        created_at_from: null,
        created_at_upto: null,
      },
    }
  },
  methods: {
    performSearch(){
      this.$store.dispatch('setAdvSParams', this.search);
      this.$router.push({name: 'Projects'})
    },
    seedDefaults(){
      this.search = this.advSParams
    },
    clearFilters(){
      this.search = {
        name: null,
        created_from: null,
        created_upto: null,
      }
      this.$store.dispatch('setAdvSParams', {});
    }
  },
  created(){
    if(Object.keys(this.advSParams).length > 0){
      this.seedDefaults();
    }
  },
  computed: {
    propertyKeysList(){
      return this.$store.getters.propertyKeys;
    },
    advSParams(){
      return this.$store.getters.advSParams;
    },
    leadKeysList(){
      return this.$store.getters.leadKeys;
    },
  }
}
</script>
<style scoped>
.clearFilter{
  display: table;
  margin:20px auto;
  opacity: .5;
  font-size: 14px;
}
</style>