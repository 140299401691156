<template>
	<div class="animate_animated animate__slideInRight">
    <div class="fixed w-full top-0 left-0 z-20">
			<div class=" bg-white px-4 py-6 pb-5 shadow-ct">
				<div class="flex items-center">
					<div class="w-2/3">
						<div class="flex items-center w-full">
							<a  @click="$route.query.match ? $router.go(-1) : $router.push('/')"><img src="@/assets/back-arrow.svg" alt=""></a>
							<span class="text-base text-content font-medium ml-4">{{ $route.query.match ? 'Matching Properties'  : 'Properties'}}</span>
						</div>
					</div>
					<div class="w-1/3">
						<div class="flex items-center justify-end">
              <a @click="share = true" class="mx-2 relative"><img src="@/assets/share.svg" alt="" ><div v-if="shareSelectedProperty.length > 0" class="absolute w-3 h-3 -right-1 -top-1 bg-primary text-white rounded-full items-center flex justify-center"><span  class=" text-xxs block ">{{shareSelectedProperty.length}}</span></div></a>
							<a @click="$router.push('searchproperties')" class="mx-2"><img src="@/assets/filter-icon.svg" alt=""></a>
						</div>
					</div>
				</div>
				<div class="flex mt-5">
					<div class="flex items-strech w-full">
						<div class="relative w-full">
							<input type="text" @input="e => bsTerm = e.target.value" :value="bsTerm" name="" id="" placeholder="Search by Owner, Project Name and City"
								class="border border-inputBorder round-md p-3 w-full z-auto focus:outline-none rounded-tl rounded-bl">
							<button type="button" class="absolute right-0 top-0 m-3" v-if="bsTerm" @click="resetBs">
              <img src="@/assets/close-icon.svg" alt="" class="transform rotate-45"></button>
						</div>

						<div class="bg-primary rounded-tr rounded-br px-3 flex items-center">
              <button type="button" :disabled="!bsTerm" @click="performBs">
							<img src="@/assets/search-icon-white.svg"   alt="" class=""></button>
						</div>
					</div>
				</div>
			</div>
		</div>
<!-- Card-List -->
<div class="pt-16 mt-18">
  <span class="result-Text px-3 pt-2 text-sm text-main text-opacity-50 block">Showing {{ pageStart }} - {{ pageEnd }} of {{ total }}</span>
    <tabs class="mt-1" v-model="currentTabSelected" @changed="setCurrentTab" :options="{ defaultTabHash: 'Residential', useUrlFragment: false }">
      <tab name="Residential"></tab>
      <tab name="Commercial"></tab>
    </tabs>
      <vue-easy-lightbox
      :visible="visible"
      :imgs="imgList"
      @hide="handleHide"
    ></vue-easy-lightbox>
    <div class="shadow-ct bg-white my-4" v-for="(pl, key) in propertyList" :key="key">
			<div class="flex items-center p-2 border-b border-divider">
				<div class="w-1/3">
        <div class="flex">
          <input type="checkbox" v-model="shareSelectedProperty" :value="pl" class="mr-2">
          <div class="w-full">
            <img :src="pl.image_url"  @click="opeLightBox(pl.image_url)" alt="" class="rounded  h-28 w-full object-cover object-center ">
          </div>
          </div>

				</div>
				<div class="w-2/3">
         <router-link :to="{ name: 'PropertyDetails',  params: {  uuid: pl.uuid }}">
					<div class="p-3">
						<div class="flex items-center mb-1">
							<span class="text-xs text-content text-opacity-40 mr-1">{{pl.days_ago == 0 ? 'Today' : pl.days_ago + ' days ago'}}</span>
              <span v-if="created_user" class="text-xs text-black font-medium mr-2 text-opacity-50">{{pl.user_name}}</span>
							<span class="text-xs bg-primary text-white font-medium rounded-full px-3 mr-1">{{pl.property_for}}</span>
							<span class="text-xs text-success font-medium">{{pl.status}}</span>
						</div>
						<h2 class="text-content text-sm font-medium mb-1">{{pl.title}}</h2>
						<div class="flex items-center mb-1">
							<img src="@/assets/location.svg" alt="" class="mr-1"> <span
								class="text-content text-xs text-opacity-50">{{pl.locality}}, {{pl.city}}</span>
						</div>
						<div class="flex items-center ">
							<span class="text-xs text-content font-medium">Rs {{priceinLacs(pl.expected_price) | asINR}}</span>
							<span class="mx-2 text-divider">|</span>
							<span class="text-xs text-content font-medium">{{pl.carpet_area}} sq.ft</span>
						</div>
					</div>
            </router-link>
				</div>
			</div>

			<div class="flex items-center p-4" v-if="!$route.query.match & pl.matching_leads_count > 0">
				<div class="w-1/2">
					<div class="flex items-center w-full"  @click="filterMatchesEnquiries(pl)">
						<span
							class="w-6 h-6 bg-success rounded-full text-white font-semibold text-xs mr-1 flex items-center justify-center">{{pl.matching_leads_count}}</span>
						<span class="text-xs text-content font-medium">Matches</span>
					</div>
				</div>
				<!-- <div class="w-1/2">
					<div class="flex items-center justify-end">
						<a href="" class="mx-4"><img src="@/assets/whatsapp.svg" alt=""></a>
						<a href="" class="mx-4"><img src="@/assets/email-icon.svg" alt=""></a>
						<a :href="pl.owner_contact && `tel:${pl.owner_contact}`" v-if="pl.owner_contact" class="mx-4"><img src="@/assets/phone-icon.svg" alt=""></a>
					</div>
				</div> -->
 			</div>
		</div>
		<!-- Add Button -->
		<router-link :to="{ name: 'NewProperty' }" v-if="!$route.query.match" class="fixed bottom-0 right-0 m-4 flex items-center justify-center w-14 h-14 bg-primary rounded-full">
			<img src="@/assets/plus-icon.svg" alt="">
		</router-link>
    <div class="pagination-holder">
      <paginate
        v-if="totalPages > 1"
        v-model="pageNo"
        :page-count="totalPages"
        :click-handler="goToPage"
        :page-class="'page-item'"
        :page-link-class="'page-link'"
        :prev-link-class="'page-link'"
        :next-link-class="'page-link'"
        :disabled-class="'page-item disabled'"
        :container-class="'pagination'">
      </paginate>
    </div>
      <v-easy-dialog v-model="share" focus-on="#my-input">
        <div class="flex flex-col bg-white p-5 mx-4">
          <h5 class="text-base  font-medium text-main mb-3">Share with</h5>
          <div class="flex py-3 cursor-pointer">
            <img src="@/assets/whatsapp-icon.svg" alt="" class="mr-2">
            <span class="text-base text-main" @click="shareWhatsApp()">Whatsapp</span>
          </div>
          <div class="flex py-3 cursor-pointer">
            <img src="@/assets/gmail-icon.svg" alt="" class="mr-2">
            <a :href="shareMailLink" @click="shareWithGmail()">Gmail</a>
          </div>
          <div class="flex justify-end space-x-2">
              <button @click="share = false" class="text-sm">Close</button>
          </div>
        </div>
    </v-easy-dialog>
</div>
	</div>
</template>
<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import VEasyDialog from 'v-easy-dialog'
import {paramsFromQuery, preparePropertiesSearchParams, numDifferentiation} from '@/assets/scripts/utility.js';
export default {
  name: 'properties',
  components: {
    VEasyDialog,
    VueEasyLightbox
  },
  data () {
    return {
      bsTerm: this.$route.query.bs,
      total: 0,
      currentTabSelected: null,
      perPage: 0,
      share: false,
      shareSelectedProperty: [],
      propertyList: [],
      matched_lead_detail: [],
      advSearchParams: [],
      imgList: [],
      index: 0,
      shareMailLink: '',
      visible: false,
    }
  },
  watch: {
    $route(to, from){
      this.loadProperties();
    }
  },
  created(){
    this.$store.dispatch('setnewPropertyData', {});
    this.loadProperties()
  },
  methods: {
    setCurrentTab(selectedTab) {
      if (selectedTab) {
        this.currentTabSelected = selectedTab.tab.name
        this.loadProperties()
      }
    },
    opeLightBox(img_url) {
      this.imgList = img_url
      this.index = 1
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    priceinLacs(value) {
      return numDifferentiation(value)
    },
    shareWhatsApp() {
      let link = ''
      let img_url = ''
      if (this.matched_lead_detail && this.matched_lead_detail.mobile != ''){
        link = `https://api.whatsapp.com/send?phone=+91${this.matched_lead_detail.mobile}&text=`
      }
      else{
        link = 'https://api.whatsapp.com/send?text='
      }
      let url = ''
      this.shareSelectedProperty.forEach(prop_detail => {
        if (prop_detail.image_url) {
          prop_detail.image_url.forEach(img => {
            img_url = img_url + `%F0%9F%96%BC%EF%B8%8F+${img}%0D%0A`
          })
        }
        if (this.propertyKeysList.whatsapp_template_id == 2) {
          let brochure_url = ''
          if (prop_detail.brochure_url) {
            brochure_url = brochure_url + `%F0%9F%96%BC%EF%B8%8F+Brochure:+${prop_detail.brochure_url}%0D%0A`
          }
          url = url + `%F0%9F%8C%8D+++https%3A%2F%2Fwww.google.com%2Fmaps%2Fsearch%2F%3Fapi%3D1%26query%3D${prop_detail.lat}%2C${prop_detail.long}+%0D%0A%F0%9F%91%89+Location+%3A+${prop_detail.locality ? prop_detail.locality : ''}%0D%0A%F0%9F%91%89+Address+%3A+${prop_detail.address ? prop_detail.address : ''}%0D%0A%F0%9F%91%89+Floor+%3A+${prop_detail.floor ? prop_detail.floor : ''}%0D%0A%F0%9F%91%89+Unit+%3A+${prop_detail.unit_no ? prop_detail.unit_no : ''}%0D%0A%F0%9F%91%89+Carpet+Area%3A+${prop_detail.carpet_area}+Sq.Ft%0D%0A%F0%9F%91%89+Buildup+Area%3A+${prop_detail.builtup_area ? prop_detail.builtup_area : ''}+Sq.Ft%0D%0A%F0%9F%91%89+Furnishing +Status%3A+${prop_detail.furnishing_type_id ? prop_detail.furnishing_type_id : ''}%0D%0A%F0%9F%91%89+Furnishing+Details%3A+${prop_detail.no_of_workstations ? prop_detail.no_of_workstations : ''}%0D%0A%F0%9F%91%89+Conference+room%3A+${prop_detail.no_of_conference_room ? prop_detail.no_of_conference_room : ''}+Nos%0D%0A%F0%9F%91%89+Private+Cabin%3A+${prop_detail.no_of_cabins ? prop_detail.no_of_cabins : ''}+Nos%0D%0A%F0%9F%91%89+Car+parks%3A+${prop_detail.parking ? prop_detail.parking : ''}%0D%0A%F0%9F%91%89+Height%3A+${prop_detail.height ? prop_detail.height : ''}%0D%0A%F0%9F%91%89+Frontage%3A+${prop_detail.frontage ? prop_detail.frontage : ''}%0D%0A%F0%9F%91%89+Washroom%3A+${prop_detail.bathroom ? prop_detail.bathroom : ''}%0D%0A${img_url}${brochure_url}+%0D%0A%0D%0ATo+arrange+site+visits%2C+or+to+know+more+details+contact+%3A+%0D%0A%F0%9F%91%A4+${this.userName}%0D%0A%F0%9F%93%B1+${this.userMobile}%0D%0A%F0%9F%93%A7+${this.userEmail}${prop_detail.rera_no ? '%0D%0A%F0%9F%91%89Consultants+Rera+No%3A' + prop_detail.rera_no : ''}%0D%0A%0D%0A`

        } else {
          let title = prop_detail.title ? encodeURIComponent(prop_detail.title) : ''
          url = url + `%F0%9F%8F%A8+Flat+%2F+Apartment+for+${prop_detail.property_for}%0D%0A%F0%9F%91%89+Property+Name+%3A+${title}${prop_detail.expected_price ? '%0D%0A%F0%9F%91%89+Price+%3A+' + prop_detail.expected_price : ''}${prop_detail.configuration ? '%0D%0A%F0%9F%91%89+Bedrooms+%3A+' + prop_detail.configuration: ''}${prop_detail.bathroom ? '%0D%0A%F0%9F%91%89+Bathrooms+%3A+' + prop_detail.bathroom: ''}${prop_detail.furnishing_type_id ? '%0D%0A%F0%9F%91%89+Furniture%3A+' + prop_detail.furnishing_type_id: ''}${prop_detail.builtup_area ? '%0D%0A%F0%9F%91%89+Salable+Area%3A+' + prop_detail.builtup_area + '+Sq.Ft': ''}%0D%0A%F0%9F%91%89+Carpet+Area%3A+${prop_detail.carpet_area}+Sq.Ft%0D%0A%F0%9F%91%89+Location+%3A+${prop_detail.locality ? prop_detail.locality : ''}%2C+${prop_detail.city ? prop_detail.city : ''}${prop_detail.external_comments ? '%0D%0A%F0%9F%91%89+Additional+Information+%3A+' + prop_detail.external_comments: ''}
          %0D%0A%0D%0A%F0%9F%8C%8D+++https%3A%2F%2Fwww.google.com%2Fmaps%2Fsearch%2F%3Fapi%3D1%26query%3D${prop_detail.lat}%2C${prop_detail.long}%0D%0A${img_url}+%0D%0A%0D%0ATo+arrange+site+visits%2C+or+to+know+more+details+contact+%3A+%0D%0A%F0%9F%91%A4+${this.userName}%0D%0A%F0%9F%93%B1+${this.userMobile}%0D%0A%F0%9F%93%A7+${this.userEmail}${prop_detail.rera_no ? '%0D%0A%F0%9F%91%89Consultants+Rera+No%3A' + prop_detail.rera_no : ''}%0D%0A%0D%0A`
        }


      })
      link = link + url
      window.open(link)
    },
    shareWithGmail() {
      let text = '';
      this.shareSelectedProperty.forEach(prop_detail => {
        let title = prop_detail.title ? encodeURIComponent(prop_detail.title) : ''
        text = text + `%F0%9F%8F%A8 Flat %2F Apartment for ${prop_detail.property_for}%0D%0A%F0%9F%91%89 Property Name %3A ${title}%0D%0A%F0%9F%91%89 Price %3A ${prop_detail.expected_price} ${prop_detail.configuration ? '%0D%0A%F0%9F%91%89 Bedrooms %3A' + prop_detail.configuration : ''}${prop_detail.bathroom ? '%0D%0A%F0%9F%91%89 Bathrooms %3A ' + prop_detail.bathroom : ''}${prop_detail.furnishing_type_id ? '%0D%0A%F0%9F%91%89 Furniture%3A ' + prop_detail.furnishing_type_id: ''}${prop_detail.builtup_area ? '%0D%0A%F0%9F%91%89 Salable Area%3A ' + prop_detail.builtup_area + 'Sq.Ft ': ''} %0D%0A%F0%9F%91%89 Carpet Area%3A ${prop_detail.carpet_area}Sq.Ft %0D%0A%F0%9F%91%89 Location %3A ${prop_detail.locality ? prop_detail.locality: ''}%2C ${prop_detail.city ? prop_detail.city: ''} ${prop_detail.external_comments ? '%0D%0A%F0%9F%91%89Additional Information %3A' + prop_detail.external_comments: ''}%0D%0A%0D%0A%F0%9F%91%89 https%3A%2F%2Fwww.google.com%2Fmaps%2Fsearch%2F%3Fapi%3D1%26query%3D${prop_detail.lat}%2C${prop_detail.long} %0D%0A%0D%0ATo arrange site visits%2C or to know more details contact %3A %0D%0A%F0%9F%91%A4 ${this.userName}%0D%0A%F0%9F%93%B1 ${this.userMobile}%0D%0A%F0%9F%93%A7 ${this.userEmail}${prop_detail.rera_no ? '%0D%0A%F0%9F%91%89Consultants era No%3A' + prop_detail.rera_no: ''}%0D%0A%0D%0A`
      })
      let url = ''
      if (this.matched_lead_detail && this.matched_lead_detail.email != ''){
        url = `mailto:${this.matched_lead_detail.email}?Subject=Property Details&body=${text}&ui=2&tf=1&pli=1`;
      } else {
        url = `mailto:?Subject=Property Details&body=${text}&ui=2&tf=1&pli=1`;
      }
      this.shareMailLink = url
    },
    filterMatchesEnquiries(properties) {
      let apiParams = {}
      apiParams['locality_id'] = properties.locality_id
      apiParams['budget_from'] = (properties.expected_price - properties.expected_price*15/100)
      apiParams['budget_upto'] = (properties.expected_price + properties.expected_price*15/100)
      apiParams['transaction_type_id'] = properties.property_for_id
      apiParams['match'] = true
      apiParams['as'] = true
      this.$router.replace({name: 'Enquiry', query: apiParams})
    },
    loadProperties(){
      let queryP = {}
        if(Object.keys(this.advSParams).length > 0){
          queryP["as"] = true
          let searchParams = preparePropertiesSearchParams(this.advSParams);
          for(let key in searchParams){
            queryP[key] = searchParams[key];
          }
          this.advSearchParams = queryP
        }
        if (this.advSearchParams.length > 0){
          paramsFromQuery(queryP, this.advSearchParams);
        }
        else{
          paramsFromQuery(queryP, this.$route.query);
        }
        if (this.currentTabSelected) {
          queryP['selected_tab'] = this.currentTabSelected;
          let data = this.$axios.get('/mobile_crm/properties', {
            params: queryP
          })
          .then(res=>{
            this.propertyList = res.data.proprties;
            this.matched_lead_detail = res.data.matched_lead_detail
            this.perPage = res.data.per_page;
            this.total = res.data.count;
        })
        .catch(err => console.log(err));
        }
    },
    goToPage(pageNo){
      let newQ = {...this.$route.query, pageNo};
      this.$router.replace({query: newQ});
    },
    resetBs(){
      let rQ = this.$route.query
      this.bsTerm = ''
      if(rQ.bs){
        delete rQ.bs;
        let reset = {}
        for(let key in rQ){
          reset[key] = rQ[key];
        }
        reset['reset'] = reset.reset ? (reset.reset == 0 ? 1 : 0) : 1
        this.$router.replace({query: reset})
      }
    },
    performBs(){
      if(this.bsTerm){
        let newQ = {...this.$route.query, bs: this.bsTerm}
        delete newQ.pageNo
        this.$router.replace({query: newQ})
      }
    }
  },
  computed: {
    userName(){
      return localStorage.getItem('property-user-name')
    },
    propertyKeysList(){
      return this.$store.getters.propertyKeys;
    },
    userEmail(){
      return localStorage.getItem('property-user-email')
    },
    userMobile(){
      return localStorage.getItem('property-user-mobile')
    },
    advSParams(){
      return this.$store.getters.advSParams;
    },
    created_user(){
      return this.$store.getters.created_user;
    },
    totalPages(){
      if(this.total === 0 || this.perPage === 0){
        return 0;
      }else{
        return Math.ceil(this.total/this.perPage);
      }
    },
    pageNo: {
      get(){
        let pageNo = this.$route.query.pageNo || 1
        return parseInt(pageNo);
      },
      set(){
        true;
      }
    },
    pageStart(){
      if(this.total === 0){
        return 0;
      }
      return (Math.max(0, (this.pageNo - 1)*this.perPage+1))
    },
    pageEnd(){
      if(this.total === 0){
        return 0;
      }
      return (Math.min(this.total, this.pageNo*this.perPage))
    },
  }
}
</script>
<style scoped>

</style>