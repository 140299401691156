<template>
  <div class="animate_animated animate__slideInRight">
    <div class="fixed w-full top-0 left-0 z-20">
      <div class="flex bg-white px-4 py-6 items-center shadow-ct">
        <div class="w-full">
          <div class="flex items-center w-full">
            <a @click="$router.go(-1)"><img src="@/assets/back-arrow.svg" alt=""></a>
            <span class="text-base text-content font-medium ml-4">New Project</span>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-20 mt-2 bg-white">
      <div class="bg-white min-h-screen p-4">
        <Project :project="AddNewProject" :uploadImg="uploadImg" :addBrochure="addBrochure" :submitFn="createProject"
          :getLocality="getLocality" :localityList="localityList" />
      </div>
    </div>
  </div>
</template>
<script>
import Project from '@/components/Project/New.vue';
import { preparePropertyParams } from '@/assets/scripts/utility.js';
export default {
  components: {
    Project
  },
  data() {
    return {
      AddNewProject: {
        name: null,
        developer_name: null,
        developer_contact: null,
        possession_status: null,
        possession_date: null,
        property_age: null,
        rera_no: null,
        project_approve_by: null,
        city_id: null,
        locality_id: null,
        location: null,
        lat: null,
        long: null,
        amenity_ids: [],
        upload_img: [],
        brochure: null
      },
      localityList: [],
    }
  },
  methods: {
    uploadImg() {
      this.AddNewProject.upload_img = event.target.files;
    },
    addBrochure() {
      this.AddNewProject.brochure = event.target.files[0];
    },
    getLocality() {
      this.$axios.get(`mobile_crm/properties/fetch_localities?city_id=${this.AddNewProject.city_id}`)
        .then((res) => {
          this.localityList = res.data
        })
        .catch((err) => console.log(err));
    },
    createProject() {
      let formData = new FormData();
      if (this.AddNewProject.upload_img.length > 0) {
        for (let file of this.AddNewProject.upload_img) {
          formData.append('images_attributes[][image]', file)
          formData.append('images_attributes[][_destroy]', false)
        }
      }
      if (this.AddNewProject.brochure) {
        formData.append('brochure', this.AddNewProject.brochure)
      }
      formData.append('name', this.AddNewProject.name || '');
      formData.append('developer_name', this.AddNewProject.developer_name || '');
      formData.append('developer_contact', this.AddNewProject.developer_contact || '');
      formData.append('possession_status', this.AddNewProject.possession_status || '');
      formData.append('possession_date', this.AddNewProject.possession_date || '');
      formData.append('property_age', this.AddNewProject.property_age || '');
      formData.append('rera_no', this.AddNewProject.rera_no || '');
      formData.append('project_approve_by', this.AddNewProject.project_approve_by || '');
      formData.append('city_id', this.AddNewProject.city_id || '');
      formData.append('location', this.AddNewProject.location || '');
      formData.append('lat', this.AddNewProject.lat || '')
      formData.append('long', this.AddNewProject.long || '')
      formData.append('locality_id', this.AddNewProject.locality_id || '');
      formData.append('description', this.AddNewProject.description || '');
      if (this.AddNewProject.amenity_ids.length > 0) {
        this.AddNewProject.amenity_ids.forEach(am => {
          formData.append('amenity_ids[]', am.id)
        })
      } else {
        formData.append('amenity_ids[]', '')
      }
      this.$axios
        .post("/mobile_crm/projects", formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        .then(res => {
          swal({
            title: "Project Created!",
            icon: "success"
          });
            this.$axios.get('mobile_crm/properties/settings')
            .then(res => {
              let {property_keys} = res.data
              this.$store.dispatch('setPropertyKeys', property_keys)
              this.$router.replace({ name: "Projects" })
            })
            .catch(err => console.log(err.response))
        })
        .catch(err => {
          if (err.response.status === 500) {
            swal({
              title: "Server Error!",
              icon: "error"
            });
          } else {
            swal({
              title: "Error",
              text: err.response.data.message,
              icon: "error"
            });
          }
        });
    },
  }
}
</script>
<style scoped>
</style>