<template>
  <div class="animate_animated animate__slideInRight">
    <div class="fixed w-full top-0 left-0 z-20">
      <div class="flex bg-white px-4 py-6 items-center shadow-ct">
        <div class="w-full">
          <div class="flex items-center w-full">
            <a @click="$router.go(-1)"><img src="@/assets/back-arrow.svg" alt=""></a>
            <span class="text-base text-content font-medium ml-4">Agreement Details</span>
          </div>
        </div>

      </div>
    </div>

    <div class="pt-16 mt-2  pb-20">
      <tabs :options="{ defaultTabHash: 'Details', useUrlFragment: false }">
        <tab id="Details" name="Details">
          <div class="p-5">
            <h3 class="text-primary font-semibold text-xs uppercase tracking-ct mb-4">Basic Details</h3>
            <div class="grid gap-1 grid-cols-1">
              <div>
                <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Property</span>
                <span class="text-content text-xs font-medium block mb-3">{{ agreementDetails.property_name }}</span>
              </div>
            </div>
            <div class="grid gap-1 grid-cols-2">
              <div>
                <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Transaction Type</span>
                <span class="text-content text-xs font-medium block mb-3">{{ agreementDetails.transaction_type }}</span>
              </div>
              <div>
                <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Buyer</span>
                <span class="text-content text-xs font-medium block mb-3">{{ agreementDetails.buyer_name }}</span>
              </div>
              <div>
                <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Owner</span>
                <span class="text-content text-xs font-medium block mb-3">{{ agreementDetails.owner }}</span>
              </div>
              <div>
                <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Configuration</span>
                <span class="text-content text-xs font-medium block mb-3">{{ agreementDetails.configuration }}</span>
              </div>
              <div>
                <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Type</span>
                <span class="text-content text-xs font-medium block mb-3">{{ agreementDetails.agreement_type_id }}</span>
              </div>
              <div>
                <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Broker</span>
                <span class="text-content text-xs font-medium block mb-3">{{ agreementDetails.broker }}</span>
              </div>
              <div>
                <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Agreement From</span>
                <span class="text-content text-xs font-medium block mb-3">{{ agreementDetails.agreement_date }}</span>
              </div>
              <div>
                <span class="flex">
                  <span class="text-content text-xs text-opacity-50  mt-1 mb-1 mr-1">Agreement Till</span>
                  <span
                    class="bg-success text-white text-xxs  font-medium  mt-1 mb-1 rounded-full px-2 text-center">{{ agreementDetails.days_left
                        > 0 ? agreementDetails.days_left + ' days left' : agreementDetails.days_left == 0 ? 'Today' :
                        'Expired'
                    }}
                  </span>
                </span>

                <span class="text-content text-xs font-medium block mb-3">{{ agreementDetails.agreement_till }}</span>
              </div>
            </div>
          </div>
          <div class="p-5">
            <h3 class="text-primary font-semibold text-xs uppercase tracking-ct mb-4">Financial Details</h3>
            <div class="grid gap-1 grid-cols-2">
              <div>
                <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Price</span>
                <span class="text-content text-xs font-medium block mb-3">Rs {{ priceinLacs(agreementDetails.price) |
                    asINR
                }}</span>
              </div>
              <!-- <div>
								<span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Rent</span>
								<span class="text-content text-xs font-medium block mb-3">10,000</span>
							</div> -->
              <div>
                <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Buyer Brokerage</span>
                <span class="text-content text-xs font-medium block mb-3">Rs
                  {{ priceinLacs(agreementDetails.buyer_brokerage) | asINR }}</span>
              </div>
              <div>
                <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Seller Brokerage</span>
                <span class="text-content text-xs font-medium block mb-3">Rs
                  {{ priceinLacs(agreementDetails.seller_brokerage) | asINR }}</span>
              </div>
              <div>
                <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Total Brokerage</span>
                <span class="text-content text-xs font-medium block mb-3">Rs
                  {{ priceinLacs(agreementDetails.total_brokerage) | asINR }}</span>
              </div>
              <div>
                <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">CGST</span>
                <span class="text-content text-xs font-medium block mb-3">Rs {{ priceinLacs(agreementDetails.gst) |
                    asINR
                }}</span>
              </div>
            </div>
          </div>
          <div class="flex items-center p-4 ">
            <div class="w-full">
              <router-link :to="{ name: 'EditAgreement', params: { uuid: this.$route.params.uuid } }"
                class="flex items-center w-full justify-center bg-primary text-white px-6 py-4 rounded-full text-base font-medium">
                Edit
              </router-link>
            </div>
          </div>
          <div class="flex items-center p-4 ">
            <div class="w-full">
              <button type="button" @click="deleteAgreement()"
                class="flex items-center w-full justify-center bg-primary text-white px-6 py-4 rounded-full text-base font-medium">Delete</button>
            </div>
          </div>
        </tab>
        <tab id="Collections" name="Collections">
          <div class="shadow-ct bg-white my-4 px-4 py-3" v-for="(c, key) in agreementDetails.collections" :key="key">
            <div class="flex">
              <div class="w-1/2">
                <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Amount</span>
                <span class="text-content text-xs font-medium block mb-3">{{ priceinLacs(c.amount) | asINR }}</span>
                <div class="">
                  <span class="text-content text-xs text-opacity-50  mr-1">Date</span><span
                    class="text-xs text-content font-medium">{{ c.created_at | dateFormat }}</span>
                </div>
                <div class="">
                  <span class="text-content text-xs text-opacity-50  mr-1">Type</span><span
                    class="text-xs text-content font-medium">{{ c.payment_type }}</span>
                </div>
              </div>
              <div class="w-1/2">
                <div class="flex justify-between items-center">
                  <span class="text-content text-xs text-opacity-50 ">GST</span>
                  <span class="bg-success text-white text-xxs font-medium px-3 py-1	 rounded-full">{{ c.status }}</span>
                </div>

                <span class="text-main text-xs font-medium block mb-3">{{ priceinLacs(c.tax_amount) | asINR }}</span>
                <div class="">
                  <span class="text-content text-xs text-opacity-50  mr-1">Mode</span><span
                    class="text-xs text-content font-medium">{{ c.payment_mode }}</span>
                </div>

              </div>
            </div>
          </div>
          <div class="fixed w-full bottom-0 left-0 bg-white">
            <div class="flex items-center p-4 shadow-top">
              <div class="w-full">
                <router-link :to="{ name: 'Collection', params: { uuid: this.$route.params.uuid } }"
                  class="flex items-center w-full justify-center bg-primary text-white px-6 py-4 rounded-full text-base font-medium">
                  Add Collection
                </router-link>
              </div>
            </div>
          </div>
        </tab>
      </tabs>
    </div>
  </div>
</template>
<script>
import { numDifferentiation } from '@/assets/scripts/utility.js';
export default {
  data() {
    return {
      agreementDetails: {},
    }
  },
  created() {
    this.loadAgreementDetails()
  },
  methods: {
    deleteAgreement() {
      swal({
        title: "Are you sure?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.$axios
            .delete(`mobile_crm/agreements/${this.$route.params.uuid}/delete`)
            .then((res) => {
              this.$router.replace({ name: "Agreement" });
              swal({
                title: "Agreement Deleted!",
                icon: "success",
              });
            })
            .catch((err) => {
              if (err.response.status === 500) {
                swal({
                  title: "Server Error!",
                  icon: "error",
                });
              } else {
                swal({
                  title: "Error",
                  text: err.response.data.message,
                  icon: "error",
                });
              }
            });
        }
      });
    },
    priceinLacs(value) {
      return numDifferentiation(value)
    },
    loadAgreementDetails() {
      this.$axios.get(`mobile_crm/agreements/${this.$route.params.uuid}`)
        .then((res) => {
          this.agreementDetails = res.data.agreement
        })
        .catch((err) => console.log(err));
    }
  }
}
</script>
<style scoped>
</style>