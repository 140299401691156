<template>
      <form @submit.prevent="!enquiry.uuid ? submitFn() : editSubmitFn()">
        <div class="mb-10">
          <label for="" class="text-main text-opacity-50 text-xs block mb-5 ml-4">Property For</label>
          <div class="flex">
            <span class="radio-selection">
              <input type="radio" v-model="enquiry.transaction_type_id" name="propertyFor" value="Sale" id="Sale" class="hidden">
              <label for="Sale" class="bg-divider py-3 px-6 text-content rounded-full font-medium pills mr-3"> Sale </label>
            </span>
             <span class="radio-selection">
              <input type="radio" v-model="enquiry.transaction_type_id" name="propertyFor" value="Rental" id="Rental" class="hidden">
              <label for="Rental" class="bg-divider py-3 px-6 text-content rounded-full font-medium pills"> {{ leadKeysList.custom_label_property_for ? 'Resale' : 'Rental' }} </label>
            </span>
          </div>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Building Type</label>
          <select v-model="enquiry.building_type" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select the type</option>
            <option
              v-for="(bt, key) in propertyKeysList.building_types"
              :key="key"
              :value="bt"
            >{{bt}}</option>
          </select>
        </div>
         <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Property Type</label>
          <select v-model="enquiry.property_type" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select Property Type</option>
            <option
              v-for="(pt, key) in propertyType"
              :key="key"
              :value="pt"
            >{{pt}}</option>
          </select>
        </div>
        <div class="mb-6 relative" v-if="lead_allowed_fields.includes('preferred_project_id')">
          <label for=""
            class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white z-10">Prefered Project</label>
              <multiselect v-model="enquiry.preferred_project_id" @input="getProperty()" :searchable="true"  placeholder="Select Prefered Project" label="text" track-by="id" :options="propertyKeysList.projects" :multiple="false" :taggable="true" class="multiselect-outer-wrapper"></multiselect>
        </div>
        <div class="mb-6 relative" v-if="lead_allowed_fields.includes('preferred_property_id')">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Prefered Property</label>
          <select v-model="enquiry.preferred_property_id" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select Property Type</option>
            <option
              v-for="(pr, key) in propertyList"
              :key="key"
              :value="pr.id"
            >{{pr.name}}</option>
          </select>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Configuration</label>
          <select v-model="enquiry.configuration" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select Configuration</option>
           <option
              v-for="(c, key) in leadKeysList.configuration"
              :key="key"
              :value="c"
            >{{c}}</option>
          </select>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Name</label>
          <input type="text" v-model="enquiry.name" placeholder="Name" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Email</label>
          <input type="email" v-model="enquiry.email" placeholder="Email" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Mobile</label>
          <input type="number" v-model="enquiry.mobile" placeholder="Mobile No" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
         <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Possession by</label>
          <select v-model="enquiry.possession_by" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select Possession By</option>
           <option
              v-for="(p, key) in leadKeysList.possession_by"
              :key="key"
              :value="p"
            >{{p}}</option>
          </select>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Possession Status</label>
          <select v-model="enquiry.possession_status" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select</option>
            <option
              v-for="(c, key) in propertyKeysList.posesstion_status"
              :key="key"
              :value="c"
            >{{c}}</option>
          </select>
        </div>
         <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Expected Price</label>
          <input type="text" v-model="enquiry.budget" placeholder="Enter in lacs" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mb-6 relative">
         <label for=""
            class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white z-10">City</label>
           <multiselect v-model="enquiry.city_id" :searchable="true"  placeholder="Select City" label="name" track-by="id" :options="leadKeysList.cities" :multiple="false" :taggable="true" class="multiselect-outer-wrapper"></multiselect>
        </div>
        <div class="mb-6 relative">
          <label for=""
            class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white z-10">Localities</label>
              <multiselect v-model="enquiry.locality_ids" :searchable="true"  placeholder="Select Localities" label="name" track-by="id" :options="Localities" :multiple="true" :taggable="true" class="multiselect-outer-wrapper"></multiselect>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Status *</label>
          <select v-model="enquiry.status_id" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select Status</option>
            <option
              v-for="(s, key) in statusList"
              :key="key"
              :value="s.id"
            >{{s.name}}</option>
          </select>
        </div>
        <div class="mb-6 relative" v-if="meeting_executive">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Closing Executive</label>
          <select v-model="enquiry.closing_executive" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select Closing Executive</option>
           <option
              v-for="(ce, key) in leadKeysList.closing_executives"
              :key="key"
              :value="ce.id"
            >{{ce.name}}</option>
          </select>
        </div>
        <div class="mb-6 relative" v-if="plan_ids.includes(enquiry.status_id)">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Tentitive visit plan date</label>
          <input type="datetime-local" v-model="enquiry.tentative_visit_planned" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mb-6 relative" v-if="deadStatusIds.includes('' + enquiry.status_id)">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Dead Reason</label>
          <select v-model="enquiry.dead_reason_id" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select Dead Reason</option>
            <option
              v-for="(s, key) in deadReasons"
              :key="key"
              :value="s.id"
            >{{s.reason}}</option>
          </select>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Sources *</label>
          <select v-model="enquiry.source_id" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select Sources</option>
            <option
              v-for="(s, key) in sourceList"
              :key="key"
              :value="s.id"
            >{{s.name}}</option>
          </select>
        </div>
        <div class="mb-6 relative" v-if="cpSourceIds.includes(enquiry.source_id)">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Channel Partner *</label>
          <select v-model="enquiry.broker_id" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select Channel Partner</option>
            <option
              v-for="(s, key) in cpList"
              :key="key"
              :value="s.id"
            >{{s.name}}</option>
          </select>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">NCD</label>
          <input type="datetime-local" v-model="enquiry.ncd" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Select Assigned To</label>
          <select v-model="enquiry.user_id" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select Users</option>
            <option
              v-for="(s, key) in userList"
              :key="key"
              :value="s.id"
            >{{s.name}}</option>
          </select>
        </div>
        <div class="mt-10">
					<button type="submit" :disabled="!showProceed" class="bg-primary text-base font-semibold text-white rounded-full p-5 w-full disabled:opacity-50" >Next</button>
				</div>
      </form>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
  components: {
    Multiselect
  },
  props: {
    enquiry: {
      type: Object,
      required: true
    },
    submitFn: {
      type: Function,
    },
    getProperty: {
      type: Function,
    },
    propertyList: {
      type: Array,
    },
    editSubmitFn: {
      type: Function,
    }
  },
  computed: {
    showProceed() {
      if (this.enquiry.status_id && this.enquiry.source_id) {
        return true
      }
    },
    propertyKeysList(){
      return this.$store.getters.propertyKeys;
    },
    propertyType() {
      if (this.enquiry.building_type === 'Residential') {
        return this.propertyKeysList.residential_property_type
      } else if (this.enquiry.building_type === 'Commercial') {
        return this.propertyKeysList.commercial_property_type
      } else {
        return []
      }
    },
    validationMsg(){
      return validationMessage(formMessages);
    },
    leadKeysList(){
      return this.$store.getters.leadKeys;
    },
    Localities() {
      if (this.enquiry.city_id) {
        return this.leadKeysList.locality.filter(l => l.city_id == this.enquiry.city_id.id)
      } else {
        return []
      }
    },
    projectList(){
      return this.$store.getters.projectMaster;
    },
    userList() {
      return this.$store.getters.usersMaster;
    },
    sourceList(){
      return this.$store.getters.sourcesMaster;
    },
    statusList(){
      return this.$store.getters.statusesMaster;
    },
    deadReasons() {
      return this.$store.getters.deadReasons;
    },
    deadStatusIds() {
      return this.$store.getters.deadStatusIds;
    },
    lead_allowed_fields() {
      return this.$store.getters.lead_allowed_fields;
    },
    propertyKeysList(){
      return this.$store.getters.propertyKeys;
    },
    cpSourceIds() {
      return this.$store.getters.cpIds;
    },
    plan_ids() {
      return this.$store.getters.plan_ids;
    },
    cpList() {
      return this.$store.getters.channel_partners;
    },
    meeting_executive() {
      return this.$store.getters.meeting_executive;
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>