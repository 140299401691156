import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    layoutName: 'default',
    isLoading: true,
    usersMaster: [],
    projectMaster: [],
    propertiesMaster: [],
    leadKeys: {},
    propertyKeys: {},
    agreementKeys: {},
    sourcesMaster: [],
    statusesMaster: [],
    deadStatusIds: [],
    bookingDoneIds: [],
    deadReasons: [],
    lead_allowed_fields: [],
    newEnquiryData: null,
    newPropertyData: null,
    newAgreementData: null,
    advSParams: {},
    cpSourceIds: [],
    channel_partners: [],
    plan_ids: [],
    hot_status_ids: [],
    meeting_executive: '',
    created_user: ''
  },
  mutations: {
    setLayout(state, layout){
      state.layoutName = layout
    },
    setLoading(state, isLoading){
      state.isLoading = isLoading
    },
    setUsers(state, user){
      state.usersMaster = user
    },
    setProjects(state, project){
      state.projectMaster = project
    },
    setProperties(state, properties){
      state.propertiesMaster = properties
    },
    setLeadKeys(state, leadkeys){
      state.leadKeys = leadkeys
    },
    setPropertyKeys(state, property_keys){
      state.propertyKeys = property_keys
    },
    setAgreementKeys(state, agreement_keys){
      state.agreementKeys = agreement_keys
    },
    setSourcesMaster(state, sources){
      state.sourcesMaster = sources
    },
    setStatusesMaster(state, statuses){
      state.statusesMaster = statuses
    },
    setDeadStatusIds(state, statusIds){
      state.deadStatusIds = statusIds
    },
    setBDStatusIds(state, statusIds){
      state.bookingDoneIds = statusIds
    },
    setDeadReasons(state, reasons){
      state.deadReasons = reasons;
    },
    setLeadAllowedFields(state, allowed_fields){
      state.lead_allowed_fields = allowed_fields;
    },
    setnewEnquiryData(state, enquiryData){
      state.newEnquiryData = enquiryData
    },
    setnewPropertyData(state, propertyData){
      state.newPropertyData = propertyData
    },
    setnewAgreementData(state, agreementData){
      state.newAgreementData = agreementData
    },
    setAdvSParams(state, sParams){
      state.advSParams = sParams;
    },
    setCPSourceIds(state, cpids){
      state.cpSourceIds = cpids;
    },
    setChannelPartners(state, cp){
      state.channel_partners = cp;
    },
    setPlanIds(state, pids){
      state.plan_ids = pids;
    },
    setHotStatusIds(state, hot_ids){
      state.hot_status_ids = hot_ids;
    },
    setMeetingExecutive(state, meeting_exe){
      state.meeting_executive = meeting_exe;
    },
    setCreatedUser(state, created_usr){
      state.created_user = created_usr;
    },
  },
  actions: {
    setLayout({commit}, layout){
      commit('setLayout', layout);
    },
    setLoading({commit}, isLoading){
      commit('setLoading', isLoading);
    },
    setUsers({commit}, user){
      commit('setUsers', user)
    },
    setProjects({commit}, project){
      commit('setProjects', project)
    },
    setProperties({commit}, properties){
      commit('setProperties', properties)
    },
    setLeadKeys({commit}, leadkeys){
      commit('setLeadKeys', leadkeys)
    },
    setPropertyKeys({commit}, property_keys){
      commit('setPropertyKeys', property_keys)
    },
    setAgreementKeys({commit}, agreement_keys){
      commit('setAgreementKeys', agreement_keys)
    },
    setSourcesMaster({commit}, sources){
      commit('setSourcesMaster', sources)
    },
    setStatusesMaster({commit}, statuses){
      commit('setStatusesMaster', statuses)
    },
    setDeadStatusIds({commit}, statusIds){
      commit('setDeadStatusIds', statusIds)
    },
    setBDStatusIds({commit}, statusIds){
      commit('setBDStatusIds', statusIds)
    },
    setDeadReasons({commit}, reasons){
      commit('setDeadReasons', reasons);
    },
    setnewEnquiryData({commit}, enquiryData){
      commit('setnewEnquiryData', enquiryData);
    },
    setnewPropertyData({commit}, propertyData){
      commit('setnewPropertyData', propertyData);
    },
    setnewAgreementData({commit}, agreementData){
      commit('setnewAgreementData', agreementData);
    },
    setAdvSParams({commit}, sParams){
      commit('setAdvSParams', sParams);
    },
    setLeadAllowedFields({commit}, allowed_fields){
      commit('setLeadAllowedFields', allowed_fields);
    },
    setCPSourceIds({commit}, cpids){
      commit('setCPSourceIds', cpids);
    },
    setChannelPartners({commit}, cp){
      commit('setChannelPartners', cp);
    },
    setPlanIds({commit}, pids){
      commit('setPlanIds', pids);
    },
    setHotStatusIds({commit}, hot_ids){
      commit('setHotStatusIds', hot_ids);
    },
    setMeetingExecutive({commit}, meeting_exe){
      commit('setMeetingExecutive', meeting_exe);
    },
    setCreatedUser({commit}, created_usr){
      commit('setCreatedUser', created_usr);
    },
  },
  getters: {
    layout(state){
      return state.layoutName;
    },
    isLoading(state){
      return state.isLoading;
    },
    usersMaster(state){
      return state.usersMaster;
    },
    projectMaster(state){
      return state.projectMaster;
    },
    propertiesMaster(state){
      return state.propertiesMaster;
    },
    leadKeys(state){
      return state.leadKeys;
    },
    propertyKeys(state){
      return state.propertyKeys;
    },
    agreementKeys(state){
      return state.agreementKeys;
    },
    sourcesMaster(state){
      return state.sourcesMaster;
    },
    statusesMaster(state){
      return state.statusesMaster;
    },
    deadStatusIds(state){
      return state.deadStatusIds;
    },
    bookingDoneIds(state){
      return state.bookingDoneIds;
    },
    deadReasons(state){
      return state.deadReasons;
    },
    newEnquiryData(state){
      return state.newEnquiryData;
    },
    newPropertyData(state){
      return state.newPropertyData;
    },
    newAgreementData(state){
      return state.newAgreementData;
    },
    advSParams(state){
      return state.advSParams;
    },
    lead_allowed_fields(state){
      return state.lead_allowed_fields;
    },
    cpIds(state){
      return state.cpSourceIds;
    },
    channel_partners(state){
      return state.channel_partners;
    },
    plan_ids(state){
      return state.plan_ids;
    },
    hot_status_ids(state){
      return state.hot_status_ids;
    },
    meeting_executive(state){
      return state.meeting_executive;
    },
    created_user(state){
      return state.created_user;
    },
  }
})
