export function decodeSortKey(key){
  if(key === "ncd"){
    return "ncd";
  }
  return key;
}
export function prepareUpdateParams(inputParams){
  let toSend = {}
  let visits = []
  if(inputParams.visitDate){
    let visitObj = {}
    visitObj['date'] = inputParams.visitDate
    visitObj['comment'] = inputParams.visitComment
    if (inputParams.projects) {
      visitObj['project_ids'] = inputParams.projects.map(p => p.id)
    }
    if (inputParams.properties) {
      visitObj['property_ids'] = inputParams.properties.map(p => p.id)
    }
    visits.push(visitObj)
  }
  if(visits.length > 0){
    toSend['visits_attributes'] = visits
  }
  return toSend
}
export function prepareEnquiryParams(enquiryParams){
  let senddata = {}
  if (enquiryParams.uuid) {
    senddata['uuid'] = enquiryParams.uuid
    senddata['id'] = enquiryParams.id
  }
  senddata['transaction_type_id'] = enquiryParams.transaction_type_id
  senddata['building_type'] = enquiryParams.building_type
  senddata['property_type'] = enquiryParams.property_type
  senddata['configuration'] = enquiryParams.configuration
  senddata['name'] = enquiryParams.name
  senddata['email'] = enquiryParams.email
  senddata['mobile'] = enquiryParams.mobile
  senddata['possession_by'] = enquiryParams.possession_by
  senddata['budget'] = enquiryParams.budget
  if (enquiryParams.locality_ids) {
    senddata['locality_ids'] = enquiryParams.locality_ids.map(i => i.id)
  }
  if (enquiryParams.city_id) {
    senddata['city_id'] = enquiryParams.city_id.id
  }
  senddata['status_id'] = enquiryParams.status_id
  senddata['dead_reason_id'] = enquiryParams.dead_reason_id
  senddata['source_id'] = enquiryParams.source_id
  senddata['broker_id'] = enquiryParams.broker_id
  senddata['closing_executive'] = enquiryParams.closing_executive
  senddata['ncd'] = enquiryParams.ncd
  senddata['tentative_visit_planned'] = enquiryParams.tentative_visit_planned
  senddata['user_id'] = enquiryParams.user_id
  if (enquiryParams.preferred_project_id) {
    senddata['preferred_project_id'] = enquiryParams.preferred_project_id.id
  }
  senddata['possession_status'] = enquiryParams.possession_status
  senddata['preferred_property_id'] = enquiryParams.preferred_property_id
  return senddata;
}

export function prepareNextEnquiryParams(enquiryParams){
  let senddata = {}
  senddata['require_furnishing'] = enquiryParams.require_furnishing
  senddata['no_of_bathroom'] = enquiryParams.no_of_bathroom
  senddata['no_of_parkings'] = enquiryParams.no_of_parkings
  senddata['floor_range'] = enquiryParams.floor_range
  senddata['facing_direction'] = enquiryParams.facing_direction
  senddata['comment'] = enquiryParams.comment
  return senddata;
}

export function prepareSubmitPropertyParams(propertyParams){
  let senddata = {}
  if (propertyParams.brochure) {
    senddata['brochure'] = propertyParams.brochure
  }
  senddata['furnishing_type_id'] = propertyParams.furnishing_type_id
  senddata['facing'] = propertyParams.facing
  senddata['watersource'] = propertyParams.watersource
  senddata['no_of_floors'] = propertyParams.no_of_floors
  senddata['no_of_balcony'] = propertyParams.no_of_balcony
  senddata['no_of_terrace'] = propertyParams.no_of_terrace
  senddata['no_of_utility_room'] = propertyParams.no_of_utility_room
  senddata['bathroom'] = propertyParams.bathroom
  senddata['no_of_kitchen'] = propertyParams.no_of_kitchen
  senddata['no_of_workstations'] = propertyParams.no_of_workstations
  senddata['no_of_cabins'] = propertyParams.no_of_cabins
  senddata['no_of_server_rooms'] = propertyParams.no_of_server_rooms
  senddata['no_of_meeting_room'] = propertyParams.no_of_meeting_room
  senddata['no_of_cubicles'] = propertyParams.no_of_cubicles
  senddata['no_of_conference_room'] = propertyParams.no_of_conference_room
  senddata['no_of_seaters'] = propertyParams.no_of_seaters
  senddata['frontage'] = propertyParams.frontage
  senddata['height'] = propertyParams.height
  senddata['electricity'] = propertyParams.electricity
  senddata['parking'] = propertyParams.parking
  senddata['amenity_ids'] = propertyParams.amenity_ids
  senddata['comment'] = propertyParams.comment
  senddata['external_comments'] = propertyParams.external_comments
  return senddata;
}
export function preparePropertyParams(propertyParams){
  let senddata = {}
  if (propertyParams.uuid) {
    senddata['uuid'] = propertyParams.uuid
    senddata['id'] = propertyParams.id
  }
  if (propertyParams.broker_id) {
    senddata['broker_id'] = propertyParams.broker_id
  }
  senddata['priority'] = propertyParams.priority
  senddata['project_id'] = propertyParams.project_id
  senddata['property_address'] = propertyParams.property_address
  senddata['property_for'] = propertyParams.property_for
  senddata['property_from'] = propertyParams.property_from
  senddata['building_type'] = propertyParams.building_type
  senddata['property_type'] = propertyParams.property_type
  senddata['configuration'] = propertyParams.configuration
  senddata['owner'] = propertyParams.owner
  senddata['deposit_amount'] = propertyParams.deposit_amount
  senddata['owner_contact'] = propertyParams.owner_contact
  senddata['other_contacts'] = propertyParams.other_contacts
  senddata['status'] = propertyParams.status
  senddata['project_name'] = propertyParams.project_name
  senddata['landmark'] = propertyParams.landmark
  senddata['posession_status'] = propertyParams.posession_status
  senddata['property_source_id'] = propertyParams.property_source_id
  senddata['area_unit'] = propertyParams.area_unit
  senddata['builtup_area'] = propertyParams.builtup_area
  senddata['carpet_area'] = propertyParams.carpet_area
  senddata['property_age'] = propertyParams.property_age
  senddata['property_approve_by'] = propertyParams.property_approve_by
  senddata['expected_price'] = propertyParams.expected_price
  senddata['car_parking_charge'] = propertyParams.car_parking_charge
  senddata['base_cost_rate'] = propertyParams.base_cost_rate
  senddata['maintenance_cost'] = propertyParams.maintenance_cost
  senddata['rera_no'] = propertyParams.rera_no
  senddata['project_name'] = propertyParams.project_name
  senddata['lat'] = propertyParams.lat
  senddata['long'] = propertyParams.long
  senddata['city_id'] = propertyParams.city_id
  senddata['locality_id'] = propertyParams.locality_id
  senddata['possession_date'] = propertyParams.possession_date
  senddata['referal_name'] = propertyParams.referal_name
  senddata['referal_mobile'] = propertyParams.referal_mobile
  if (propertyParams.amenity_ids) {
    senddata['amenity_ids'] = propertyParams.amenity_ids
  }
  return senddata;
}

export function prepareAgreementParams(agreementParams){
  let senddata = {}
  senddata['transaction_type'] = agreementParams.transaction_type
  senddata['agreement_type_id'] = agreementParams.agreement_type_id
  senddata['configuration'] = agreementParams.configuration
  if (agreementParams.property_id) {
    senddata['property_id'] = agreementParams.property_id
  }
  senddata['owner'] = agreementParams.owner
  senddata['buyer_name'] = agreementParams.buyer_name
  senddata['broker_id'] = agreementParams.broker_id
  senddata['start_date'] = agreementParams.start_date
  senddata['end_date'] = agreementParams.end_date
  return senddata;
}

export function updateCollectionParams(collectionParams){
  let toSend = {}
  let collectionattributes = {}
  let collection = []
  collectionattributes['payment_type'] = collectionParams.payment_type
  collectionattributes['payment_mode'] = collectionParams.payment_mode
  collectionattributes['amount'] = collectionParams.amount
  collectionattributes['tax_amount'] = collectionParams.tax_amount
  collectionattributes['status'] = collectionParams.status
  collection.push(collectionattributes)
  toSend['collections_attributes'] = collection
  return toSend;
}

export function prepareEnquirySearchParams(advSParams){
  let toSend = {}
  toSend['ss_id'] = advSParams.ss_id
  toSend['name'] = advSParams.name
  toSend['mobile'] = advSParams.mobile
  toSend['email'] = advSParams.email
  toSend['transaction_type_id'] = advSParams.transaction_type_id
  toSend['building_type'] = advSParams.building_type
  toSend['property_type'] = advSParams.property_type
  toSend['configuration'] = advSParams.configuration
  toSend['furnishing'] = advSParams.furnishing
  toSend['set_search'] = advSParams.set_search
  toSend['search_name'] = advSParams.search_name
  if (advSParams.dead_reason_id) {
    toSend['dead_reason_ids'] = [advSParams.dead_reason_id]
  }

  if (advSParams.lead_statuses) {
    toSend['lead_statuses'] = advSParams.lead_statuses.map(i => i.id)
  }
  if (advSParams.source_id) {
    toSend['source_id'] = advSParams.source_id.map(i => i.id)
  }
  if (advSParams.project_ids) {
    toSend['project_ids'] = advSParams.project_ids.map(i => i.id)
  }
  if (advSParams.property_ids) {
    toSend['property_ids'] = advSParams.property_ids.map(i => i.id)
  }
  if (advSParams.closing_executive) {
    toSend['closing_executive'] = advSParams.closing_executive.map(i => i.id)
  }
  toSend['created_at_from'] = advSParams.created_at_from
  toSend['created_at_upto'] = advSParams.created_at_upto
  toSend['city_ids'] = advSParams.city_ids
  toSend['locality_ids'] = advSParams.locality_ids.map(i => i.id)
  if (advSParams.user_id) {
    toSend['assigned_to'] = advSParams.user_id.map(i => i.id)
  }
  if (advSParams.broker_ids) {
    toSend['broker_ids'] = advSParams.broker_ids.map(i => i.id)
  }
  if (advSParams.locality_id) {
    toSend['locality_ids'] = [advSParams.locality_id]
    toSend['budget_from'] = advSParams.budget_from
    toSend['transaction_type_id'] = advSParams.transaction_type_id
    toSend['match'] = advSParams.match
    toSend['as'] = true
  }
  return toSend;
}

export function prepareProjectSearchParams(advSParams){
  let toSend = {}
  toSend['name'] = advSParams.name
  toSend['created_from'] = advSParams.created_from
  toSend['created_upto'] = advSParams.created_upto
  return toSend;
}

export function preparePropertiesSearchParams(advSParams){
  let toSend = {}
  toSend['owner'] = advSParams.owner
  toSend['property_for'] = advSParams.property_for
  toSend['property_from'] = advSParams.property_from
  toSend['project_name'] = advSParams.project_name
  toSend['building'] = advSParams.building
  toSend['configuration_id'] = advSParams.configuration_id
  toSend['property_type_id'] = advSParams.property_type_id
  toSend['posession_status'] = advSParams.posession_status
  toSend['status'] = advSParams.status
  toSend['city_id'] = advSParams.city_ids
  toSend['locality_ids'] = advSParams.locality_ids.map(i => i.id)
  if (advSParams.project_ids) {
    toSend['project_ids'] = advSParams.project_ids.map(i => i.id)
  }
  if (advSParams.lead_id) {
    toSend['lead_id'] = advSParams.lead_id
    toSend['match'] = advSParams.match
  }
  return toSend;
}

export function prepareAgreementSearchParams(advSParams){
  let toSend = {}
  if ( advSParams.property_id) {
    toSend['property_id'] = advSParams.property_id.id
  }
  toSend['owner'] = advSParams.owner
  toSend['buyer_name'] = advSParams.buyer_name
  toSend['transaction_type'] = advSParams.transaction_type
  toSend['agreement_date_from'] = advSParams.agreement_date_from
  toSend['agreement_date_upto'] = advSParams.agreement_date_upto
  return toSend;
}

export function prepareExpiringLeaseSearchParams(advSParams){
  let toSend = {}
  toSend['buyer_name'] = advSParams.buyer_name
  toSend['transaction_type'] = advSParams.transaction_type
  toSend['agreement_date_from'] = advSParams.agreement_date_from
  toSend['agreement_date_upto'] = advSParams.agreement_date_upto
  return toSend;
}

export function paramsFromQuery(mainParams, queryParams, status){
  let bs = queryParams.bs
  let lead_id = queryParams.lead_id
  let locality_id = queryParams.locality_id
  let sortKey = queryParams.sortKey;
  let sortDir = queryParams.sortDir;
  let page = queryParams.pageNo
  if(status){
    if (status.length >= 1) {
      if (typeof status === 'string') {
        mainParams['lead_statuses'] = [status]
      } else {
        mainParams['lead_statuses'] = status
      }
    } else {
      mainParams['lead_statuses'] = [status]
    }
    mainParams['as'] = true
  }
  if(bs){
    mainParams['bs'] = bs;
  }
  if (lead_id) {
    mainParams['lead_id'] = lead_id;
    mainParams['match'] = true
    mainParams['as'] = true
  }
  if (locality_id) {
    mainParams['locality_ids'] = locality_id;
    mainParams['budget_from'] = queryParams.budget_from;
    mainParams['budget_upto'] = queryParams.budget_upto;
    mainParams['transaction_type_id'] = queryParams.transaction_type_id;
    mainParams['match'] = true
    mainParams['as'] = true
  }
  if(sortKey){
    mainParams['key'] = decodeSortKey(sortKey)
    mainParams['sort'] = sortDir || 'asc'
  }
  if(page){
    mainParams['page'] = page
  }
}
export function numDifferentiation(value) {
  var val = Math.abs(value)
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + ' Cr';
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + ' Lac';
  } else if (val >= 1000) {
    val = (val / 1000).toFixed(2) + ' K';
  }
  return val;
}
export function waFormatter(num){
  if(!num){
    return false
  }
  let newNum = num.split(' ').join('').replace("+",'');
  if(newNum.length > 10){
    return newNum
  }else if(newNum.length === 10){
    return `91${newNum}`
  }else{
    return false
  }
}