<template>
  <div class="pt-20 mt-2 bg-white">
    <div class="bg-white  p-4">
      <form @submit.prevent="Agreement.uuid ? editSubmitFn() : submitFn()">
        <div class="mb-6 relative">
          <label for=""
            class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white z-10">Property
            *</label>
          <multiselect v-model="Agreement.property_id" :searchable="true" :placeholder="propertyNoInput"
            label="project_name" track-by="id" @input="selectProperty(Agreement.property_id)" :options="propertiesList"
            :taggable="true" @search-change="suggestProperty" class="multiselect-outer-wrapper"></multiselect>
        </div>
        <div class="mb-10">
          <label for="" class="text-main text-opacity-50 text-xs block mb-5 ml-4">Transaction Type *</label>
          <div class="flex">
            <span class="radio-selection">
              <input type="radio" v-model="Agreement.transaction_type" name="propertyFor" value="Sale" id="Sale"
                class="hidden">
              <label for="Sale" class="bg-divider py-3 px-6 text-content rounded-full font-medium pills mr-3"> Sale
              </label>
            </span>
            <span class="radio-selection">
              <input type="radio" v-model="Agreement.transaction_type" name="propertyFor" value="Rental" id="Rental"
                class="hidden">
              <label for="Rental" class="bg-divider py-3 px-6 text-content rounded-full font-medium pills"> Rental
              </label>
            </span>
          </div>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Type
            *</label>
          <select v-model="Agreement.agreement_type_id" required
            class="border border-inputBorder round-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select Type</option>
            <option v-for="(a, key) in agreementKeysList.agreement_type_id" :key="key" :value="a">{{ a }}</option>
          </select>
        </div>
        <div class="mb-6 relative">
          <label for=""
            class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Configuration</label>
          <select v-model="Agreement.configuration"
            class="border border-inputBorder round-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select Configuration</option>
            <option v-for="(c, key) in agreementKeysList.configuration" :key="key" :value="c">{{ c }}</option>
          </select>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Owner
            Name</label>
          <input type="text" v-model="Agreement.owner" placeholder="Owner Name"
            class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Buyer
            Name</label>
          <input type="text" v-model="Agreement.buyer_name" placeholder="Buyer Name"
            class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>

        <div class="mb-6 relative">
          <label for=""
            class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Broker</label>
          <select v-model="Agreement.broker_id"
            class="border border-inputBorder round-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select Broker</option>
            <option v-for="(b, key) in agreementKeysList.brokers" :key="key" :value="b.id">{{ b.name }}</option>
          </select>
        </div>
        <div class="mb-6 relative">
          <label for=""
            class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Agreement Date
            *</label>
          <input type="date" v-model="Agreement.start_date" required
            class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>

        <div class="mb-6 relative">
          <label for=""
            class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Agreement
            Till</label>
          <input type="date" v-model="Agreement.end_date"
            class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mt-10">
          <button type="submit" :disabled="!showProceed"
            class="bg-primary text-base font-semibold text-white rounded-full p-5 w-full disabled:opacity-50">Next</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  components: {
    Multiselect
  },
  props: {
    Agreement: {
      type: Object,
      required: true
    },
    suggestProperty: {
      type: Function,
      required: true
    },
    submitFn: {
      type: Function,
    },
    editSubmitFn: {
      type: Function,
    },
    propertiesList: {
      type: Array
    },
    selectProperty: {
      type: Function,
    }
  },
  data() {
    return {
      propertyNoInput: 'Start Typing....',
    }
  },
  computed: {
    showProceed() {
      if (this.Agreement.property_id && this.Agreement.transaction_type && this.Agreement.agreement_type_id
        && this.Agreement.start_date) {
        return true
      }
    },
    agreementKeysList() {
      return this.$store.getters.agreementKeys;
    },
  }
}
</script>

<style>
</style>