<template>
	<div class="animate_animated animate__slideInRight">
		<div class="fixed w-full top-0 left-0 z-20">
		<div class="flex bg-white px-4 py-6 items-center shadow-ct">
			<div class="w-full">
				<div class="flex items-center w-full">
					<a @click="$router.go(-1)"><img src="@/assets/back-arrow.svg" alt=""></a>
					<span class="text-base text-content font-medium ml-4">New Property</span>
				</div>
			</div>
		</div>
    </div>
    <div class="pt-20 mt-2 bg-white">
    <div class="bg-white min-h-screen p-4">
      <Property :property="AddNewProperty" :submitFn="goToStep1" />
    </div>
  </div>
	</div>
</template>
<script>
import Property from '@/components/Property/New.vue';
import {preparePropertyParams} from '@/assets/scripts/utility.js';
export default {
  components: {
    Property
  },
  data () {
    return {
      AddNewProperty: {
        property_for: null,
        property_from: null,
        broker_id: null,
        property_type: null,
        configuration: null,
        owner: null,
        deposit_amount: null,
        owner_contact: null,
        other_contacts: null,
        status: null,
        project_name: null,
        landmark: null,
        posession_status: null,
        area_unit: null,
        builtup_area: null,
        carpet_area: null,
        property_age: null,
        property_approve_by: null,
        expected_price: null,
        car_parking_charge: null,
        base_cost_rate: null,
        maintenance_cost: null,
        rera_no: null,
        lat: null,
        long: null,
        referal_name: null,
        referal_mobile: null,
      },
    }
  },
  created() {
    let step0_data = this.$store.getters.newPropertyData
    if (step0_data) {
      this.AddNewProperty = step0_data
    }
  },
  methods: {
    goToStep1(){
      let apiParams = {}
      apiParams = preparePropertyParams(this.AddNewProperty);
      let newPropertyData = apiParams
      this.$store.dispatch('setnewPropertyData', newPropertyData);
      this.$router.push({name: 'NextProperty'});
    },
  }
}
</script>
<style scoped>

</style>