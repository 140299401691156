<template>
  <div>
    <div class="fixed w-full top-0 left-0 z-20">
      <div class="flex bg-white px-4 py-6 items-center shadow-ct">
        <div class="w-full">
          <div class="flex items-center w-full">
            <a @click="$router.go(-1)"><img src="@/assets/back-arrow.svg" alt=""></a>
            <span class="text-base text-content font-medium ml-4">Enquiry Details</span>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-16 mt-4  pb-20">
      <div class="p-5 bg-white mb-3">
        <h3 class="text-primary font-semibold text-xs uppercase tracking-ct mb-4">Contact Details</h3>
        <div class="grid gap-1 grid-cols-2">
          <div>
            <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Name</span>
            <span class="text-content text-xs font-medium block mb-3">{{enquiryDetails.name}}</span>
          </div>
          <div class="">
            <div class="flex justify-end">
              <span
                class="text-content text-xs text-opacity-50 flex items-center  bg-grey py-2 px-4 rounded-full text-center  font-medium">{{enquiryDetails.status}}</span>
            </div>
          </div>
        </div>
        <div class="grid gap-1 grid-cols-2">
          <div>
            <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Email</span>
            <a class="text-content text-xs font-medium block mb-3" :href="enquiryDetails.email && `mailto: ${enquiryDetails.email}`">{{enquiryDetails.email || '-'}}</a>
          </div>
          <div>
            <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Mobile</span>
            <div :class="{disabled : !enquiryDetails.mobile}">
            <a class="text-content text-xs font-medium block mb-3 flex item-center" @click="enquiryDetails.ctoc_enabled ? makeCall(enquiryDetails.uuid)  : logCallAttempt(`tel:${enquiryDetails.mobile}`)">
              <div class="icon mr-1 w-3 h-3" style='margin-top:2px;'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <g id="phone-call" transform="translate(-2 -2)">
                    <path
                      id="Path_111"
                      data-name="Path 111"
                      d="M13,8a3,3,0,0,1,3,3,1,1,0,0,0,2,0,5,5,0,0,0-5-5,1,1,0,1,0,0,2Z"
                      fill="var(--mainColor)"
                    />
                    <path
                      id="Path_112"
                      data-name="Path 112"
                      d="M13,4a7,7,0,0,1,7,7,1,1,0,0,0,2,0,9,9,0,0,0-9-9,1,1,0,0,0,0,2Z"
                      fill="var(--mainColor)"
                    />
                    <path
                      id="Path_113"
                      data-name="Path 113"
                      d="M21.75,15.91a1,1,0,0,0-.72-.65l-6-1.37a1,1,0,0,0-.92.26c-.14.13-.15.14-.8,1.38a9.91,9.91,0,0,1-4.87-4.89C9.71,10,9.72,10,9.85,9.85a1,1,0,0,0,.26-.92L8.74,3a1,1,0,0,0-.65-.72,3.79,3.79,0,0,0-.72-.18A3.94,3.94,0,0,0,6.6,2,4.6,4.6,0,0,0,2,6.6,15.42,15.42,0,0,0,17.4,22,4.6,4.6,0,0,0,22,17.4a4.77,4.77,0,0,0-.06-.76A4.34,4.34,0,0,0,21.75,15.91ZM17.4,20A13.41,13.41,0,0,1,4,6.6,2.61,2.61,0,0,1,6.6,4h.33L8,8.64l-.54.28c-.86.45-1.54.81-1.18,1.59a11.85,11.85,0,0,0,7.18,7.21c.84.34,1.17-.29,1.62-1.16l.29-.55L20,17.07v.33A2.61,2.61,0,0,1,17.4,20Z"
                      fill="#0d0500"
                    />
                  </g>
                </svg>
              </div>
              {{enquiryDetails.mobile || '-'}}
            </a>
          </div>
            
          
          </div>
        </div>

        <div class="col-md-12 call-loading-holder">
          <img width="100%" height="auto"  :src="gifCallStatus">
      </div>
      </div>
      <div class="p-5 bg-white mb-2">
        <h3 class="text-primary font-semibold text-xs uppercase tracking-ct mb-4 block">Property Details</h3>
        <div class="grid gap-1 grid-cols-2">
        <div v-if="enquiryDetails.preferred_project_id">
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Preferred Project</span>
          <span class="text-content text-xs font-medium block mb-3">{{enquiryDetails.preferred_project}}</span>
        </div>
        <div v-if="enquiryDetails.preferred_property_id">
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Preferred Property</span>
          <span class="text-content text-xs font-medium block mb-3">{{enquiryDetails.preferred_property}}</span>
        </div>
        <div>
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Property For</span>
          <span class="text-content text-xs font-medium block mb-3">{{leadKeysList.custom_label_property_for & enquiryDetails.transaction_type_id === 'Rental' ? 'Resale' : enquiryDetails.transaction_type_id}}</span>
        </div>
        <div>
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Building Type</span>
          <span class="text-content text-xs font-medium block mb-3">{{enquiryDetails.building_type || '-'}}</span>
        </div>
        <div>
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Property Type</span>
          <span class="text-content text-xs font-medium block mb-3">{{enquiryDetails.property_type || '-'}}</span>
        </div>
        <div>
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Configuration</span>
          <span class="text-content text-xs font-medium block mb-3">{{enquiryDetails.configuration}}</span>
        </div>
        <div>
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Possession by</span>
          <span class="text-content text-xs font-medium block mb-3">{{enquiryDetails.possession_by}}</span>
        </div>
        <div>
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Possession Status</span>
          <span class="text-content text-xs font-medium block mb-3">{{enquiryDetails.possession_status}}</span>
        </div>
        <div>
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Expected Price</span>
          <span class="text-content text-xs font-medium block mb-3">{{priceinLacs(enquiryDetails.budget)}}</span>
        </div>
        <div>
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">City</span>
          <span class="text-content text-xs font-medium block mb-3">{{enquiryDetails.city}}</span>
        </div>
        <div>
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Localities</span>
          <span class="text-content text-xs font-medium block mb-3">{{enquiryDetails.localities}}</span>
        </div>
        <div>
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Sources</span>
          <span class="text-content text-xs font-medium block mb-3">{{enquiryDetails.display_source}}</span>
        </div>
        <div v-if="enquiryDetails.closing_executive">
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Closing Executive</span>
          <span class="text-content text-xs font-medium block mb-3">{{enquiryDetails.closing_executive_name}}</span>
        </div>
        <div v-if="cpSourceIds.includes(enquiryDetails.source_id)">
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Channel Partner</span>
          <span class="text-content text-xs font-medium block mb-3">{{enquiryDetails.broker}}</span>
        </div>
        <div v-if="plan_ids.includes(enquiryDetails.status_id)">
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Tentitive visit plan date</span>
          <span class="text-content text-xs font-medium block mb-3">{{enquiryDetails.formatted_tentative}}</span>
        </div>
        <div>
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">NCD</span>
          <span class="text-content text-xs font-medium block mb-3">{{enquiryDetails.formatted_ncd}}</span>
        </div>
        <div>
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Assigned to</span>
          <span class="text-content text-xs font-medium block mb-3">{{enquiryDetails.user}}</span>
        </div>


        <div>
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Furnishings</span>
          <span class="text-content text-xs font-medium block mb-3">{{enquiryDetails.require_furnishing}}</span>
        </div>
        <div>
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Bathrooms</span>
          <span class="text-content text-xs font-medium block mb-3">{{enquiryDetails.no_of_bathroom}}</span>
        </div>
        <div>
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">No of Parkings</span>
          <span class="text-content text-xs font-medium block mb-3">{{enquiryDetails.no_of_parkings}}</span>
        </div>
        <div>
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Floor Range</span>
          <span class="text-content text-xs font-medium block mb-3">{{enquiryDetails.floor_range}}</span>
        </div>
        <div>
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Facing Direction</span>
          <span class="text-content text-xs font-medium block mb-3">{{enquiryDetails.facing_direction}}</span>
        </div>
        <div v-if="deadStatusIds.includes('' + enquiryDetails.status_id)">
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Dead Reason</span>
          <span class="text-content text-xs font-medium block mb-3">{{enquiryDetails.dead_reason}}</span>
        </div>
        <div v-if="(enquiryDetails.visits || []).length > 0">
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Total Visits</span>
          <span class="text-content text-xs font-medium block mb-3 text-primary" @click="modal_visit_details = true">{{enquiryDetails.visits.length}} Visit(s)</span>
        </div>
        <div v-for="(item,index) in enquiryDetails.magic_field_values" :key="index">
            <div class="col-span-2" v-if="item.value">
            <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">{{item.label_name}}</span>
            <span class="text-content text-xs font-medium block mb-3">{{item.value}}</span>
            </div>
          </div>
        <div class="col-span-2">
          <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Description</span>
          <read-more more-str="read more"  class="text-content text-xs font-medium block mb-3" :text="enquiryDetails.comments" v-if="enquiryDetails.comments" link="#" less-str="read less" :max-chars="50"></read-more>
        </div>
        </div>
      </div>
       <div class="p-5 bg-white mb-2">
        <h3 class="text-primary font-semibold text-xs uppercase tracking-ct mb-4 block">Action</h3>
           <div class="flex">
          <div class="w-full">
            <span class="iconAction flex items-center justify-center border border-primary text-primary  px-6 py-3 rounded-full text-base font-medium bg-primary bg-opacity-10">
              <span class="mr-2 ">
              <svg xmlns="http://www.w3.org/2000/svg" class="fill-current" width="18" height="18" viewBox="0 0 18 18">
                <g id="navigation-2" transform="translate(-2.995 -3.974)">
                  <path
                    id="Path_126"
                    data-name="Path 126"
                    d="M13.627,21.973h-.06a1,1,0,0,1-.917-.8l-1.684-8.188L2.8,11.3a1,1,0,0,1-.12-1.927L18.618,4.05a1,1,0,0,1,1.315,1.268l-5.31,15.976A1,1,0,0,1,13.627,21.973ZM6.852,10.091l5.171,1.058a1,1,0,0,1,.787.779l1.046,5.182L17.363,6.576Z"
                    transform="translate(1 0)"
                    fill=""
                  />
                </g>
              </svg>
              </span>
              <span class="btn" @click="showVisitModal = true">Add Visit</span>
            </span>
          </div>
        </div>
        </div>
          <v-easy-dialog v-model="modal_visit_details" focus-on="#my-input">
            <div class="flex flex-col bg-white p-5 mx-4 overflow-x-scroll">
              <h3 class="text-primary font-semibold text-xs uppercase tracking-ct mb-4 block w-full">Total visit</h3>
              <div v-for="(visit, key) in enquiryDetails.visits" :key="key">
                 <div class="flex  border-b border-main border-opacity-20 mb-5  ">
                    <div class="w-3/4">
                    <div class="col-span-2">
                      <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Project Name</span>
                      <span class="text-content text-xs font-medium block mb-3">{{visit.projects}}</span>
                    </div>
                    <div class="col-span-2">
                      <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Properties Name</span>
                      <span class="text-content text-xs font-medium block mb-3">{{visit.properties}}</span>
                    </div>
                    <div class="col-span-2">
                      <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Date</span>
                      <span class="text-content text-xs font-medium block mb-3">{{visit.date}}</span>
                    </div>
                      <div class="col-span-2">
                        <span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Description</span>
                        <span class="text-content text-xs font-medium block mb-3">{{visit.comment}}</span>
                      </div>

                    </div>
                      <div class="w-1/4 flex justify-end">
                		<span class=" text-main text-opacity-50 "  @click="deleteVisit(visit)"><svg  class="stroke-current " width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M11.8125 3.0625L2.1875 3.06254" stroke="" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M5.6875 5.6875V9.1875" stroke="" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M8.3125 5.6875V9.1875" stroke="" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M10.9375 3.0625V11.375C10.9375 11.491 10.8914 11.6023 10.8094 11.6844C10.7273 11.7664 10.616 11.8125 10.5 11.8125H3.5C3.38397 11.8125 3.27269 11.7664 3.19064 11.6844C3.10859 11.6023 3.0625 11.491 3.0625 11.375V3.0625" stroke="" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M9.1875 3.0625V2.1875C9.1875 1.95544 9.09531 1.73288 8.93122 1.56878C8.76712 1.40469 8.54456 1.3125 8.3125 1.3125H5.6875C5.45544 1.3125 5.23288 1.40469 5.06878 1.56878C4.90469 1.73288 4.8125 1.95544 4.8125 2.1875V3.0625" stroke="" stroke-linecap="round" stroke-linejoin="round"/>
											</svg>
                    </span>
                    </div>
                 </div>
              </div>
              <div class="flex justify-end space-x-2">
                  <button @click="modal_visit_details = false" class="text-sm text-main text-opacity-50">Close</button>
              </div>
            </div>
        </v-easy-dialog>
          <v-easy-dialog v-model="showVisitModal" focus-on="#my-input">
            <div class="flex flex-col bg-white p-5 mx-4 overflow-x-scroll">
              <h3 class="text-primary font-semibold text-xs uppercase tracking-ct mb-6 block ">Add visit</h3>
              <div class="mb-6 relative">
                <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white z-10">Project</label>
                  <multiselect v-model="leadParams.projects" :searchable="true" :placeholder="propertyNoInput"
                  label="name" track-by="id" :multiple="true" :options="projectList"
                  :taggable="true" @search-change="suggestProject" class="multiselect-outer-wrapper"></multiselect>
              </div>
              <div class="mb-6 relative">
                <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white z-10">Properties</label>
                  <multiselect v-model="leadParams.properties" :searchable="true" :placeholder="propertyNoInput"
                  label="project_name" track-by="id" :multiple="true" :options="propertiesList"
                  :taggable="true" @search-change="suggestProperty" class="multiselect-outer-wrapper"></multiselect>
              </div>
              <div class="mb-6 relative">
                <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Visit Date</label>
                <input type="date" v-model="leadParams.visitDate" required class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
              </div>
              <div class="mb-6 relative">
                <label for=""
                  class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Comment</label>
                <textarea v-model="leadParams.visitComment" rows="3" placeholder="Comment"
                  class="border border-inputBorder round-md p-4 w-full z-auto focus:outline-none"></textarea>
              </div>
              <div class="flex justify-end space-x-2">
                  <button @click="updateLead" class="text-sm border-primary text-primary  px-6 py-3 rounded-full   bg-primary bg-opacity-10 mr-4">Submit</button>
                  <button @click="showVisitModal = false" class="text-sm font-medium text-main text-opacity-50">Close</button>
              </div>
            </div>
        </v-easy-dialog>
        <div class="flex items-center p-4 ">
          <div class="w-full">
            <router-link :to="{ name: 'EditEnquiry',  params: {  uuid: this.$route.params.uuid }}" class="flex items-center w-full justify-center bg-primary text-white px-6 py-4 rounded-full text-base font-medium">
              Edit Enquiry
            </router-link>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import VEasyDialog from 'v-easy-dialog'
import {numDifferentiation, prepareUpdateParams} from '@/assets/scripts/utility.js';
export default {
  components: {
    VEasyDialog,
    Multiselect
  },
  data () {
    return {
      callStatus: null,
      phoneCall: {connected: require('@/assets/connected.gif'), dialing: require('@/assets/dialing.gif'), failed: require('@/assets/failed.gif')},
      enquiryDetails: {},
      modal_visit_details: false,
      showVisitModal: false,
      propertiesList: [],
      projectList: [],
      propertyNoInput: 'Start Typing....',
      leadParams: {
        visitDate: null,
        visitComment: null,
        projects: [],
        properties: []
      },
    }
  },
  created(){
    this.$store.dispatch('setnewEnquiryData', {});
    this.loadEnquiryDetails()
  },
  computed: {
    cpSourceIds() {
      return this.$store.getters.cpIds;
    },
    plan_ids() {
      return this.$store.getters.plan_ids;
    },
    leadKeysList(){
      return this.$store.getters.leadKeys;
    },
    deadStatusIds() {
      return this.$store.getters.deadStatusIds;
    },
    gifCallStatus() {
      if (this.callStatus == 'dialing') {
        return this.phoneCall.dialing
      } else if (this.callStatus == true) {
        return this.phoneCall.connected
      } else if (this.callStatus == false) {
        return this.phoneCall.failed
      } else {
        return ''
      }
    }
  },
  methods: {
    logCallAttempt(location){
      this.$axios
      .post(`mobile_crm/leads/${this.enquiryDetails.uuid}/log_call_attempt`)
      .then((res) => {
        window.location= location
      })
      .catch((err) => {
        window.location= location
      });
    },
    makeCall(uuid) {
      this.callStatus = 'dialing'
      this.$axios.post(`mobile_crm/leads/make_call`, { uuid: uuid }).then((res) => {
        this.callStatus = res.data.success
      });
    },
    suggestProject(event) {
      if (event.length === 3) {
        event = encodeURIComponent(event)
        this.$axios.get(`/mobile_crm/leads/search_projects?search_string=${event.trim()}`)
          .then(res => {
            this.propertyNoInput = "No Results!"
            if (res.data.projects.length > 0) {
              this.projectList = res.data.projects
            }
          })
          .catch(err => {
            this.propertyNoInput = "Something Went Wrong!"
          })
      }
    },
    suggestProperty(event) {
      if (event.length === 3) {
        event = encodeURIComponent(event)
        this.$axios.get(`/mobile_crm/leads/search_properties?search_string=${event.trim()}`)
          .then(res => {
            this.propertyNoInput = "No Results!"
            if (res.data.properties.length > 0) {
              this.propertiesList = res.data.properties
            }
          })
          .catch(err => {
            this.propertyNoInput = "Something Went Wrong!"
          })
      }
    },
    priceinLacs(value) {
      return numDifferentiation(value)
    },
    deleteVisit(visit) {
      swal({
        title: "Are you sure?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.$axios
            .delete(`mobile_crm/leads/${this.enquiryDetails.uuid}/visits/${visit.id}`)
            .then((res) => {
              let index = this.enquiryDetails.visits.indexOf(visit);
              this.enquiryDetails.visits.splice(index, 1);
              swal({
                title: "Visit Deleted!",
                icon: "success",
              });
            })
            .catch((err) => {
              if (err.response.status === 500) {
                swal({
                  title: "Server Error!",
                  icon: "error",
                });
              } else {
                swal({
                  title: "Error",
                  text: err.response.data.message,
                  icon: "error",
                });
              }
            });
        }
      });
    },
    updateLead() {
      let updateP = prepareUpdateParams(this.leadParams);
      if (Object.keys(updateP).length > 0) {
        this.$axios
          .put(`mobile_crm/leads/${this.enquiryDetails.uuid}`, { lead: updateP })
          .then((res) => {
            this.enquiryDetails = res.data.lead;
            for (let key in this.leadParams) {
              this.leadParams[key] = null;
            }
            this.showVisitModal = false
            swal({
              title: "Updated!",
              icon: "success",
            });
          })
          .catch((err) => {
            if (err.response.status === 500) {
              swal({
                title: "Server Error!",
                icon: "error",
              });
            } else {
              swal({
                title: "Error",
                text: err.response.data.message,
                icon: "error",
              });
            }
          });
      }
    },
    loadEnquiryDetails(){
      this.$axios.get(`mobile_crm/leads/${this.$route.params.uuid}`)
        .then((res) => {
        this.enquiryDetails = res.data.lead
      })
      .catch((err) => console.log(err));
    }
  },
}
</script>