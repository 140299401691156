<template>
	<div class="animate_animated animate__slideInRight">
    <div class="fixed w-full top-0 left-0 z-20">
			<div class=" bg-white px-4 py-6 pb-5 shadow-ct">
				<div class="flex items-center">
					<div class="w-1/2">
						<div class="flex items-center w-full">
							<a  @click="$router.push('/')"><img src="@/assets/back-arrow.svg" alt=""></a>
							<span class="text-base text-content font-medium ml-4">Agreements</span>
						</div>
					</div>
					<div class="w-1/2">
						<div class="flex items-center justify-end">
							<a @click="$router.push('searchagreements')" class="mx-2"><img src="@/assets/filter-icon.svg" alt=""></a>
						</div>
					</div>
				</div>
				<div class="flex mt-5">
					<div class="flex items-strech w-full">
						<div class="relative w-full">
							<input type="text" @input="e => bsTerm = e.target.value" :value="bsTerm" name="" id="" placeholder="Search with Project, Buyer or Owner Name"
								class="border border-inputBorder round-md p-3 w-full z-auto focus:outline-none rounded-tl rounded-bl">
							<button type="button" class="absolute right-0 top-0 m-3" v-if="bsTerm" @click="resetBs">
              <img src="@/assets/close-icon.svg" alt="" class="transform rotate-45"></button>
						</div>

						<div class="bg-primary rounded-tr rounded-br px-3 flex items-center">
              <button type="button" :disabled="!bsTerm" @click="performBs">
							<img src="@/assets/search-icon-white.svg"   alt="" class=""></button>
						</div>
					</div>
				</div>
			</div>
     
		</div>
		<div class="pt-16 mt-18">
				<!-- Card-List -->
      <span class="result-Text px-3 pt-2 text-sm text-main text-opacity-50 block -mb-3">Showing {{ pageStart }} - {{ pageEnd }} of {{ total }}</span>
      <router-link :to="{ name: 'AgreementDetails',  params: {  uuid: ag.uuid } }" v-for="(ag, key) in agreements" :key="key">
			<div class="shadow-ct bg-white my-4 px-4 py-3 ">
				<div class="flex">
					<div class="w-1/2">
						<span class="text-content text-xs text-opacity-50 block mt-1 mb-1">Buyer</span>
						<span class="text-primary text-xs font-medium block mb-3">{{ag.buyer_name}}</span>
						<div class="">
							<span class="text-content text-xs text-opacity-50  mr-1">Agreement ID</span><span
								class="text-xs text-content font-medium">{{ag.id}}</span>
						</div>
						<div class="">
							<span class="text-content text-xs text-opacity-50  mr-1">Property ID</span><span
								class="text-xs text-content font-medium">{{ag.property_id}}</span>
						</div>
					</div>
					<div class="w-1/2">
						<div class="flex justify-between items-center">
							<span class="text-content text-xs text-opacity-50 ">Owner</span>
							<span class="bg-primary text-white text-xxs font-medium px-3 py-1	 rounded-full">{{ag.transaction_type}}</span>
						</div>
						
						<span class="text-primary text-xs font-medium block mb-3">{{ag.owner}}</span>
						<div class="">
							<span class="text-content text-xs text-opacity-50  mr-1">Agmt. Till</span><span
								class="text-xs text-content font-medium">{{ag.agreement_till}}</span>
						</div>
						<!-- <div class="">
							<span class="text-content text-xs text-opacity-50  mr-1">City</span><span
								class="text-xs text-content font-medium">Pune</span>
						</div> -->
					</div>
				</div>
			</div>
      </router-link>
			<!-- Add Button -->
			<router-link :to="{ name: 'NewAgreement' }" class="fixed bottom-0 right-0 m-4 flex items-center justify-center w-14 h-14 bg-primary rounded-full">
				<img src="@/assets/plus-icon.svg" alt="">
			</router-link>
      <div class="pagination-holder">
      <paginate
        v-if="totalPages > 1"
        v-model="pageNo"
        :page-count="totalPages"
        :click-handler="goToPage"
        :page-class="'page-item'"
        :page-link-class="'page-link'"
        :prev-link-class="'page-link'"
        :next-link-class="'page-link'"
        :disabled-class="'page-item disabled'"
        :container-class="'pagination'">
      </paginate>
    </div>
		</div>
	</div>
</template>
<script>
import {paramsFromQuery, prepareAgreementSearchParams} from '@/assets/scripts/utility.js';
export default {
  data () {
    return {
      bsTerm: this.$route.query.bs,
      total: 0,
      perPage: 0,
      agreements: [],
      advSearchParams: [],
    }
  },
  watch: {
    $route(to, from){
      this.loadAgreements();
    }
  },
  created(){
    this.$store.dispatch('setnewAgreementData', {});
    this.loadAgreements()
  },
  methods: {
    loadAgreements(){
      let queryP = {}
      if(Object.keys(this.advSParams).length > 0){
        queryP["as"] = true
        let searchParams = prepareAgreementSearchParams(this.advSParams);
        for(let key in searchParams){
          queryP[key] = searchParams[key];
        }
        this.advSearchParams = queryP
      }
      if (this.advSearchParams.length > 0){
        paramsFromQuery(queryP, this.advSearchParams);
      }
      else{
        paramsFromQuery(queryP, this.$route.query);
      }
      let data = this.$axios.get('/mobile_crm/agreements', {
        params: queryP
      })
      .then(res=>{
        this.agreements = res.data.agreements;
        this.perPage = res.data.per_page;
        this.total = res.data.count;
        // this.$store.dispatch('setAdvSParams', {});
    })
    .catch(err => console.log(err));
    },
    goToPage(pageNo){
      let newQ = {...this.$route.query, pageNo};
      this.$router.replace({query: newQ});
    },
    resetBs(){
      let rQ = this.$route.query
      this.bsTerm = ''
      if(rQ.bs){
        let {bs, ...newQ} = {}
        this.$router.replace({query: newQ})
      }
    },
    performBs(){
      if(this.bsTerm){
        let newQ = {...this.$route.query, bs: this.bsTerm}
        delete newQ.pageNo
        this.$router.replace({query: newQ})
      }
    }
  },
  computed: {
    advSParams(){
      return this.$store.getters.advSParams;
    },
    totalPages(){
      if(this.total === 0 || this.perPage === 0){
        return 0;
      }else{
        return Math.ceil(this.total/this.perPage);
      }
    },
    pageNo: {
      get(){
        let pageNo = this.$route.query.pageNo || 1
        return parseInt(pageNo);
      },
      set(){
        true;
      }
    },
    pageStart(){
      if(this.total === 0){
        return 0;
      }
      return (Math.max(0, (this.pageNo - 1)*this.perPage+1))
    },
    pageEnd(){
      if(this.total === 0){
        return 0;
      }
      return (Math.min(this.total, this.pageNo*this.perPage))
    }
  }
}
</script>
<style scoped>

</style>
