<template>
	<div class="animate_animated animate__slideInRight">
		<div class="fixed w-full top-0 left-0 z-20">
		<div class="flex bg-white px-4 py-6 items-center shadow-ct">
			<div class="w-full">
				<div class="flex items-center w-full">
					<a  @click="$router.go(-1)"><img src="@/assets/back-arrow.svg" alt=""></a>
					<span class="text-base text-content font-medium ml-4">New Enquiry</span>
				</div>
			</div>
		</div>
    </div>
    <div class="pt-20 mt-2 bg-white">
    <div class="bg-white min-h-screen p-4">
       <Enquiry :enquiry="AddNewEnquiry" :submitFn="goToStep1" :getProperty="getProperty" :propertyList="propertyList"/>
    </div>
  </div>
	</div>
</template>
<script>
import Enquiry from '@/components/Enquiry/New.vue';
import {prepareEnquiryParams} from '@/assets/scripts/utility.js';
export default {
  components: {
    Enquiry
  },
  data () {
    return {
      propertyList: [],
      AddNewEnquiry: {
        transaction_type_id: null,
        building_type: null,
        property_type: null,
        configuration: null,
        name: null,
        email: null,
        mobile: null,
        possession_by: null,
        budget: null,
        city_id: null,
        locality_ids: [],
        status_id: null,
        source_id: null,
        ncd: null,
        user_id: null,
      },
    }
  },
  created() {
    let step0_data = this.$store.getters.newEnquiryData
    if (step0_data) {
      this.AddNewEnquiry = step0_data
    }
  },
  methods: {
    getProperty() {
      this.$axios.get(`mobile_crm/leads/properties?project_id=${this.AddNewEnquiry.preferred_project_id.id}`)
      .then((res) => {
        this.propertyList = res.data
      })
      .catch((err) => console.log(err));
    },
    goToStep1(){
      let apiParams = {}
      apiParams = prepareEnquiryParams(this.AddNewEnquiry);
      let newEnquiryData = apiParams
      this.$store.dispatch('setnewEnquiryData', newEnquiryData);
      this.$router.push({name: 'NextEnquiry'});
    },
  }
}
</script>
<style scoped>

</style>