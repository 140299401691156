<template>
  <div>
    <div class="animationFix">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  created(){
    this.$axios.get('mobile_crm/leads/settings')
      .then(res => {
        let {users, projects, properties, bank_names, lead_keys} = res.data
        this.$store.dispatch('setUsers', users)
        this.$store.dispatch('setProjects', projects)
        this.$store.dispatch('setProperties', properties)
        this.$store.dispatch('setLeadKeys', lead_keys)
      })
      .catch(err => console.log(err.response))
      this.$axios.get('mobile_crm/settings')
      .then(res => {
        let {sources, statuses, dead_status_ids, bookings_done_ids, dead_reasons, lead_allowed_fields, cp_sources_ids, channel_partners, site_visit_planned_ids, hot_status_ids, enable_meeting_executives, enable_created_user} = res.data
        this.$store.dispatch('setSourcesMaster', sources)
        this.$store.dispatch('setStatusesMaster', statuses)
        this.$store.dispatch('setDeadStatusIds', dead_status_ids)
        this.$store.dispatch('setBDStatusIds', bookings_done_ids)
        this.$store.dispatch('setDeadReasons', dead_reasons)
        this.$store.dispatch('setLeadAllowedFields', lead_allowed_fields)
        this.$store.dispatch('setCPSourceIds', cp_sources_ids)
        this.$store.dispatch('setChannelPartners', channel_partners)
        this.$store.dispatch('setPlanIds', site_visit_planned_ids)
        this.$store.dispatch('setHotStatusIds', hot_status_ids)
        this.$store.dispatch('setMeetingExecutive', enable_meeting_executives)
        this.$store.dispatch('setCreatedUser', enable_created_user)
      })
      .catch(err => console.log(err.response))
      this.$axios.get('mobile_crm/properties/settings')
      .then(res => {
        let {property_keys} = res.data
        this.$store.dispatch('setPropertyKeys', property_keys)
      })
      .catch(err => console.log(err.response))
      this.$axios.get('mobile_crm/agreements/settings')
      .then(res => {
        let agreement_keys = res.data.agreement_keys
        this.$store.dispatch('setAgreementKeys', agreement_keys)
      })
      .catch(err => console.log(err.response))
  }
}
</script>