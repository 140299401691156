import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Projects from '../views/Project/projects.vue'
import NewProject from '../views/Project/New.vue'
import EditProject from '../views/Project/Edit.vue'
import ProjectDetails from '../views/Project/Details.vue'
import SearchProject from '../views/Project/SearchProject.vue'
import Properties from '../views/properties/Properties.vue'
import SearchProperties from '../views/properties/SearchProperties.vue'
import NewProperty from '../views/properties/New.vue'
import NextProperty from '../views/properties/Next.vue'
import Next2Property from '../views/properties/Next2.vue'
import EditProperty from '../views/properties/Edit.vue'
import EditNextProperty from '../views/properties/EditNext.vue'
import EditNext2Property from '../views/properties/EditNext2.vue'
import PropertyDetails from '../views/properties/Details.vue'
import PropertyMatches from '../views/properties/Matches.vue'
import Enquiry from '../views/Enquiry/Enquiry.vue'
import TodaysCalls from '../views/Enquiry/TodaysCalls.vue'
import Backlogs from '../views/Enquiry/Backlogs.vue'
import NewEnquiry from '../views/Enquiry/New.vue'
import NextEnquiry from '../views/Enquiry/Next.vue'
import EditEnquiry from '../views/Enquiry/Edit.vue'
import EditNextEnquiry from '../views/Enquiry/EditNext.vue'
import DetailsEnquiry from '../views/Enquiry/Details.vue'
import History from '../views/Enquiry/History.vue'
import SearchEnquiry from '../views/Enquiry/SearchEnquiry.vue'
import EnquiryMatches from '../views/Enquiry/Matches.vue'
import Agreement from '../views/Agreement/Agreement.vue'
import NewAgreement from '../views/Agreement/New.vue'
import NextAgreement from '../views/Agreement/Next.vue'
import EditAgreement from '../views/Agreement/Edit.vue'
import EditNextAgreement from '../views/Agreement/EditNext.vue'
import AgreementDetails from '../views/Agreement/Details.vue'
import Collection from '../views/Agreement/Collection.vue'
import SearchAgreements from '../views/Agreement/SearchAgreements.vue'
import ExpiringLease from '../views/Lease/ExpiringLease.vue'
import SearchExpiringLease from '../views/Lease/SearchExpiringLease.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/newproject',
    name: 'NewProject',
    component: NewProject
  },
  {
    path: '/editproject/:uuid',
    name: 'EditProject',
    component: EditProject
  },
  {
    path: '/projectdetails/:uuid',
    name: 'ProjectDetails',
    component: ProjectDetails
  },
  {
    path: '/searchproject',
    name: 'searchproject',
    component: SearchProject
  },
  {
    path: '/properties',
    name: 'Properties',
    component: Properties
  },
  {
    path: '/searchproperties',
    name: 'SearchProperties',
    component: SearchProperties
  },
  {
    path: '/newproperty',
    name: 'NewProperty',
    component: NewProperty
  },
  {
    path: '/nextproperty',
    name: 'NextProperty',
    component: NextProperty
  },
  {
    path: '/next2property',
    name: 'Next2Property',
    component: Next2Property
  },
  {
    path: '/editproperty/:uuid',
    name: 'EditProperty',
    component: EditProperty
  },
  {
    path: '/editnextproperty/:uuid',
    name: 'EditNextProperty',
    component: EditNextProperty
  },
  {
    path: '/editnext2property/:uuid',
    name: 'EditNext2Property',
    component: EditNext2Property
  },
  {
    path: '/propertydetails/:uuid',
    name: 'PropertyDetails',
    component: PropertyDetails
  },
  {
    path: '/propertymatches',
    name: 'PropertyMatches',
    component: PropertyMatches
  },
  {
    path: '/enquiry',
    name: 'Enquiry',
    component: Enquiry
  },
  {
    path: '/TodaysCalls',
    name: 'TodaysCalls',
    component: TodaysCalls
  },
  {
    path: '/Backlogs',
    name: 'Backlogs',
    component: Backlogs
  },
  {
    path: '/newenquiry',
    name: 'NewEnquiry',
    component: NewEnquiry
  },
  {
    path: '/nextenquiry',
    name: 'NextEnquiry',
    component: NextEnquiry
  },
  {
    path: '/editenquiry/:uuid',
    name: 'EditEnquiry',
    component: EditEnquiry
  },
  {
    path: '/editnextenquiry/:uuid',
    name: 'EditNextEnquiry',
    component: EditNextEnquiry
  },
  {
    path: '/detailsenquiry/:uuid',
    name: 'DetailsEnquiry',
    component: DetailsEnquiry
  },
  {
    path: '/history/:uuid',
    name: 'History',
    component: History
  },
  {
    path: '/searchenquiry',
    name: 'SearchEnquiry',
    component: SearchEnquiry
  },
  {
    path: '/enquirymatches',
    name: 'EnquiryMatches',
    component: EnquiryMatches
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: Agreement
  },
  {
    path: '/newagreement',
    name: 'NewAgreement',
    component: NewAgreement
  },
  {
    path: '/nextagreement',
    name: 'NextAgreement',
    component: NextAgreement
  },
  {
    path: '/editagreement/:uuid',
    name: 'EditAgreement',
    component: EditAgreement
  },
  {
    path: '/editnextagreement/:uuid',
    name: 'EditNextAgreement',
    component: EditNextAgreement
  },
  {
    path: '/agreementdetails/:uuid',
    name: 'AgreementDetails',
    component: AgreementDetails
  },
  {
    path: '/searchagreements',
    name: 'SearchAgreements',
    component: SearchAgreements
  },
  {
    path: '/collection/:uuid',
    name: 'Collection',
    component: Collection
  },
  {
    path: '/expiringlease',
    name: 'ExpiringLease',
    component: ExpiringLease
  },
  {
    path: '/searchexpiringlease',
    name: 'SearchExpiringLease',
    component: SearchExpiringLease
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
