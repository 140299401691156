<template>
	<div class="animate_animated animate__slideInRight">
		<div class="fixed w-full top-0 left-0 z-20">
		<div class="flex bg-white px-4 py-6 items-center shadow-ct">
			<div class="w-full">
				<div class="flex items-center w-full">
					<a @click="$router.go(-1)"><img src="@/assets/back-arrow.svg" alt=""></a>
					<span class="text-base text-content font-medium ml-4">Edit Property</span>
				</div>
			</div>
		</div>
    </div>
    <div class="pt-20 mt-2 bg-white">
    <div class="bg-white min-h-screen p-4">
      <Property :property="EditProperty" :editSubmitFn="goToStep1" />
    </div>
  </div>
	</div>
</template>
<script>
import Property from '@/components/Property/New.vue';
import {preparePropertyParams} from '@/assets/scripts/utility.js';
export default {
  components: {
    Property
  },
  data () {
    return {
      EditProperty: {},
    }
  },
  created() {
    this.$axios.get(`mobile_crm/properties/${this.$route.params.uuid}`)
    .then((res) => {
      this.EditProperty = res.data.property
        let step0_data = this.$store.getters.newPropertyData
        if (Object.keys(step0_data).length > 0) {
          this.EditProperty = { ...this.EditProperty, ...step0_data}
          // this.EditProperty = step0_data
        }
      })
    .catch((err) => console.log(err));
  },
  methods: {
    goToStep1(){
      let apiParams = {}
      apiParams = preparePropertyParams(this.EditProperty);
      let editPropertyData = apiParams
      this.$store.dispatch('setnewPropertyData', editPropertyData);
      this.$router.push({name: 'EditNextProperty'});
    },
  }
}
</script>
<style scoped>

</style>