<template>
	<div class="animate_animated animate__slideInRight">
		<div class="fixed w-full top-0 left-0 z-20">
		<div class="flex bg-white px-4 py-6 items-center shadow-ct">
			<div class="w-full">
				<div class="flex items-center w-full">
					<a  @click="$router.go(-1)"><img src="@/assets/back-arrow.svg" alt=""></a>
					<span class="text-base text-content font-medium ml-4">Search Enquiry</span>
				</div>
			</div>
		</div>
    </div>
    <div class="pt-20 mt-2 bg-white">
    <div class="bg-white min-h-screen p-4">
      <form>
        <div class="mb-6 relative">
          <label for=""
            class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Saved Searches</label>
          <select v-model="search.ss_id"
            class="border border-inputBorder round-md p-4 w-full z-auto custom-select focus:outline-none">
            <option
              v-for="(s, key) in saved_searches"
              :key="key"
              :value="s.id"
            >{{s.name}}</option>
          </select>
        </div>
        <div class="mb-10">
          <label for="" class="text-main text-opacity-50 text-xs block mb-5 ml-4">Property For</label>
          <div class="flex">
            <span class="radio-selection">
              <input type="radio" v-model="search.transaction_type_id" name="propertyFor" value="1" id="Sale" class="hidden">
              <label for="Sale" class="bg-divider py-3 px-6 text-content rounded-full font-medium pills mr-3"> Sale </label>
            </span>
             <span class="radio-selection">
              <input type="radio" v-model="search.transaction_type_id" name="propertyFor" value="2" id="Rental" class="hidden">
              <label for="Rental" class="bg-divider py-3 px-6 text-content rounded-full font-medium pills"> Rental </label>
            </span>
          </div>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Name</label>
          <input type="text" v-model="search.name" placeholder="Name" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Email</label>
          <input type="email" v-model="search.email" placeholder="Email" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Mobile</label>
          <input type="number" v-model="search.mobile" placeholder="Mobile No" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Created From</label>
          <input type="date" v-model="search.created_at_from" placeholder="Created From" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Created Upto</label>
          <input type="date" v-model="search.created_at_upto" placeholder="Created Upto" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Building Type</label>
          <select v-model="search.building_type" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select the type</option>
            <option
              v-for="(bt, key) in leadKeysList.building_types"
              :key="key"
              :value="bt.id"
            >{{bt.name}}</option>
          </select>
        </div>
         <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Property Type</label>
          <select v-model="search.property_type" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select Property Type</option>
            <option
              v-for="(pt, key) in propertyType"
              :key="key"
              :value="pt"
            >{{pt}}</option>
          </select>
        </div>
        <div class="mb-6 relative">
          <label for=""
            class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Furnishings</label>
          <select v-model="search.furnishing"
            class="border border-inputBorder round-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select Furnishings</option>
            <option value='Yes'>Yes</option>
            <option value='No'>No</option>
          </select>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Configuration</label>
          <select v-model="search.configuration" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select Configuration</option>
           <option
              v-for="(c, key) in leadKeysList.configuration"
              :key="key"
              :value="c"
            >{{c}}</option>
          </select>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white z-10">Status</label>
          <multiselect v-model="search.lead_statuses" :searchable="false"  placeholder="Select Status" label="name" track-by="id" :options="statusList" :multiple="true" :taggable="true" class="multiselect-outer-wrapper"></multiselect>
        </div>
        <div class="mb-6 relative" v-if="is_status_dead">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Dead Reason</label>
          <select v-model="search.dead_reason_id" class="border border-inputBorder rounded-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select Dead Reason</option>
            <option
              v-for="(s, key) in deadReasons"
              :key="key"
              :value="s.id"
            >{{s.reason}}</option>
          </select>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white z-10">Sources</label>
          <multiselect v-model="search.source_id" :searchable="false"  placeholder="Select Sources" label="name" track-by="id" :options="sourceList" :multiple="true" :taggable="true" class="multiselect-outer-wrapper"></multiselect>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white z-10">Brokers</label>
          <multiselect v-model="search.broker_ids" :searchable="false"  placeholder="Select Broker" label="name" track-by="id" :options="cpList" :multiple="true" :taggable="true" class="multiselect-outer-wrapper"></multiselect>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white z-10">Select Assigned To</label>
          <multiselect v-model="search.user_id" :searchable="false"  placeholder="Select Users" label="name" track-by="id" :options="userList" :multiple="true" :taggable="true" class="multiselect-outer-wrapper"></multiselect>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white z-10">Select Projects</label>
          <multiselect v-model="search.project_ids" :searchable="true"  placeholder="Select Projects" label="name" track-by="id" :options="projectList" :multiple="true" :taggable="true" class="multiselect-outer-wrapper"></multiselect>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white z-10">Select Properties</label>
          <multiselect v-model="search.property_ids" :searchable="true"  placeholder="Select Properties" label="name" track-by="id" :options="propertiesList" :multiple="true" :taggable="true" class="multiselect-outer-wrapper"></multiselect>
        </div>
        <div class="mb-6 relative" v-if="meeting_executive">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white z-10">Select Closing Executive</label>
          <multiselect v-model="search.closing_executive" :searchable="true"  placeholder="Select Closing Executive" label="name" track-by="id" :options="leadKeysList.closing_executives" :multiple="true" :taggable="true" class="multiselect-outer-wrapper"></multiselect>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">City</label>
          <select v-model="search.city_ids" class="border border-inputBorder round-md p-4 w-full z-auto custom-select focus:outline-none">
            <option selected disabled>Select City</option>
            <option
              v-for="(r, key) in leadKeysList.cities"
              :key="key"
              :value="r.id"
            >{{r.name}}</option>
          </select>
        </div>
        <div class="mb-6 relative">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white z-10">Locality</label>
          <multiselect v-model="search.locality_ids" :searchable="true"  :options-limit="leadKeysList.locality.length"  placeholder="Select Locality" label="name" track-by="id" :options="leadKeysList.locality" :multiple="true" :taggable="true" class="multiselect-outer-wrapper"></multiselect>
        </div>
        <div class="mb-6 relative">
          <el-checkbox class="ml-2" v-model="search.set_search">  Saved Search</el-checkbox>
        </div>
        <div class="mb-6 relative" v-if="search.set_search">
          <label for="" class="text-main text-opacity-50 text-xs block  ml-2 absolute -top-2 left-0 px-2 bg-white">Search Name</label>
          <input type="text" v-model="search.search_name" placeholder="Search Name" class="border border-inputBorder rounded-md p-4 w-full z-auto focus:outline-none">
        </div>
        <div class="mt-10">
          <a @click="clearFilters" class="clearFilter"> Clear Filters</a>
          <button type="button" @click="performSearch" class="bg-primary text-base font-semibold text-white rounded-full p-5 w-full disabled:opacity-50" >Filter</button>
        </div>
      </form>
    </div>
  </div>
	</div>
</template>
<script>
import {AddEnquiryParams} from '@/assets/scripts/utility.js';
import Multiselect from 'vue-multiselect'
export default {
  components: {
    Multiselect
  },
  data () {
    return {
      saved_searches: [],
      search: {
        ss_id: null,
        name: null,
        email: null,
        mobile: null,
        lead_statuses: [],
        source_id: [],
        user_id: [],
        created_at_from: null,
        created_at_upto: null,
        city_ids: null,
        locality_ids: [],
        project_ids: [],
        property_ids: [],
        broker_ids: [],
        transaction_type_id: null,
        building_type: null,
        property_type: null,
        configuration: null,
        dead_reason_id: null,
        furnishing: null,
        set_search:  false,
        search_name: null,
        closing_executive: []

      },
    }
  },
  methods: {
    performSearch(){
      this.$store.dispatch('setAdvSParams', this.search);
      if (this.$route.query.type === 'Backlogs') {
        this.$router.push({name: 'Backlogs'})
      } else if (this.$route.query.type === 'TodaysCalls') {
         this.$router.push({name: 'TodaysCalls'})
      } else {
        this.$router.push({name: 'Enquiry'})
      }
    },
    seedDefaults(){
      this.search = this.advSParams
      this.search.search_name = null
      this.search.set_search = false
    },
    clearFilters(){
      this.search = {
        ss_id: null,
        name: null,
        email: null,
        mobile: null,
        lead_statuses: [],
        source_id: [],
        user_id: [],
        created_at_from: null,
        created_at_upto: null,
        city_ids: null,
        locality_ids: [],
        property_ids: [],
        project_ids: [],
        broker_ids: [],
        transaction_type_id: null,
        building_type: null,
        property_type: null,
        configuration: null,
        furnishing: null,
        set_search:  false,
        search_name: null,
        closing_executive: []

      }
      this.$store.dispatch('setAdvSParams', {});
    }
  },
  created(){
    if(Object.keys(this.advSParams).length > 0){
      this.seedDefaults();
    }
    this.$axios
      .get(`mobile_crm/saved_searches`)
      .then(res => {
        this.saved_searches = res.data.searches;
      })
      .catch(err => console.log(err));

  },
  computed: {
    leadKeysList(){
      return this.$store.getters.leadKeys;
    },
    projectList(){
      return this.$store.getters.projectMaster;
    },
    propertiesList(){
      return this.$store.getters.propertiesMaster;
    },
    userList() {
      return this.$store.getters.usersMaster;
    },
    sourceList(){
      return this.$store.getters.sourcesMaster;
    },
    statusList(){
      return this.$store.getters.statusesMaster;
    },
    advSParams(){
      return this.$store.getters.advSParams;
    },
    cpList() {
      return this.$store.getters.channel_partners;
    },
    deadReasons() {
      return this.$store.getters.deadReasons;
    },
    deadStatusIds() {
      return this.$store.getters.deadStatusIds;
    },
    propertyKeysList(){
      return this.$store.getters.propertyKeys;
    },
    meeting_executive() {
      return this.$store.getters.meeting_executive;
    },
    is_status_dead() {
      if (this.search.lead_statuses) {
        return this.search.lead_statuses.map(s => s.id).some(s => this.deadStatusIds.includes('' + s))
      }
    },
    propertyType() {
      if (this.search.building_type === 1) {
        return this.propertyKeysList.residential_property_type
      } else if (this.search.building_type === 2) {
        return this.propertyKeysList.commercial_property_type
      } else {
        return []
      }
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.clearFilter{
  display: table;
  margin:20px auto;
  opacity: .5;
  font-size: 14px;
}
</style>