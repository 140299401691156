<template>
  <div class="animate_animated animate__slideInRight">
    <div class="fixed w-full top-0 left-0 z-20">
      <div class=" bg-white px-4 py-6 pb-5 shadow-ct">
        <div class="flex items-center">
          <div class="w-1/2">
            <div class="flex items-center w-full">
              <a @click="$route.query.match ? $router.go(-1) : $router.push('/')"><img src="@/assets/back-arrow.svg"
                  alt=""></a>
              <span class="text-base text-content font-medium ml-4">{{ $route.query.match ? 'Matches' : 'My Backlogs'}}</span>
            </div>
          </div>
          <div class="w-1/2">
            <div class="flex items-center justify-end">
              <router-link :to="{ name: 'SearchEnquiry', query: { type: 'Backlogs' } }" class="mx-2"><img
                  src="@/assets/filter-icon.svg" alt=""></router-link>
            </div>
          </div>
        </div>
        <div class="flex mt-5">
          <div class="flex items-strech w-full">
            <div class="relative w-full">
              <input type="text" @input="e => bsTerm = e.target.value" :value="bsTerm" name="" id=""
                placeholder="Search by Name, Enquiry No, Email or Phone"
                class="border border-inputBorder round-md p-3 w-full z-auto focus:outline-none rounded-tl rounded-bl">
              <button type="button" class="absolute right-0 top-0 m-3" v-if="bsTerm" @click="resetBs">
                <img src="@/assets/close-icon.svg" alt="" class="transform rotate-45"></button>
            </div>

            <div class="bg-primary rounded-tr rounded-br px-3 flex items-center">
              <button type="button" :disabled="!bsTerm" @click="performBs">
                <img src="@/assets/search-icon-white.svg" alt="" class=""></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-16 mt-18">
      <LeadsListLayout :activeStatus="activeStatus" :selectStatus="goTo" />
      <span class="result-Text px-3 pt-1 text-sm text-main text-opacity-50 block -mb-3">Showing {{ pageStart }} - {{
          pageEnd
      }} of {{ total }}</span>
      <!-- Card-List -->
      <div class="shadow-ct bg-white my-4" v-for="(el, key) in enquiryList" :key="key">
        <router-link :to="{ name: 'DetailsEnquiry', params: { uuid: el.uuid } }">
          <div class="px-4 py-3">
            <div class="flex items-center">
              <div class="w-2/3">
                <span class="text-content text-sm font-medium">{{ priceinLacs(el.budget) | asINR }} {{ el.city }}</span>
              </div>
              <div class="w-1/3 flex items-center justify-end ">
                <span
                  class="text-content text-xs text-opacity-50  bg-grey py-2 px-4 rounded-full text-center  font-medium  ">{{ el.status }}</span>
              </div>
            </div>
            <div class="flex items-center mt-1 mb-2">
              <img src="@/assets/user-icon.svg" alt="" class="mr-1"> <span
                class="text-content text-xs text-opacity-50">{{ el.name }}</span>
            </div>
            <div class="flex flex-wrap items-center">
              <span class="enquiry-label">
                <span class="text-xs text-content text-opacity-50 mr-1">Assigned</span><span
                  class="text-content font-medium text-xs">{{ el.user }}</span>
              </span>
              <span class="enquiry-label">
                <span class="text-xs text-content text-opacity-50 mr-1">Possession</span><span
                  class="text-content font-medium text-xs">{{ el.possession_by }}</span>
              </span>
            </div>
            <div class="flex flex-wrap items-center">
              <span class="enquiry-label">
                <span class="text-xs text-content text-opacity-50 mr-1">Source</span><span
                  class="text-content font-medium text-xs">{{ el.source }}</span>
              </span>
              <span class="enquiry-label">
                <span class="text-xs text-content text-opacity-50 mr-1">NCD</span><span
                  class="text-content font-medium text-xs">{{ el.formatted_ncd }}</span>
              </span>
            </div>
          </div>
        </router-link>
        <div class="flex items-center p-4 border-t border-divider">
          <div class="w-1/2">
            <!-- <router-link :to="{ name: 'EnquiryMatches' }"> -->
            <div class="flex items-center w-full" v-if="el.property_matches > 0" @click="filterMatchesProperties(el)">
              <span
                class="w-6 h-6 bg-success rounded-full text-white font-semibold text-xs mr-1 flex items-center justify-center">{{ el.property_matches }}</span>
              <span class="text-xs text-content font-medium">Matches</span>
            </div>
            <!-- </router-link> -->
          </div>
          <div class="w-1/2">
            <div class="flex items-center justify-end">
              <a :href="el.mobile && `https://wa.me/${waNum(el.mobile)}`" v-if="el.mobile" class="mx-4"><img
                  src="@/assets/whatsapp.svg" alt=""></a>
              <a :href="el.email && `mailto: ${el.email}`" v-if="$route.query.match && el.email" class="mx-4"><img
                  src="@/assets/email-icon.svg" alt=""></a>
              <a :href="el.mobile && `tel:${el.mobile}`" v-if="el.mobile" class="mx-4"><img
                  src="@/assets/phone-icon.svg" alt=""></a>
            </div>
          </div>
        </div>
      </div>
      <!-- Add Button -->
      <router-link :to="{ name: 'NewEnquiry' }" v-if="!$route.query.match"
        class="fixed bottom-0 right-0 m-4 flex items-center justify-center w-14 h-14 bg-primary rounded-full">
        <img src="@/assets/plus-icon.svg" alt="">
      </router-link>
      <div class="pagination-holder">
        <paginate v-if="totalPages > 1" v-model="pageNo" :page-count="totalPages" :click-handler="goToPage"
          :page-class="'page-item'" :page-link-class="'page-link'" :prev-link-class="'page-link'"
          :next-link-class="'page-link'" :disabled-class="'page-item disabled'" :container-class="'pagination'">
        </paginate>
      </div>
    </div>
  </div>
</template>
<script>
import LeadsListLayout from '@/components/Common/ListLayout.vue';
import qs from 'qs';
import { paramsFromQuery, waFormatter, prepareEnquirySearchParams, numDifferentiation } from '@/assets/scripts/utility.js';
export default {
  components: {
    LeadsListLayout
  },
  data() {
    return {
      bsTerm: this.$route.query.bs,
      total: 0,
      perPage: 0,
      enquiryList: [],
      advSearchParams: [],
      activeStatus: 'all'
    }
  },
  watch: {
    $route(to, from) {
      this.selectStatus();
    }
  },
  created() {
    this.$store.dispatch('setnewEnquiryData', {});
    this.selectStatus();
  },
  methods: {
    goTo(status) {
      let newQ = { ...this.$route.query, status_id: status }
      if (status === 'all') {
        delete newQ['status_id'];
      }
      delete newQ['sortDir'];
      delete newQ['sortKey'];
      delete newQ['pageNo'];
      this.$router.replace({ query: newQ });
    },
    selectStatus() {
      let status = this.$route.query.status_id || 'all';
      let sendable;
      if (status != 'all') {
        this.activeStatus = `${status}`
        sendable = status;
      } else {
        this.activeStatus = 'all'
      }
      this.loadEnquiries(sendable);
    },
    priceinLacs(value) {
      return numDifferentiation(value)
    },
    waNum(el) {
      return waFormatter(el);
    },
    filterMatchesProperties(enquiry) {
      let apiParams = {}
      apiParams['lead_id'] = enquiry.id
      apiParams['match'] = true
      apiParams["as"] = true
      this.$router.replace({ name: 'Properties', query: apiParams });
    },
    loadEnquiries(status) {
      let queryP = { backlogs_only: true, as: true }
      if (Object.keys(this.advSParams).length > 0) {
        queryP["as"] = true
        let searchParams = prepareEnquirySearchParams(this.advSParams);
        for (let key in searchParams) {
          queryP[key] = searchParams[key];
        }
        this.advSearchParams = queryP
      }
      if (this.advSearchParams.length > 0) {
        paramsFromQuery(queryP, this.advSearchParams, status);
      }
      else {
        paramsFromQuery(queryP, this.$route.query, status);
      }
      let data = this.$axios.get('/mobile_crm/leads', {
        params: queryP,
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' })
      })
        .then(res => {
          this.enquiryList = res.data.leads;
          this.perPage = res.data.per_page;
          this.total = res.data.count;
        })
        .catch(err => console.log(err));
    },
    goToPage(pageNo) {
      let newQ = { ...this.$route.query, pageNo };
      this.$router.replace({ query: newQ });
    },
    resetBs() {
      let rQ = this.$route.query
      this.bsTerm = ''
      if (rQ.bs) {
        delete rQ.bs;
        let reset = {}
        for (let key in rQ) {
          reset[key] = rQ[key];
        }
        reset['reset'] = reset.reset ? (reset.reset == 0 ? 1 : 0) : 1
        this.$router.push({ query: reset });
      }
    },
    performBs() {
      if (this.bsTerm) {
        let newQ = { ...this.$route.query, bs: this.bsTerm }
        delete newQ.pageNo
        this.$router.replace({ query: newQ })
      }
    }
  },
  computed: {
    advSParams() {
      return this.$store.getters.advSParams;
    },
    statusList() {
      return this.$store.getters.statusesMaster;
    },
    totalPages() {
      if (this.total === 0 || this.perPage === 0) {
        return 0;
      } else {
        return Math.ceil(this.total / this.perPage);
      }
    },
    pageNo: {
      get() {
        let pageNo = this.$route.query.pageNo || 1
        return parseInt(pageNo);
      },
      set() {
        true;
      }
    },
    pageStart() {
      if (this.total === 0) {
        return 0;
      }
      return (Math.max(0, (this.pageNo - 1) * this.perPage + 1))
    },
    pageEnd() {
      if (this.total === 0) {
        return 0;
      }
      return (Math.min(this.total, this.pageNo * this.perPage))
    },
  }
}
</script>
<style scoped>
.enquiry-label {
  @apply border-r border-divider pr-3 mr-3 inline-flex mb-2;
}

.enquiry-label:first-child {
  @apply pl-0;
}

.enquiry-label:last-child {
  @apply border-none;
}
</style>
